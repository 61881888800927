import React, { useState, useContext } from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {Redirect, Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {InputBase, AppBar, Toolbar, Typography, IconButton} from '@material-ui/core';
import Logo from './Logo';
import ArrowLeftIcon  from '@material-ui/icons/ArrowLeft';
import WizardProgress from './WizardProgress';
import WizardQuestionView from './WizardQuestionView';
import WizardMultipleQuestionView from './WizardMultipleQuestionView';
import StyledButton from './StyledButton';
import LoggedInUserContext from '../../loggedInUserContext';
import Api from '../../api';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#fff'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 0,
        marginRight: '24px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        margin: '8px 12px',
        justifyContent: 'flex-end'
    },
    progressRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: '32px 42px 12px 0px',
        justifyContent: 'flex-end'
    },
    appBar: {
        borderBottom: '1px solid rgba(25, 25, 25, 0.06);',
        backgroundColor: '#fff'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    docName: {
        fontFamily: 'Assistant',
        fontSize: '18px',
        fontWeight: 500
    },
    button: {
        margin: '0px 4px'
    },
    buttonContainer: {
        paddingRight: '18px'
    },
    poweredRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '16px 0px'
    },
    powered: {
        fontFamily: 'Assistant',
        fontSize: '11px',
        alignSelf: 'center',
        marginRight: '4px'
    },
    poweredLink: {
        fontFamily: 'Assistant',
        fontSize: '11px',
        alignSelf: 'center',
        fontWeight: '500'
    }
}));

export default function MainWizardView({}) {
    const classes = useStyles();
    const allQuestions = useSelector(state => state.questions.questions, shallowEqual);
    const questions = allQuestions.filter(q => { return q.parent_id === undefined; });

    const document = useSelector(state => state.document, shallowEqual);

    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const currentQuestion = questions[currentQuestionIndex];
    const [answers, setAnswers] = useState({});
    const [dirtyName, setDirtyName] = useState(document.name);

    const context = useContext(LoggedInUserContext);
    const [redirectBack, setRedirectBack] = useState(false);
    const toURL = `${context.lastURL.pathname}${context.lastURL.search}`;

    const onBackPressed = async () => {
        setRedirectBack(true);
    };

    const handleNextClick = async () => {
        await setCurrentQuestionIndex(currentQuestionIndex + 1);
        if (currentQuestionIndex + 1 === questions.length) {
            const fileRet = await Api.files.createDocx(document.documentId, Object.keys(answers).map(k => {
                return questions.find(q=>q.id === k).is_multi ? {question_id: k, children: answers[k]} : 
                    {question_id: k, value: answers[k]}
            }));
            // const file = await Api.files.getFile(fileRet.filename);
            console.log(`localhost:3000/files/${fileRet.filename}`);
        }
    }
    
    const handleBackClick = async () => {
        await setCurrentQuestionIndex(currentQuestionIndex - 1);
    }

    const onAnswerChange = async (e, id) => {
        let newAnswers = Object.assign({}, answers);
        newAnswers[id] = e.target.value;
        await setAnswers(newAnswers);
    };
    const onInnerAnswerChange = async (val) => {
        let newAnswers = Object.assign({}, answers);
        newAnswers[currentQuestion.id] = val;
        await setAnswers(newAnswers);
    };

    const handleDocNameBlur = async (e) => {
        e.persist();
        await setDirtyName(e.target.value);
    };

    return redirectBack ? <Redirect to={toURL} /> : (
        <div className={classes.root}>
            <AppBar position="sticky" color="default" elevation={0} className={classes.appBar}>
                <Toolbar>
                    <div className={classes.buttonContainer}>
                        <IconButton aria-label="delete" size="small" onClick={onBackPressed}>
                            <ArrowLeftIcon fontSize="small" />
                        </IconButton>
                    </div>
                    <Logo style={{'maxWidth': '140px', 'marginRight': '20px'}} />
                    <InputBase className={classes.docName} value={dirtyName} onBlur={handleDocNameBlur} onChange={e => setDirtyName(e.target.value)} />
                </Toolbar>
            </AppBar>
            <div className={classes.progressRow}>
                <WizardProgress completed={questions.length > 0 && (currentQuestionIndex) * 100 / questions.length || 0} />
            </div>
            {currentQuestion && !currentQuestion.is_multi && <WizardQuestionView question={currentQuestion} onAnswerChange={e => onAnswerChange(e, currentQuestion.id)} val={answers[currentQuestion.id] || ''} />}
            {currentQuestion && currentQuestion.is_multi && <WizardMultipleQuestionView question={currentQuestion} onAnswerChange={onInnerAnswerChange} answers={answers} />}
            <div className={classes.buttonsContainer}>
                <StyledButton className={classes.button} onClick={handleBackClick} disabled={currentQuestionIndex === 0}>Back</StyledButton>
                <StyledButton 
                    className={classes.button} 
                    onClick={handleNextClick}>{currentQuestionIndex + 1 === questions.length && 'Finish' || 'Next'}</StyledButton>
                {currentQuestion && !currentQuestion.mandatory && currentQuestionIndex + 1 !== questions.length && <StyledButton onClick={handleNextClick} className={classes.button}>Skip</StyledButton>}
                
            </div>
            <div className={classes.poweredRow}>
                <Typography className={classes.powered}>Powered by </Typography><Link className={classes.poweredLink} to='/'>LegalUp.me</Link>
            </div>
        </div>
    );
}