import { withStyles } from '@material-ui/core/styles';
import {Button} from '@material-ui/core';

const StyledButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '9px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        borderRadius: '14px',
        color: 'white',
        backgroundColor: '#1274e4',
        '&:hover': {
            backgroundColor: '#1060BB'
      },
    },
}))(Button);

export default StyledButton;