import React from 'react';
import classnames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { SERVER_URL } from '../../api';

const useStyles = makeStyles(theme => ({
    mainHeader: {
        backgroundColor: '#FFFFFF'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    grow: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    firstText: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#35373d'
    },
    secondText: {
        fontWeight: '300'
    },
    image: {
        width: '24px',
        height: '24px',
        margin: '0px 8px'
    }
}));
console.log('SERVER_URL', SERVER_URL);
function Logo(props) {
    const classes = useStyles();
    return (
        <div className={classes.grow} {...props}>
            <img src={`${SERVER_URL}/assets/ic_logo.svg`} className={classes.image}/>
            <div className={classes.row}>
                <Typography className={classes.firstText}>Legal</Typography>
                <Typography className={classnames(classes.firstText, classes.secondText)}>Up</Typography>
            </div>
        </div>
    );
}
export default Logo;