import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Paper, InputBase, Divider} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
        fontSize: '14px',
        fontFamily: 'Assistant'
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));

export default function CustomizedInputBase(props) {
    const classes = useStyles();
    const {placeholder, onChange, autoFocus} = props;

    return (
        <div className={classes.root}>
            <IconButton className={classes.iconButton} aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase 
                className={classes.input}
                placeholder={placeholder}
                inputProps={{ 'aria-label': {placeholder} }}
                onChange={onChange} autoFocus
            />
            {/* <Divider className={classes.divider} orientation="vertical" />
            <IconButton color="primary" className={classes.iconButton} aria-label="directions">
                <DirectionsIcon />
            </IconButton> */}
        </div>
    );
}
