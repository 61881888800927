import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import {Chip, LinearProgress, Switch, Typography} from "@material-ui/core";
import StyledButton from "./StyledButton";
import {useDispatch, useSelector} from "react-redux";
import { useClipboard } from 'use-clipboard-copy';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete , { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {MenuItem} from '@material-ui/core';
import { TextField } from '@material-ui/core';

import CheckIcon from '@material-ui/icons/Check';

import * as Actions from "../../redux/actions";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import {withStyles} from "@material-ui/styles";
import {Redirect} from "react-router-dom";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {isRTL} from "../../utils";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'rgba(247, 247, 249, 0.5)',
    },
    main: {
        background: '#FFFFFF',
        borderRadius: '2px',
        width: '310px',
        display: 'flex',
        margin: '0 auto 10px',
        alignItems: 'center',
        flexDirection: 'column'
    },
    button: {
        width: '294px',
        border: 'none',
        background: 'rgba(17, 116, 229, 1)',
        borderRadius: '2px',
        margin: '0',
        boxSizing: 'border-box',
        height: '33px',
        color: '#fff',
        '&:hover' : {
            backgroundColor: 'rgba(17, 116, 229, 0.9)',
            cursor: 'pointer',
        },
        '&:focus, &:active' : {
            backgroundColor: 'rgba(17, 116, 229, 1)',
            cursor: 'pointer',
        },
        '&:focus span, &:focus svg path, &:active span, &:active svg path': {
            color: '#fff',
            fill:  '#fff',
        },
    },
    span: {
        color: '#fff',
        paddingLeft: '8px',
        fontWeight: '700',
        fontSize:' 10px',
        fontFamily: 'Nunito',
    },
    list: {
        width: '100%'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '32px',
        '&:hover' : {
            backgroundColor: 'rgba(36, 232, 255, 0.04)',
            cursor: 'pointer'
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(18, 116, 228, 0.05)'
        },
        '& > p': {
            flexGrow: 1,
            fontSize: '14px',
            fontFamily: 'Assistant'
        }
    },
    description: {
        padding: '12px',
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0.2px',
        color: '#595959',
        marginBottom: '10px'
    },
    progress: {
        marginTop :'12px',
        width: '74%'
    },
    deleteButton: {
        width: '11px',
        height: '11px',
    },
    deleteIcon: {
        width: '11px',
        height: '11px',
    },
    link: {
        padding: '0 20px',
        lineHeight: '33px',
        background: '#1174E5',
        textAlign: 'center',
        display: 'flex',
        margin: '0 4px',
        textDecoration: 'none',
        alignItems: 'center',
        flexDirection: 'row'
    },
    textArea: {
        width: '294px',
        padding: '15px',
        boxSizing: 'border-box',
        background: 'rgba(247, 247, 249, 0.5)',
        border: '1px solid #F7F7F9',
        borderRadius: '2px',
        marginBottom: '12px',
        fontFamily: 'Nunito',
        color: 'rgba(3, 2, 41, 1)',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '15px',
        '&::placeholder': {
            color: '#030229',
            opacity: '1',
        },
    },
    textAreaResult: {
        width: '294px',
        padding: '15px',
        boxSizing: 'border-box',
        background: 'rgba(247, 247, 249, 0.5)',
        border: '1px solid #F7F7F9',
        borderRadius: '2px',
        marginBottom: '12px',
        fontFamily: 'Nunito',
        margin: '5px 0',
    },
    titleKeep: {
        color: '#1174E5',
        fontFamily: 'Nunito',
        fontSize: '9px',
        fontWeight: '700',
        fontStyle: 'normal',
    },
    colorOne: {
        color: '#9411AC',
    },
    colorSecond: {
        color: '#00B298',
    },
    bgOne: {
        border: 'none',
        background: 'rgba(148, 17, 172, 0.15)',
        borderRadius: '2px',
    },
    bgSecond: {
        border: 'none',
        background: 'rgba(0, 178, 152, 0.15)',
        borderRadius: '2px',
    },
    titleBack: {
        color: '#9411AC',
        fontFamily: 'Nunito',
        fontSize: '9px',
        fontWeight: '700',
        fontStyle: 'normal',
        textDecoration: 'none',
        marginLeft: '10px',
        cursor: "pointer",
        zIndex: "99",
    },
    titleBack2: {
        color: '#9411AC',
        fontFamily: 'Nunito',
        fontSize: '9px',
        fontWeight: '700',
        fontStyle: 'normal',
        textDecoration: 'none',
        marginLeft: '5px',
    },
    noBg: {
        background: 'none',
        minWidth: 'auto',
        '&:hover': {
            background: 'none',
        },
    },
    styleBlock: {
        display: 'flex',
        paddingLeft: '20px',
        width: '100%',
        boxSizing: 'border-box',
        justifyContent: 'left',
        '& p': {
            color: 'rgba(148, 17, 172, 0.8)',
            fontWeight: '700',
            margin: '6px 0',
            fontSize:' 10px',
            fontFamily: 'Nunito',
            cursor: 'pointer',
            '& span span': {
                paddingLeft: '8px',
                color: 'rgba(148, 17, 172, 0.8)',
                fill:  'rgba(148, 17, 172, 0.8)',
            }
        },
        '&:hover span, &:hover span svg path': {
            color: '#9411AC',
            fill:  '#9411AC',
        }
    },
    title: {
        color: '#030229',
        fontFamily: 'Nunito',
        fontSize: '10px',
        fontWeight: '700',
        fontStyle: 'normal',
        marginBottom: '5px'
    },
    styleForBlockSuggest: {
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        paddingLeft: '10px',
        boxSizing: 'border-box',
    },
    textField: {
        marginLeft: '40px',
        '& :before' : {
            display: 'none',
        },
        '& input' : {
            background: '#FBFBFC',
            border: '1px solid #F7F7F9',
            borderRadius: '2px',
            padding: '4px 5px',
            '& :before' : {
                display: 'none',
            },
        },
        '& input::placeholder': {
            color: '#030229',
            opacity: '1',
        },
    },
    empty: {
        '& input' : {
            border: '1px solid #FF7070',
        }
    },
    aiError: {
        background: 'rgba(255, 255, 255, 0.1)',
        border: '1px solid rgba(255, 89, 89, 0.1)',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.03)',
        borderRadius: '4px',
        width: '294px',
        boxSizing: 'border-box',
        marginBottom: '12px',
        fontFamily: 'Nunito',
        marginTop: '20px',
    },
    titleAiError: {
        color: '#FF5959',
        background: 'rgba(255, 89, 89, 0.15)',
        borderRadius: '4.2px 4.2px 1px 1px;',
        height: '22px',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '10px',
        lineHeight: '14px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
    },
    aiErrorContent: {
        padding: '30px 20px 20px 20px',
    },
    aiErrorIcon: {
        display: 'flex',
        width: '100%',
        marginBottom: '15px',
    },
    aiErrorText : {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '11px',
        display: 'flex',
        alignItems: 'center',
        color: '#FF5959',
    },
    inputCheck: {
        marginLeft: '-20px',
        zIndex: '1',
        marginTop: '7px',
    },
    inputCheckError: {
        marginLeft: '-15px',
        zIndex: '1',
        marginTop: '9px',
    },
    error: {
        background: 'rgba(247, 247, 249, 0.5)',
        border: '1px solid rgba(255, 89, 89, 0.15)',
        borderRadius: '2px',

        '&::placeholder': {
            color: 'rgba(255, 89, 89, 0.5)',
        },
    },

    tags: {
        background: 'rgba(17, 116, 229, 0.15)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '4.6875px 8.5px',
        margin: '2.5px',
        borderRadius: '6px',
        position:'relative',
        paddingRight: '22px',
        '& span' : {
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: '600',
            fontSize: '9px',
            lineHeight: '12px',
            display: 'flex',
            alignItems: 'center',
            color: '#1174E5',
            opacity: '0.8',
            '& svg' : {
                width: '10px',
                height: '10px',
                marginLeft: '8px',
                cursor: 'pointer',
                zIndex: '1',
                right:'5px',
                position:'absolute',
                transition: 'transform .25s, opacity .25s',
                '&:hover' : {
                    transform: 'rotate(270deg)',
                },
            }
        }
    },
    blocksMargin : {
        width: '294px',
        marginTop: '10px',
        '& fieldset' : {
            border: 'none!important',
            borderRadius: '0'
        },
        '& input::placeholder': {
            color: '#030229',
            opacity: '1',
        },
    },
    subTitle: {
        color: '#030229',
        fontFamily: 'Nunito',
        fontSize: '8px',
        fontWeight: '500',
        fontStyle: 'normal',
    },
    textFieldDocument : {
        '& input': {
            padding: '0 10px',
            width: '294px',
            height: '33px',
            boxSizing: 'border-box',
            background: 'rgba(247, 247, 249, 0.5)',
            border: '1px solid #F7F7F9',
            borderRadius:'2px',
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '8px',
            color: 'rgba(3, 2, 41, 1)',
        },
        '& fieldset' : {
            border: 'none!important',
            borderRadius: '0'
        },
        '& div' : {
            border: 'none!important',
            borderRadius: '0'
        },
    },
    private: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '10px',
        lineHeight: '14px',
        color: '#030229',
        marginRight: '20px',
    },
    shared: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '10px',
        lineHeight: '14px',
        color: '#1174E5',
        marginLeft: '-6px',
    },
    sharedColor: {
        color: '#1174E5',
    },
    privateColor: {
        color: '#030229',
    },
    rtl: {
        direction: 'rtl',
    },
    borderTopForCustom: {
        borderTop: '2px solid #000',
        width: '100%',
        display: 'block'
    }

}));

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: '22px',
        height: '18px',
        padding: 0,
        margin: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(7px)',
            '& + $track': {
                opacity: 1,
            },
        },
        '&$focusVisible $thumb': {

        },
    },
    thumb: {
        width: '10px',
        height: '10px',
        background: '#1174E5',
        borderRadius: '3.5px',
        transform: 'translateX(2px)',
        marginTop: '2px',
    },
    track: {
        width: '22px!important',
        height: '14px!important',
        background: '#F7F7F9!important',
        border: '2px solid #EDEDED!important',
        borderRadius: '5px!important',
        opacity: 1,

    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});



export default function KeepClauseEdit(props) {
    const { match: { params } } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const promptAi = useSelector(state => state.document.promptAi)
    const documentTypes = useSelector(state => state.document.documentTypes)
    const documentTypesTags = useSelector(state => state.document.documentTypesTags)
    const savedDocumentTypesId = useSelector(state => state.document.savedDocumentTypesId)
    const savedDocumentTypesTagsId = useSelector(state => state.document.savedDocumentTypesTagsId)
    const clauseCategories = useSelector(state => state.document.clauseCategories)
    const [language, setLanguage] = useState('en');
    const [documentTypesOption, setDocumentTypesOption] = useState([]);
    const [documentTypesOptionTags, setDocumentTypesTagsOption] = useState([]);
    const [documentTypesId, setDocumentTypesId] = useState('');
    const [categoryId, setCategoryId] = useState(undefined);
    const [tagsId, setTagsId] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState(undefined);
    const [documentName, setDocumentName] = useState('');
    const [categoriesOption, setCategoriesOption] = useState([]);
    const [subCategoriesOption, setSubCategoriesOption] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [redirectPage, setRedirectPage] = useState(undefined);
    const [clause, setClause] = useState(undefined);
    const [internalNotes, setInternalNotes] = useState(false);
    const [internal, setInternal] = useState('');
    const [autocompleteForCategoryShow, setAutocompleteForCategoryShow] = useState(true);
    const [autocompleteForSubCategoryShow, setAutocompleteForSubCategoryShow] = useState(true);
    const [autocompleteForTagsShow, setAutocompleteForTagsShow] = useState(true);
    const [autocompleteForLanguage, setAutocompleteForLanguage] = useState(true);
    const clauseId = params.clause_id;
    const clausesPortfolio = useSelector(state => state.document.clausesPortfolio)
    const filter = createFilterOptions();
    const namesLanguage = [
        "en",
        "he",
        "fr",
    ];

    const user =  JSON.parse(localStorage.getItem('current_user'));
    const role = user && user.id ? user.role : '';

    const onChangeAutocompleteLanguage = (value) => {
        if (namesLanguage.indexOf(value) !== -1) {
            setLanguage(value);
            return value;
        }
        return value;
    }


    function toRGBAForCat(id) {
        //const findColor = documentTypesTags.find((data)=>data.id === id)
        let hex = '#1174E5';
        // if(findColor){
        //     hex = findColor.color
        // }
        return "rgba(" + hex.match(/[^#]./g).map(ff => parseInt(ff, 16)) + ',0.15' + ")";
    }

    function colorForCat(id) {
        //const findColor = documentTypesTags.find((data)=>data.id === id)
        let color = '#1174E5';
        // if(findColor){
        //     color = findColor.color || '#6211E5';
        // }
        return color;
    }

    function toRGBA(id) {
        const findColor = documentTypesTags.find((data)=>data.id === id)
        let hex = '#6211E5';
        if(findColor){
            hex = findColor.color
        }
        return "rgba(" + hex.match(/[^#]./g).map(ff => parseInt(ff, 16)) + ',0.15' + ")";
    }

    function color(id) {
        const findColor = documentTypesTags.find((data)=>data.id === id)
        let color = '#6211E5';
        if(findColor){
            color = findColor.color
        }
        return color;
    }

    const onChangeAutocompleteDocumentType = async (value) => {
        if(!value.id){
            const data = {
                name: {
                    name: value.label,
                    language: language
                }
            }
            await setLoading(true);
            await dispatch(Actions.saveDocumentType( data ));
            await dispatch(Actions.getDocumentType( language ));
            await setLoading(false);
        }
        if (documentTypesOption.findIndex(item => item.id === value.id) !== -1) {
            setDocumentTypesId(value.id);
            return value;
        }
        return value;
    }

    const onChangeAutocompleteDocumentTypeTags = async (tags) => {

        const findUndefinedTagsId = tags.find((data)=>data.id===undefined)

        setAutocompleteForTagsShow(false)
        if(findUndefinedTagsId){
            const data = {
                name: {
                    name: findUndefinedTagsId.label,
                    language: language
                },
                color: "#6211E5",
                is_tag: true
            }
            await setLoading(true);
            await dispatch(Actions.saveDocumentType( data ));
            await dispatch(Actions.getDocumentType( language ));
            await setLoading(false);
        }

        setTagsId(tags);
        return tags
    }

    const onChangeAutocompleteCategory = (categories) => {
        setCategoryId(categories);
        return categories
    }

    const onChangeAutocompleteSubCategory = (categories) => {
        setSubCategoryId(categories);
        return categories
    }

    const handleRemoveCategory = (id) => {
        const categoriesWithoutThisId = categoryId.filter(item => item.id !== id);
        setCategoryId(categoriesWithoutThisId);
        setAutocompleteForCategoryShow(false)
    }

    const handleRemoveTags = (id) => {
        const tagsWithoutThisId = tagsId.filter(item => item.id !== id);
        setTagsId(tagsWithoutThisId);
        setAutocompleteForTagsShow(false)
    }

    const handleRemoveSubCategory = (id) => {
        const categoriesWithoutThisId = subCategoryId.filter(item => item.id !== id);
        setSubCategoryId(categoriesWithoutThisId);
        setAutocompleteForSubCategoryShow(false)
    }

    const handleChange = (event) => {
        setInternalNotes( event.target.checked );
    };

    const handleSave = async () => {
       let category =[];
       let subCategory =[];
       let tags =[];

        if(categoryId && categoryId.length) {
            category = categoryId.map((option) => {
                return option.id
            })
        }

        if(subCategoryId && subCategoryId.length) {
             subCategory = subCategoryId.map((option)=>{
                return option.id
            })
        }

        if(tagsId && tagsId.length) {
            tags = tagsId.map((option) => {
                return option.id
            })
        }

        const clauses = {
            name: documentName,
            shared: internalNotes,
            tag_ids: tags,
            language,
            category_ids: subCategory.length > 0 ? category.concat(subCategory) : category,
            alternatives: [
                {
                    "name": documentName,
                    "text": clause.alternatives[0].text,
                    "internal_notes": internal,
                    "raw_data": "<w:p xmlns:w=\"http://schemas.openxmlformats.org/wordprocessingml/2006/main\" w:rsidR=\"000C609A\" w:rsidRPr=\"00FD3F8D\" w:rsidRDefault=\"000C609A\" w:rsidP=\"00D232BB\"><w:pPr><w:pStyle w:val=\"ArticleL2\"/><w:numPr><w:ilvl w:val=\"0\"/><w:numId w:val=\"0\"/></w:numPr><w:rPr><w:rFonts w:ascii=\"Arial\" w:hAnsi=\"Arial\" w:cs=\"Arial\"/><w:sz w:val=\"22\"/><w:szCs w:val=\"22\"/><w:lang w:val=\"en-GB\" w:eastAsia=\"de-DE\"/></w:rPr></w:pPr><w:r w:rsidRPr=\"007C5749\"><w:rPr><w:rFonts w:ascii=\"Arial\" w:hAnsi=\"Arial\"/><w:sz w:val=\"22\"/><w:lang w:val=\"en-GB\"/></w:rPr><w:t>${clause.alternatives[0].text}</w:t></w:r><w:r w:rsidRPr=\"00FD3F8D\"><w:rPr><w:rFonts w:ascii=\"Arial\" w:hAnsi=\"Arial\" w:cs=\"Arial\"/><w:sz w:val=\"22\"/><w:szCs w:val=\"22\"/><w:lang w:val=\"en-GB\" w:eastAsia=\"de-DE\"/></w:rPr><w:t xml:space=\"preserve\">  </w:t></w:r></w:p>"
                }
            ],
        }

        if (documentTypesId) {
            clauses.document_type_id = documentTypesId;
        }

        await setLoading(true);
        await dispatch(Actions.editClauses( clause.id, clauses ));
        await setLoading(false);
        setRedirectPage('/clausesPorfolio')
    };

    useEffect( () => {
        if(!autocompleteForCategoryShow){
            setAutocompleteForCategoryShow(true);
        }
    }, [autocompleteForCategoryShow] );

    useEffect( () => {
        if(!autocompleteForSubCategoryShow){
            setAutocompleteForSubCategoryShow(true);
        }
    }, [autocompleteForSubCategoryShow] );

    useEffect( () => {
        if(clausesPortfolio.length && clauseId){
            const clause = clausesPortfolio.find((item)=>clauseId === item.id);
            setClause(clause)
        }
    }, [clausesPortfolio] );

    useEffect( () => {
        setAutocompleteForLanguage(false);
        const init = async () => {
            if(language.length > 0) {
                await setLoading(true);
                await dispatch(Actions.getDocumentType( language ));
                await dispatch(Actions.getClauseCategories( language ));
                await setLoading(false);
            }
        }
        init();
    }, [language] );

    useEffect( () => {
        const init = async () => {
            await setLoading(true);
            await dispatch(Actions.getDocumentType( language ));
            await dispatch(Actions.getClauseCategories( language ));
            await dispatch(Actions.getDocument());
            await dispatch(Actions.getClauses( '' ));
            await setLoading(false);
        }
        init();
    }, [] );

    useEffect( () => {
        setDocumentTypesId(savedDocumentTypesId);
    }, [savedDocumentTypesId] );

    useEffect( () => {
        setAutocompleteForTagsShow(false);
    }, [documentTypesId] );


    useEffect( () => {
        setAutocompleteForTagsShow(false)
        if(savedDocumentTypesTagsId && savedDocumentTypesTagsId.id ){
            const checkTagsId = tagsId.find((data)=> savedDocumentTypesTagsId.id === data.id)
            if(!checkTagsId){
                const findUndefinedTagsId = tagsId.filter((data)=>data.id !== undefined)
                let tagsList = findUndefinedTagsId;
                tagsList.push(savedDocumentTypesTagsId);
                setTagsId(tagsList);
            }
        }
    }, [tagsId] );


    useEffect( () => {
        if(!autocompleteForTagsShow){
            setAutocompleteForTagsShow(true);
        }
    }, [autocompleteForTagsShow] );

    useEffect( () => {
        if(!autocompleteForLanguage){
            setAutocompleteForLanguage(true);
        }
    }, [autocompleteForLanguage] );



    const findTypeByLanguage = (type) => {
        if(type && type.alternative_names.length){
            const correctNameIndex = type.alternative_names.findIndex((n)=>{
                return n.language === language
            })
            if(correctNameIndex !==-1) {
                return type.alternative_names[correctNameIndex].name
            }
        }
        return type.name.name;
    }

    useEffect( () => {
       if(documentTypes && documentTypes.length) {
           const firstCustomType = []

           let options = documentTypes.map((option)=>{
               if(!!option.organization_id){
                   firstCustomType.push(option)
               }
               return {label: findTypeByLanguage(option), id: option.id, custom: !!option.organization_id}
           })

           if(options.length) {
               options.sort((a, b) => a.custom - b.custom);
           }

           if(firstCustomType.length) {
              const index = options.findIndex((i)=>i.id === firstCustomType[0].id)
               if(index !== -1) {
                   options[index].border = true;
               }
           }

           setDocumentTypesOption(options);
       } else {
           setDocumentTypesOption([]);
       }
    }, [documentTypes] );

    useEffect( () => {
        if(documentTypesTags && documentTypesTags.length) {
            const firstDocumentTypesTags = []

            let options = documentTypesTags.map((option)=>{
                if(!!option.organization_id){
                    firstDocumentTypesTags.push(option)
                }
                return {label: findTypeByLanguage(option), id: option.id, custom: !!option.organization_id}
            })

            if(options.length) {
                options.sort((a, b) => a.custom - b.custom);
            }

            if(firstDocumentTypesTags.length) {
                const index = options.findIndex((i)=>i.id === firstDocumentTypesTags[0].id)
                if(index !== -1) {
                    options[index].border = true;
                }
            }

            setDocumentTypesTagsOption(options);
        } else {
            setDocumentTypesTagsOption([]);
        }
    }, [documentTypesTags] );

    useEffect( () => {
        if(clauseCategories && clauseCategories.length) {

            const filteredCategoriesList = clauseCategories.filter((category)=>{
                return category.parent_ids.length === 0
            })

            const options = filteredCategoriesList.map((option)=>{
                return {label: option.name.name, id: option.id}
            })

            setCategoriesOption(options)
        } else {
            setCategoriesOption([])
        }
    }, [clauseCategories] );

    useEffect( () => {
        if(clauseCategories && clauseCategories.length && categoryId && categoryId.length) {
            const subCategoriesList = []
            categoryId.forEach((category)=>{
                clauseCategories.forEach((cat)=>{
                    const findParentId = cat.parent_ids.filter((id)=>{
                        return id === category.id
                    })

                    if(findParentId.length > 0) {
                        subCategoriesList.push(cat)
                    }
                })

            })

            const options = subCategoriesList.map((option)=>{
                return {label: option.name.name, id: option.id}
            })


            setSubCategoriesOption(options)

            let cats = [];
            clause.category_ids.forEach((catId)=>{
                options.forEach((cat)=>{
                    if(cat.id === catId) {
                        cats.push(cat)
                    }
                })

            })
            setSubCategoryId(cats)


        }
    }, [categoryId] );

    useEffect( () => {
        if(clause && documentTypesOptionTags.length && categoriesOption.length){

            let tags = [];
            let cats = [];

            clause.tag_ids.forEach((tagId)=>{
                documentTypesOptionTags.forEach((tag)=>{
                    if(tag.id === tagId) {
                        tags.push(tag)
                    }
                })

            })

            clause.category_ids.forEach((catId)=>{
                categoriesOption.forEach((cat)=>{
                    if(cat.id === catId) {
                        cats.push(cat)
                    }
                })
            })

            setDocumentTypesId(clause.document_type_id);
            setCategoryId(cats)
            setTagsId(tags)
            setDocumentName( clause.alternatives[0].name )
            setInternal(clause.alternatives[0].internal_notes)
            //setLanguage(clause.language)

        }
    }, [clause, categoriesOption, documentTypesOptionTags] );


    useEffect( () => {
        if(clause){
            setLanguage(clause.language)
        }
    }, [clause]);

    const renderOption = (option) => {
        if(option.id === undefined){
            return `<div class="wrapperAutocompleteOption">
                        <span class="left-new">${option.label}</span>
                        <span class="right-new">Add new</span>
                    </div>`;
        } else {
            return option.label;
        }
    }

    const handleBack = async () => {
        await dispatch(Actions.resetTags());
        setRedirectPage(`/`)
    };

    console.log('language', language);

    return  redirectPage ? <Redirect to={redirectPage} /> :  (
        <div className={classes.root}>
            <Header showButton={false} />
            <div className={classes.main}>

                <div style={{display: 'flex', marginBottom: '12px', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginTop: '10px',}}>
                    <span className={classes.titleBack} onClick={handleBack}>
                        <svg className={classes.svg} width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5C9.27614 5 9.5 4.77614 9.5 4.5C9.5 4.22386 9.27614 4 9 4V5ZM0.646446 4.14645C0.451184 4.34171 0.451184 4.65829 0.646446 4.85355L3.82843 8.03553C4.02369 8.2308 4.34027 8.2308 4.53553 8.03553C4.7308 7.84027 4.7308 7.52369 4.53553 7.32843L1.70711 4.5L4.53553 1.67157C4.7308 1.47631 4.7308 1.15973 4.53553 0.964466C4.34027 0.769204 4.02369 0.769204 3.82843 0.964466L0.646446 4.14645ZM9 4L1 4V5L9 5V4Z" fill="#9411AC"/>
                        </svg>
                        <span className={classes.titleBack2}>Back</span>
                    </span>
                    <Typography className={classes.title}>Edit Keep Clause<br/>{documentName}</Typography>
                    <div> </div>
                </div>
                {(isLoading) && <LinearProgress color="primary" className={classes.progress}/>}
                <div id='autocomplete-block' className={classes.blocksMargin}>
                    <>
                        <Typography className={classes.title}>Language</Typography>
                        {namesLanguage.length && language && autocompleteForLanguage ?
                            <Autocomplete
                                //multiple
                                id="language"
                                defaultValue={language}
                                options={namesLanguage}
                                getOptionLabel={(option) => option}

                                onChange={(event, newValue) => {
                                    onChangeAutocompleteLanguage(newValue);
                                }}
                                renderOption={( option, { selected }) => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        {option}
                                    </MenuItem>
                                )}

                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select Language"
                                    />
                                )}
                            />

                            : null}

                    </>
                </div>


                <div id='autocomplete-block' className={classes.blocksMargin}>
                    <>
                        <Typography className={classes.title}>Document Type</Typography>
                        {documentTypesOption.length && clause && <Autocomplete
                            //multiple
                            id="document-type"
                            options={documentTypesOption}
                            defaultValue={clause.document_type_id && clause.document_type_id !=='' ? documentTypesOption.find((item)=>clause.document_type_id === item.id) : ''}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                if (newValue !== null){
                                    onChangeAutocompleteDocumentType(newValue);
                                }

                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option.label);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        id: options.id,
                                        label: inputValue,
                                    });
                                }
                                return filtered;
                            }}
                            getOptionLabel={(option) => {
                                if( option.label !== null ){
                                    return option.label;
                                }
                            }}
                            renderOption={( option, { selected }) => (
                                <div className={option.border ? classes.borderTopForCustom : ''}
                                     dir={isRTL(option.label) ? 'rtl' : 'ltr'}>
                                    <MenuItem
                                        key={option.label}
                                        value={option.id}
                                        sx={{justifyContent: "space-between"}}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: renderOption(option)}}/>

                                    </MenuItem>
                                </div>
                            )}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select Type"
                                />
                            )}
                        /> }

                    </>
                </div>
                <div id='autocomplete-block' className={classes.blocksMargin}>
                    <>
                        <Typography className={classes.title}>Tags</Typography>

                        {autocompleteForTagsShow &&
                        <Autocomplete
                            multiple
                            id="document-type-tags"
                            options={documentTypesOptionTags}
                            defaultValue={tagsId}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {


                                // if (newValue !== null){
                                //     onChangeAutocompleteDocumentType(newValue);
                                // }

                                onChangeAutocompleteDocumentTypeTags(newValue);
                            }}
                            filterOptions={(options, params) => {
                                const filtered = filter(options, params);
                                const { inputValue } = params;
                                const isExisting = options.some((option) => inputValue === option.label);
                                if (inputValue !== '' && !isExisting) {
                                    filtered.push({
                                        id: options.id,
                                        label: inputValue,
                                    });
                                }
                                return filtered;
                            }}
                            getOptionLabel={(option) => {
                                if( option.label !== null ){
                                    return option.label;
                                }
                            }}
                            renderOption={( option, { selected }) => (
                                <div className={option.border ? classes.borderTopForCustom : ''}
                                    dir={isRTL(option.label) ? 'rtl' : 'ltr'}>
                                    <MenuItem
                                        key={option.label}
                                        value={option.id}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: renderOption(option) }} />
                                    </MenuItem>
                                </div>
                            )}
                            renderTags={(value, getTagProps) =>
                                <></>
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select from pre-defined tags, or type your own custom tag"
                                />
                            )}
                        />}
                    </>
                </div>

                <div className={classes.blocksMargin} style={{display: 'block' }}>
                    {tagsId && tagsId.length > 0 && tagsId.map((tag)=>{
                        return (

                            <div dir={isRTL(tag.label) ? 'rtl' : 'ltr'} key={tag.id} className={classes.tags} style={{display: 'inline-block', background: `${toRGBA(tag.id)}` }}>
                                <span style={{color:`${color(tag.id)}`}}>
                                    {tag.label}
                                    <svg onClick={()=>{handleRemoveTags(tag.id)}} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.25 2.47184L5.34062 1.56247L3.75 3.15309L2.15937 1.56247L1.25 2.47184L2.84063 4.06247L1.25 5.65309L2.15937 6.56247L3.75 4.97184L5.34062 6.56247L6.25 5.65309L4.65937 4.06247L6.25 2.47184Z" fill={color(tag.id)}/>
                                    </svg>

                                </span>
                            </div>

                        )

                    })}

                </div>


                <div id='autocomplete-block' className={classes.blocksMargin}>
                    <>
                        <Typography className={classes.title}>Category <span className={classes.subTitle}>(from sali.org)</span></Typography>
                        {autocompleteForCategoryShow && clause &&
                            <Autocomplete
                                multiple
                                id="category-type"
                                options={categoriesOption}
                                defaultValue={ categoryId }
                                getOptionLabel={(option) => option.label}

                                onChange={(event, newValue) => {
                                    onChangeAutocompleteCategory(newValue);
                                }}
                                renderOption={( option, { selected }) => (
                                    <div dir={isRTL(option.label) ? 'rtl' : 'ltr'}>
                                        <MenuItem
                                            key={option.label}
                                            value={option.id}
                                            sx={{ justifyContent: "space-between" }}
                                        >
                                            {option.label}
                                            {/*{selected ? <CheckIcon color="info" /> : null}*/}
                                        </MenuItem>
                                    </div>
                                )}
                                renderTags={(value, getTagProps) =>
                                    <></>
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        placeholder="Select from pre-defined categories list"
                                    />
                                )}
                            />
                        }
                    </>
                </div>

                <div className={classes.blocksMargin} style={{display: 'block' }}>
                    {categoryId && categoryId.length > 0 && categoryId.map((category)=>{
                        return (

                            <div dir={isRTL(category.label) ? 'rtl' : 'ltr'} key={category.id} className={classes.tags} style={{display: 'inline-block', background: `${toRGBAForCat(category.id)}` }}>
                                <span style={{color:`${colorForCat(category.id)}`}}>
                                    {category.label}
                                    <svg onClick={()=>{handleRemoveCategory(category.id)}} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.25 2.47184L5.34062 1.56247L3.75 3.15309L2.15937 1.56247L1.25 2.47184L2.84063 4.06247L1.25 5.65309L2.15937 6.56247L3.75 4.97184L5.34062 6.56247L6.25 5.65309L4.65937 4.06247L6.25 2.47184Z" fill={colorForCat(category.id)}/>
                                    </svg>

                                </span>
                            </div>

                        )

                    })}

                </div>



                <div id='autocomplete-block' className={classes.blocksMargin}>
                    <>
                        <Typography className={classes.title}>Sub-Category <span className={classes.subTitle}>(from sali.org)</span></Typography>
                        {autocompleteForSubCategoryShow &&
                        <Autocomplete
                            multiple
                            id="sub-category-type"
                            options={subCategoriesOption}
                            defaultValue={subCategoryId}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                onChangeAutocompleteSubCategory(newValue);
                            }}
                            renderOption={( option, { selected }) => (
                                <div dir={isRTL(option.label) ? 'rtl' : 'ltr'}>
                                    <MenuItem
                                        key={option.label}
                                        value={option.id}
                                        sx={{ justifyContent: "space-between" }}
                                    >
                                        {option.label}
                                        {/*{selected ? <CheckIcon color="info" /> : null}*/}
                                    </MenuItem>
                                </div>
                            )}
                            renderTags={(value, getTagProps) =>
                                <></>
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder="Select from pre-defined categories list"
                                />
                            )}
                        />}
                    </>
                </div>

                <div className={classes.blocksMargin} style={{display: 'block' }}>
                    {subCategoryId && subCategoryId.length > 0 && subCategoryId.map((category)=>{
                        return (

                            <div dir={isRTL(category.label) ? 'rtl' : 'ltr'} key={category.id} className={classes.tags} style={{display: 'inline-block', background: `${toRGBAForCat(category.id)}` }}>
                                <span style={{color:`${colorForCat(category.id)}`}}>
                                    {category.label}
                                    <svg onClick={()=>{handleRemoveSubCategory(category.id)}} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.25 2.47184L5.34062 1.56247L3.75 3.15309L2.15937 1.56247L1.25 2.47184L2.84063 4.06247L1.25 5.65309L2.15937 6.56247L3.75 4.97184L5.34062 6.56247L6.25 5.65309L4.65937 4.06247L6.25 2.47184Z" fill={colorForCat(category.id)}/>
                                    </svg>

                                </span>
                            </div>

                        )

                    })}

                </div>

                <div id='autocomplete-block' className={classes.blocksMargin}>
                    <>
                        <Typography className={classes.title}>Document name</Typography>
                        <TextField
                            className={classes.textFieldDocument}
                            value={documentName}
                            variant="outlined"
                            placeholder="Enter a document name"
                            onChange={(e)=> {setDocumentName(e.target.value);}}
                        />
                    </>
                </div>

                <div id='autocomplete-block' className={classes.blocksMargin}>
                    <>
                        <Typography className={classes.title}>Internal notes</Typography>

                        <div dir={isRTL(internal) ? 'rtl' : 'ltr'}>
                            <TextareaAutosize
                                minRows={10}
                                placeholder='For example: “Use this clause only in case the other party has already commented on your draft and did not accept the Company’s boilerplate”'
                                name='internal'
                                className={ classes.textArea }
                                value={internal}
                                onChange={(e)=> {setInternal(e.target.value);}}
                            />
                        </div>

                        <div style={{display: 'flex',alignItems: 'center', justifyContent: 'center' }}>
                            <span className={internalNotes ?
                                classes.private + ' ' + classes.privateColor :
                                classes.private + ' ' + classes.sharedColor}>Private</span>
                                <FormControlLabel
                                    disabled={(role === 'user')}
                                    control={
                                        <IOSSwitch
                                            checked={internalNotes}
                                            onChange={handleChange}
                                            name="InternalNotes" />
                                    }
                                />
                            <span className={ internalNotes ?
                                classes.shared + ' ' + classes.sharedColor :
                                classes.shared + ' ' + classes.privateColor}>Shared</span>
                        </div>

                    </>
                </div>

                <StyledButton onClick={handleSave} className={classes.button + ' ' + classes.blocksMargin}>
                    <span className={classes.span}>Save</span>
                </StyledButton>

            </div>



            <div style={{display: 'flex', height: '1px'}}></div>
        </div>
    );
}

