import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {LinearProgress, Typography} from '@material-ui/core';

const useStyles = makeStyles({
    mainContainer:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    root: {
        width : '128px',
        height: '8px',
        borderRadius: '3px'
    },
    percentage: {
        fontFamily: 'Assistant',
        fontSize: '8px',
        color: 'rgb(100, 100, 100, 1)',
        letterSpacing: '0.1px',
        marginRight: '4px'
    }
});

export default function WizardProgress({completed, other}) {
    const classes = useStyles();
    return (
        <div className={classes.mainContainer} {...other}>
            <Typography className={classes.percentage}>
                {`${completed}%`}
            </Typography>
            <LinearProgress className={classes.root} variant="determinate" value={completed} />
        </div>
    );
}