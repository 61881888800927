import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ChooseQuestionDialog from './ChooseQuestionDialog';
import * as Actions from '../../redux/actions';

export default function VariableSetup(props) {
    const containerId = useSelector(state => state.document.setupVariableContainerId);
    if (!containerId) return '';

    const dispatch = useDispatch();

    const onQuestionSelected = (selectedQuestion, parentQuestion) => {
        dispatch(Actions.saveVariable(containerId, selectedQuestion, parentQuestion));
    };

    const onClose = () => {
        dispatch(Actions.setupVariable(null));
    };

    return <ChooseQuestionDialog 
        open ={true}
        onClose={onClose}
        onQuestionSelected={onQuestionSelected}
    />;
}
