import React, {useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import {useDispatch, useSelector} from "react-redux";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as Actions from '../../redux/actions';
import {deleteCategory} from "../../redux/actions";

const useStyles = makeStyles(theme => ({
    icon: {
        color: 'rgba(18, 116, 228, 1)',
        width: '24px',
        height: '24px'
    },
}));

function DropdownAutocompleteForCategory() {
    const categories = useSelector(state => state.organization.categories) || [];
    const filter = createFilterOptions();
    const saveCategory = useSelector(state => state.organization.saveCategory);
    const [arrCategory, setArrCategory] = React.useState([]);
    const documentCategoryId = useSelector(state => state.document.document_category_id);

    const dispatch = useDispatch();

    useEffect(() => {
        if (categories?.length) {
            let newArr = [];
            for (let i = 0; i < categories.length; i++) {
                if(categories[i].name !== undefined){
                    newArr[i] = { id: categories[i].id, name: categories[i].name }
                }
            }
            if ( saveCategory?.name !== null && saveCategory?.name?.trim() !== '' ){
                const findInArr = newArr.find(e => e.name.toLowerCase().trim() === saveCategory.name.toLowerCase().trim() );
                if (findInArr === undefined){
                    newArr.push({
                        id: saveCategory.id,
                        name: saveCategory.name,
                    });
                }
            }
            setArrCategory(newArr);
        } else if (arrCategory.length) {
            setArrCategory([{ id: saveCategory.id, name: saveCategory.name }]);
        }
    }, [dispatch, categories])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
                dispatch(Actions.getCategories());
        }, 1)
        return () => clearTimeout(delayDebounceFn)
    }, [])

    useEffect(() => {
        if( saveCategory.name !== null && saveCategory.id === undefined && saveCategory.new  ){
            dispatch(Actions.createCategory({ name: saveCategory.name } ));
        }
    }, [ saveCategory.new ])


    useEffect(() => {
        if(documentCategoryId && categories.length) {
            const categoryName = categories.find(item => item.id === documentCategoryId);
            if(categoryName && categoryName.name) {
                dispatch(Actions.saveCategoryInMemory(documentCategoryId, categoryName.name, null));
            }
        }
    }, [documentCategoryId, categories])

    const renderOption = (option) => {
        if (!option || option.name === null) return '';
        if (option.id === undefined || (option.id === null && option.name !== null)) {
            return (
                <div className="wrapperAutocompleteOption">
                    <span className="left-new">{option.name}</span>
                    <span className="right-new" onClick={(event) => handleAddNew(event, option)}>Add new</span>
                </div>
            );
        } else {
            return (
                <div className="wrapperAutocompleteOption">
                    <span className="left-new">{option.name}</span>
                    <span className="right-new" onClick={(event) => handleDelete(event, option)}>delete</span>
                </div>
            );
        }
    };

    const handleDelete = (event, option) => {
        event.stopPropagation();
        dispatch(Actions.deleteCategory(option.id));
        setTimeout(() => {
            dispatch(Actions.getCategories());
        }, 1000)
    };

    const handleAddNew = (event, option) => {
        event.stopPropagation();
        if (option && option.name) {
            dispatch(Actions.createCategory({ name: option.name }));
            setTimeout(() => {
                dispatch(Actions.getCategories());
            }, 1000);
        }
    };


    const classes = useStyles();


    return (
        <Autocomplete
            className={classes.select}
            classes={{
                clearIndicatorDirty: classes.icon,
                popupIndicator: classes.icon,
            }}
            value={saveCategory}
            onChange={(event, newValue) => {
                if (newValue === null){
                     dispatch(Actions.saveCategoryInMemory(null, null, false));
                } else {
                     dispatch(Actions.saveCategoryInMemory(newValue.id, newValue.name, true));
                }
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option.name);
                if (inputValue !== '' && !isExisting) {
                    filtered.push({
                        id: null,
                        name: inputValue,
                    });
                    // if(arrCategory.length !==0){
                    //     dispatch(Actions.saveCategoryInMemory(options.id, inputValue, null));
                    // }
                }
                return filtered;
            }}
            id="name"
            options={arrCategory}
            getOptionLabel={(option) => {
                if( option.name !== null ){
                    return option?.name;
                } else {
                    return ''
                }
            }}
            // renderOption={(option) => (
            //     <div dangerouslySetInnerHTML={{ __html: renderOption(option) }} />
            // )}
            renderOption={(option) => renderOption(option)}

            renderInput={(params) => (
                <TextField
                    {...params }
                    label="Select category or type new"
                    variant="standard"
                />
            )}
        />
    );
}
export default DropdownAutocompleteForCategory;