import { withStyles } from '@material-ui/core/styles';
import {MenuItem} from '@material-ui/core';

export default withStyles(theme => ({
    root: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        height: '24px',
        '&:hover': {
            backgroundColor: 'rgba(18, 116, 228, 0.2)',
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(18, 116, 228, 0.05)',
            '&:hover': {
                backgroundColor: 'rgba(18, 116, 228, 0.2)',
            }
        }
    },
}))(MenuItem);