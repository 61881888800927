import React from 'react';

import { ADMIN_URL } from '../../api';
import {Link, useParams} from "react-router-dom";

export default function iframeWizardView() {
    const { document_id, filename } = useParams();

    let documentViewUrl = new URL(`${ADMIN_URL}/documents/${document_id}`);
    let params = new URLSearchParams(documentViewUrl.search);
    params.set('filename', filename);
    params.set('forAddin', '1');
    documentViewUrl = documentViewUrl + '?' + params;

    return (
        <>
            <Link style={{
                position: 'absolute',
                top: '20px',
                color: 'rgba(0, 0, 0, 0.87)',
                right: '38px',
                textDecoration: 'none'
            }} to='/checkLists'>Go Back</Link>
            
            <iframe style={{ border: 'none' }} src={documentViewUrl} width='100%' height='600px'/>
        </>
    )
}