import React, {useEffect, useState} from 'react';
import {Link, Redirect} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Header from './Header';
import {LinearProgress, Typography} from "@material-ui/core";
import StyledButton from "./StyledButton";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import * as Actions from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import StyledTextField from "./StyledTextField";
import {isRTL} from '../../utils';
import ClausesPorfolio from "./ClausesPorfolio";
import {clauseSearch} from "../../redux/actions";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'rgba(247, 247, 249, 0.5)',
    },
    main: {
        background: '#FFFFFF',
        borderRadius: '2px',
        width: '310px',
        display: 'flex',
        margin: '10px auto',
        alignItems: 'center',
        flexDirection: 'column'
    },
    button: {
        width: '140px',
        margin: '4px'
    },
    list: {
        width: '100%'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '32px',
        '&:hover' : {
            backgroundColor: 'rgba(36, 232, 255, 0.04)',
            cursor: 'pointer'
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(18, 116, 228, 0.05)'
        },
        '& > p': {
            flexGrow: 1,
            fontSize: '14px',
            fontFamily: 'Assistant'
        }
    },
    title: {
        color: '#030229',
        paddingTop: '14px',
        fontFamily: 'Nunito',
        fontSize: '10px',
        fontWeight: '800',
        fontStyle: 'normal',
    },
    title2: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '800',
        fontSize: '12px',
        color: '#030229',
        margin: '14px auto 0',
        textAlign: 'center',
        lineHeight: '24px',
    },
    description: {
        padding: '12px',
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0.2px',
        color: '#595959',
        marginBottom: '10px'
    },
    progress: {
        marginTop :'12px',
        width: '74%'
    },
    deleteButton: {
        width: '11px',
        height: '11px',
    },
    deleteIcon: {
        width: '11px',
        height: '11px',
    },
    link: {
        padding: '0 20px',
        lineHeight: '33px',
        background: '#1174E5',
        textAlign: 'center',
        display: 'flex',
        margin: '0',
        textDecoration: 'none',
        alignItems: 'center',
        flexDirection: 'row',
        minWidth: '145px',
        boxSizing: 'border-box',
        justifyContent: 'center',
        '&:hover' : {
            backgroundColor: '#126DD5',
            cursor: 'pointer',
        },
    },
    span: {
        color: '#fff',
        paddingLeft: '6px',
        fontWeight: '700',
        fontSize:' 10px',
        fontFamily: 'Nunito',
    },
    textArea: {
        width: '294px',
        padding: '15px',
        boxSizing: 'border-box',
        background: 'rgba(247, 247, 249, 0.5)',
        border: '1px solid #F7F7F9',
        borderRadius: '2px',
        marginTop: '12px',
        marginBottom: '12px',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '15px',
        letterSpacing: '-0.02em',
        color: '#030229',
        '&::placeholder': {
            color: '#030229',
            opacity: '1',
        },
    },
    titleKeep: {
        color: '#1174E5',
        fontFamily: 'Nunito',
        fontSize: '9px',
        fontWeight: '700',
        fontStyle: 'normal',
    },
    colorOne: {
        color: '#9411AC',
        textTransform: 'capitalize',
    },
    colorSecond: {
        color: '#00B298',
        textTransform: 'capitalize',
    },
    bgOne: {
        border: 'none',
        background: 'rgba(148, 17, 172, 0.15)',
        borderRadius: '2px',
        margin: '0',
        boxSizing: 'border-box',
        height: '33px',
        '&:hover' : {
            backgroundColor: 'rgba(148, 17, 172, 0.35)',
            cursor: 'pointer'
        },
        '&:focus, &:active' : {
            backgroundColor: 'rgba(148, 17, 172, 1)',
            cursor: 'pointer',
        },
        '&:focus span, &:focus svg path, &:active span, &:active svg path': {
            color: '#fff',
            fill:  '#fff',
        },
    },
    bgSecond: {
        border: 'none',
        background: 'rgba(0, 178, 152, 0.15)',
        borderRadius: '2px',
        margin: '0',
        boxSizing: 'border-box',
        height: '33px',
        '&:hover' : {
            backgroundColor: 'rgba(0, 178, 152, 0.35)',
            cursor: 'pointer',
        },
        '&:focus, &:active' : {
            backgroundColor: 'rgba(0, 178, 152, 1)',
            cursor: 'pointer',
        },
        '&:focus span, &:focus svg path, &:active span, &:active svg path': {
            color: '#fff',
            fill:  '#fff',
        },
    },

    bgSecondFetchPatent: {
        border: '1px solid rgba(0, 178, 152, 0.15)',
        background: '#fff',
        borderRadius: '2px',
        margin: '0',
        boxSizing: 'border-box',
        height: '33px',
        '&:hover' : {
            backgroundColor: 'rgba(0, 178, 152, 0.35)',
            cursor: 'pointer',
        },
        '&:focus, &:active' : {
            backgroundColor: 'rgba(0, 178, 152, 1)',
            cursor: 'pointer',
        },
        '&:focus span, &:focus svg path, &:active span, &:active svg path': {
            color: '#fff',
            fill:  '#fff',
        },
    },
    filter: {
        background: '#FFFFFF',
        borderRadius: '2px',
        width: '310px',
        height: '80px',
        margin: '0 auto',
    },
    error: {
        background: 'rgba(247, 247, 249, 0.5)',
        border: '1px solid rgba(255, 89, 89, 0.15)',
        borderRadius: '2px',
        '&::placeholder': {
            color: 'rgba(255, 89, 89, 0.5)',
        },
    },
    noBg: {
        background: 'rgba(17, 116, 229, 0.15)',
        borderRadius: '2px',
        width: '34px',
        height: '24px',
        minWidth: 'auto',
        marginRight: '10px',
        marginTop: '10px',
        '&:hover': {
            background: 'rgba(17, 116, 229, 0.35)',
        },
    },
    filterInput: {
        display: 'flex',
        margin: '4px 0 0 0',
        padding: '0 10px',
        width: '100%',
        boxSizing: 'border-box',
        position: 'relative',
    },
    filterHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    textField: {
        width: '100%',
        boxSizing: 'border-box',
        '& :before' : {
            display: 'none',
        },
        '& input' : {
            paddingLeft: '30px',
            boxSizing: 'border-box',
            height: '30px',
            fontFamily: 'Nunito',
            fontStyle: 'normal',
            fontWeight: '400',
            fontSize: '12px',
            color: '#030229',
            // borderBottom: '1px solid rgba(3, 2, 41, 0.03)',
            '& :before' : {
                display: 'none',
            },
        },
        '& input::placeholder': {
            color: '#030229',
            opacity: '1',
        },
    }
}));

export default function MainPage({}) {
    const classes = useStyles();
    const [redirect, setRedirect] = useState(undefined);
    const [redirectPage, setRedirectPage] = useState(undefined);
    const [redirectPageClausesPorfolio, setRedirectPageClausesPorfolio] = useState(undefined);
    const [prompt, setPrompt] = useState(undefined);
    const [search, setSearch] = useState(undefined);
    const [emptyPrompt, setEmptyPrompt] = useState(undefined);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const promptAi = useSelector(state => state.document.promptAi)
    const promptAiForPatent = useSelector(state => state.document.promptAiForPatent)
    const clauseSearch = useSelector(state => state.document.clauseSearch)
    const promptAiError = useSelector(state => state.document.promptAiError)


    useEffect( () => {
       if((promptAi && promptAi.length > 0) || (promptAiForPatent && promptAiForPatent.length > 0) ){
           setRedirect(redirectPage)
       }
    }, [promptAi, promptAiForPatent, redirectPage] );

    useEffect( () => {
        setRedirect(redirectPageClausesPorfolio)
    }, [redirectPageClausesPorfolio, clauseSearch] );



    useEffect( () => {
        if(clauseSearch && clauseSearch.length > 2) {
            setRedirectPageClausesPorfolio('/clausesPorfolio')
        }
    }, [clauseSearch] );

    const handleAskAiClick = async () => {
        if(prompt && prompt.length > 0) {
            setEmptyPrompt(false);
            await setLoading(true);
            await dispatch(Actions.askAiMain( prompt ));
            setRedirectPage('/askAi')
            await setLoading(false);
        } else {
            setEmptyPrompt(true);
        }

    };

    const handleAskPatentClick = async () => {
        if(prompt && prompt.length > 0) {
            setEmptyPrompt(false);
            await setLoading(true);
            await dispatch(Actions.askAiMainForPatent( prompt ));
            setRedirectPage('/askPatent')
            await setLoading(false);
        } else {
            setEmptyPrompt(true);
        }

    };

    const handleAskPatentiWthoutTextClick = async () => {
        setRedirect('/askPatent')
    };

    const handleClausesPorfolio = async () => {
        setRedirectPageClausesPorfolio('/clausesPorfolio')
    };

    const handleClausesSearch = async () => {
        await dispatch(Actions.clauseSearch( search ));
    };



    const handleKeepClick = async () => {
        if(prompt && prompt.length > 0) {
            setEmptyPrompt(false);
            await setLoading(true);
            await dispatch(Actions.askAiMain( prompt ));
            setRedirectPage('/keepClause')
            await setLoading(false);
        } else {
            setEmptyPrompt(true);
        }
    };


    const RTL = isRTL(prompt);

    return redirect ? <Redirect to={redirect} /> :  (

        <div className={classes.root}>
            <Header showButton={false} />
            <div className={classes.filter}>
                <div className={classes.filterHeader}>
                    <Typography className={classes.title2}>Clauses Portfolio</Typography>
                    <Tooltip title="Advanced search" arrow placement="bottom">
                        <StyledButton onClick={handleClausesPorfolio} className={classes.noBg}>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.58585 2.29167C1.4896 2.29167 1.39335 2.32833 1.31543 2.38792C1.11377 2.54375 1.0771 2.8325 1.23293 3.02958V3.03417L3.66668 6.15083V8.89625L4.71627 9.95042C4.89502 10.1292 5.18835 10.1292 5.3671 9.95042C5.54585 9.77167 5.54585 9.47833 5.3671 9.29958L4.58335 8.52042V5.83458L1.9571 2.47042C1.87002 2.35583 1.73252 2.29167 1.58585 2.29167ZM7.33335 5.5V9.11167C7.35168 9.24917 7.30585 9.39583 7.20043 9.49208C7.02168 9.67083 6.73293 9.67083 6.55418 9.49208L5.63293 8.57083C5.52752 8.46542 5.48168 8.32333 5.50002 8.19042V5.5H5.48627L2.84627 2.1175C2.69043 1.92042 2.7271 1.63167 2.92418 1.47583C3.01127 1.41167 3.10752 1.375 3.20835 1.375H9.62502C9.72585 1.375 9.8221 1.41167 9.90918 1.47583C10.1063 1.63167 10.1429 1.92042 9.9871 2.1175L7.3471 5.5H7.33335Z" fill="#1174E5"/>
                            </svg>
                        </StyledButton>
                    </Tooltip>

                </div>
                <div className={classes.filterInput}>
                    <span onClick={handleClausesSearch} style={{position: 'absolute', left: '16px', top: '5px', cursor: 'pointer', zIndex: '999'}}>
                        <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="4.09091" cy="4.09091" r="3.51818" stroke="#030229" stroke-width="1.14545"/>
                            <line x1="8.19004" y1="9" x2="6.54545" y2="7.35541" stroke="#030229" stroke-width="1.14545" stroke-linecap="round"/>
                        </svg>
                    </span>
                    <StyledTextField
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handleClausesSearch()
                            }
                        }}
                        onChange={(e)=> {setSearch(e.target.value);}} type='text' placeholder='Search a clause that contains…' name='filter' className={classes.textField}>

                    </StyledTextField>
                </div>
            </div>
            <div className={classes.main}>
                {(isLoading) && <LinearProgress color="primary" className={classes.progress}/>}
                <Typography className={classes.title}>Optimize your Clauses</Typography>
                { RTL ? <div dir={"rtl"}>
                    <TextareaAutosize minRows={15} placeholder='Copy Paste your clause here...' name='prompt'
                                      className={ emptyPrompt ? classes.textArea + ' ' + classes.error :  classes.textArea}
                                      value={prompt}
                                      onChange={(e)=> {setPrompt(e.target.value);}}
                    />
                </div> :  <TextareaAutosize minRows={15} placeholder='Copy Paste your clause here...' name='prompt'
                                            className={ emptyPrompt ? classes.textArea + ' ' + classes.error :  classes.textArea}
                                            value={prompt}
                                            onChange={(e)=> {setPrompt(e.target.value);}}
                />}

                <div style={{
                    display: 'flex',
                    marginBottom: '15px',
                    width: '294px',
                    justifyContent: 'space-between',
                }}>
                    <StyledButton onClick={handleKeepClick} className={classes.button + ' ' + classes.bgOne} style={{ flex: '2 1 auto' }}>
                        <svg className={classes.svg} width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.66669 6.99998V2.66665H9.20836V1.58331H3.79169V2.66665H4.33336V6.99998L3.25002 8.08331V9.16665H6.06669V12.4166H6.93336V9.16665H9.75002V8.08331L8.66669 6.99998Z" fill="#9411AC"/>
                        </svg>
                        <span className={classes.span + ' ' + classes.colorOne}>Keep</span>
                    </StyledButton>

                    <StyledButton onClick={handleAskAiClick} className={classes.button + ' ' + classes.bgSecond} style={{margin: '0 4px' }}>
                        <svg className={classes.svg} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.45956 9.23748C9.69789 8.86373 9.83331 8.41415 9.83331 7.93748C9.83331 6.58331 8.74998 5.49998 7.39581 5.49998C6.04164 5.49998 4.95831 6.58331 4.95831 7.93748C4.95831 9.29165 6.04164 10.375 7.39581 10.375C7.86706 10.375 8.31123 10.2396 8.68498 10.0066L10.375 11.6696L11.1279 10.9166L9.45956 9.23748ZM7.39581 9.29165C6.64831 9.29165 6.04164 8.68498 6.04164 7.93748C6.04164 7.18998 6.64831 6.58331 7.39581 6.58331C8.14331 6.58331 8.74998 7.18998 8.74998 7.93748C8.74998 8.68498 8.14331 9.29165 7.39581 9.29165ZM4.19456 9.39998L0.624977 6.62123L1.50248 5.93873L3.87498 7.78581C3.87498 7.83998 3.87498 7.88873 3.87498 7.93748C3.87498 8.45748 3.99414 8.95581 4.19456 9.39998ZM1.50789 4.5629L0.624977 3.87498L5.49998 0.083313L10.375 3.87498L9.48664 4.5629L9.10206 4.86081C8.59831 4.57915 8.01331 4.41665 7.39581 4.41665C5.92789 4.41665 4.66581 5.32665 4.14581 6.6104L1.50789 4.5629Z" fill="#00B298"/>
                        </svg>
                        <span className={classes.span + ' ' + classes.colorSecond }>Ask GPT</span>
                    </StyledButton>

                    <StyledButton onClick={handleAskPatentiWthoutTextClick} className={classes.button + ' ' + classes.bgSecond} style={{margin: '0 4px 0 0' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
                            <path fill="#00B298" d="M40,6H8C6.895,6,6,6.895,6,8v32c0,1.105,0.895,2,2,2h32c1.105,0,2-0.895,2-2V8	C42,6.895,41.105,6,40,6z"></path>
                            <path fill="#ffffff" d="M22.197,14.234h-4.404L10.037,33.67c0-0.096,4.452,0,4.452,0l1.484-4.069h8.234l1.58,4.069h4.261	L22.197,14.234z M17.362,26.059l2.729-6.894l2.633,6.894C22.723,26.059,17.266,26.059,17.362,26.059z"></path>
                            <path fill="#ffffff" d="M25.963,14.234L33.59,33.67h4.356l-7.803-19.436C30.144,14.234,25.963,14.186,25.963,14.234z"></path>
                        </svg>
                        <span className={classes.span + ' ' + classes.colorSecond }>Ask Claude</span>
                    </StyledButton>

                    <Tooltip title="Copy paste the *patent* number and *click here*" arrow placement="bottom">
                        <StyledButton onClick={handleAskPatentClick} className={classes.button + ' ' + classes.bgSecondFetchPatent} style={{
                            width: '40px',
                            minWidth: '40px',
                            maxWidth: '40px',
                            padding: '0',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            boxSizing: 'border-box',
                        }}>
                            <span className={ classes.colorSecond }>Fetch patent</span>
                        </StyledButton>
                    </Tooltip>
                </div>
                <div style={{ display: 'none'}}>
                    <Link to='#' className={classes.titleKeep}>Keep clauses of an entire doc</Link>
                </div>

            </div>
            <div style={{display: 'flex', height: '1px'}}></div>
        </div>
    );
}
