import React from 'react';
import {withStyles} from '@material-ui/styles';
import {Switch} from '@material-ui/core';

export default withStyles(theme => ({
    root: {
        width: '42px',
        height: '20px',
        padding: '0px 0px 2px 0px',
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(22px)',
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.white,
            borderColor: theme.palette.common.white,
            '& + $track': {
                backgroundColor: 'rgba(18, 116, 228, 1)',  //'#52d869',                
                opacity: 1,
                borderColor: theme.palette.common.white
            },
        },
        '&$focusVisible $thumb': {
            color: theme.palette.common.white, //'rgba(18, 116, 228, 1)',   //'#52d869',
            border: '2px solid #fff',
        },
    },
    thumb: {
        width: 18,
        height: 18,
        color: 'rgba(18, 116, 228, 0.75)',
        boxShadow: 'none',
    },
    track: {
        borderRadius: 9,
        border: '1px solid rgba(228, 236, 245, 1)',
        backgroundColor: 'rgba(237, 245, 255, 1)',
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {
      backgroundColor: 'magenta'
    },
    focusVisible: {}
  }))(({ classes, ...props }) => {
    return (
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          {...props}
        />
    );
});