import React, {useState, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Toolbar, IconButton, Typography, CircularProgress } from '@material-ui/core';
import {Redirect} from 'react-router-dom';
import StyledButton from './StyledButton';
import ArrowLeftIcon  from '@material-ui/icons/ArrowLeft';
import LoggedInUserContext from '../../loggedInUserContext';
import AlertDialog from './AlertDialog';

const useStyles = makeStyles(theme => ({
    mainHeader: {
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid rgba(25, 25, 25, 0.06);',        
    },
    grow: {
        flexGrow: 1,
    },
    buttonContainer: {
        borderRight: '0.1em solid rgba(235, 235, 235, 1)', 
        paddingRight: '25px'
    },
    title: {
        fontFamily: 'Assistant',
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'rgba(22, 31, 41, 1)',
        textAlign: 'left',
        paddingLeft: '25px'
    },
    saveButton: {
        marginRight: '24px'
    },
    onTop: {
        zIndex: 5000
    }
}));

function NewObjectHeader({dirty, isSaving, onSaveClick, title}) {
    const classes = useStyles();
    const [redirectBack, setRedirectBack] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const context = useContext(LoggedInUserContext);

    const toURL = `${context.lastURL.pathname}${context.lastURL.search}`;

    const onBackPressed = async () => {
        if (dirty) {
            console.log({dirty});
            setShowAlert(true);
        }
        else setRedirectBack(true);
    };

    return redirectBack ? <Redirect to={toURL} /> : ( 
        <AppBar position="sticky" className={classes.onTop}>
            {showAlert && <AlertDialog 
                title='Discard Changes' 
                text={`Are you sure you want to discard changes? (${dirty})`} 
                open={showAlert} 
                buttonTexts={['Cancel', 'DISCARD']}
                onOk={()=>{setRedirectBack(true); setShowAlert(false)}} 
                onCancel={()=>{setShowAlert(false);}} />}
            <Toolbar className={classes.mainHeader}>
                <div className={classes.buttonContainer}>
                    <IconButton aria-label="delete" size="small" onClick={onBackPressed}>
                        <ArrowLeftIcon fontSize="small" />
                    </IconButton>
                </div>
                <Typography className={classes.title}>{title}</Typography>
                <div className={classes.grow} />
                {isSaving && <CircularProgress /> }
                {!isSaving && <StyledButton onClick={onSaveClick} className={classes.saveButton}>Save</StyledButton>}
            </Toolbar>
        </AppBar>
    );
}
export default NewObjectHeader;