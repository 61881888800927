import React, {useState, useContext} from 'react';
import {Link, Redirect} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import StyledButton from './StyledButton';
import Header from './Header';
import StyledTextField from './StyledTextField';
import Api from '../../api';
import LoggedInUserContext from '../../loggedInUserContext';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '12px',
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    textField: {
        paddingBottom: '12px'
    },
    title: {
        color: '#4789d2'
    },
    message: {
        margin: '8px 18px',
        fontSize: '12px',
        fontFamily: 'Assistant'
    },
    link: {
        fontFamily: 'Assistant',
        fontSize: '10px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#4789d2',
        marginTop: '8px'
    },
    error: {
        display: 'flex',
        position: 'relative',
        padding: '10px 8px',
        alignItems: 'center',
        width: '320px',
        marginBottom: '10px',
        marginTop: '10px',
        boxSizing: 'border-box',
        background:' rgba(255, 89, 89, 0.15)',
        borderRadius: '4.2px 4.2px 1px 1px',
        border: '1px solid rgba(255, 89, 89, 0.1)',
    },
    errorIcon: {
        marginRight: '8px',
    },
    closeError: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        zIndex: '2',
        cursor: "pointer",
    },
}));

export default function SignupView(props) {
    let classes = useStyles();
    let [redirectHome, setRedirectHome] = useState(false);
    let [email, setEmail] = useState(undefined);
    let [password, setPassword] = useState(undefined);
    let userContext = useContext(LoggedInUserContext);
    const [showError, setShowError] = useState(undefined);

    const message = `Cut 85% in documents drafting time by automating your own templates! 
    With LegalUp your documents will be instantly populated with the right fields and wordings to fit any scenario. Soon your users will be able to fill and generate complex and bespoke sets of legal documents in minutes.`;

    const handleClick = async (e) => {
        try {
            const user = await Api.users.create(email, password);
            await userContext.update(user.id);
            await setRedirectHome(true);
            setShowError(undefined)
        }
        catch(e) {
            setShowError('This username already exists. Try to login with this email address, or the Signup with a different email address.');
            console.error(e);
        }
    };

    const onCloseError = e => {
        setShowError(undefined);
    };

    return redirectHome ? (<Redirect to='/' />) : (<div>
            <Header showButton={false} />
            <div className={classes.root}>
                <Typography className={classes.message}>{message}</Typography>
                <Typography className={classes.title}>Signup</Typography>
                {
                    showError ?
                        <div className={classes.error}>
                            <ErrorIcon htmlColor='rgba(255, 89, 89, 0.15)' className={classes.errorIcon}/>
                            {showError}
                            <svg className={classes.closeError} onClick={onCloseError} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.25006 2.15937L5.34069 1.25L3.75006 2.84063L2.15944 1.25L1.25006 2.15937L2.84069 3.75L1.25006 5.34062L2.15944 6.25L3.75006 4.65937L5.34069 6.25L6.25006 5.34062L4.65944 3.75L6.25006 2.15937Z" fill="#E51111"/>
                            </svg>
                        </div> : null
                }
                <StyledTextField type='email' placeholder='email' name='email' className={classes.textField}
                    onChange={(e)=> {setEmail(e.target.value);}}></StyledTextField>
                <StyledTextField type='password' placeholder='password' name='password' className={classes.textField }
                onChange={(e)=> {setPassword(e.target.value);}}></StyledTextField>
                <StyledButton disabled={!email || !password} onClick={handleClick}>Signup</StyledButton>
                <Link to='/login' className={classes.link}>Already a member?</Link>                
            </div>
        </div>
    );
}
