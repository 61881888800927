import React, { useState, useEffect, useContext } from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import loggedInUserContext from '../../loggedInUserContext';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Box, FormControl } from '@material-ui/core';
import NewObjectHeader from './NewObjectHeader';
import StyledSelect from './StyledSelect';
import StyledMenuItem from './StyledMenuItem';
import StyledTextField from './StyledTextField';
import SingleQuestionView from './SingleQuestionView';
import MultiQuestionsForm from './MultiQuestionsForm';

import * as Actions from '../../redux/actions';
import ConditionView from './ConditionView';
// import { ObjectId } from '../../utils';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            overflow-y="scroll"                            
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    label: {
        opacity: '0.77',
        fontFamily: 'Assistant',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.22px',
        textAlign: 'center',
        color: '#97a1b0',        
    },
    indicator: {
        backgroundColor: "#1274e4"
    },
    tab: {
        backgroundColor: 'white',
        boxShadow: 'none',
        '&.Mui-selected > span > span': {
            fontFamily: 'Assistant',
            fontSize: '11px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.12px',
            textAlign: 'center',
            color: '#162250'
        }        
    },
    settingsIcon: {
        height: '16px',
        width: '16px'
    },
    appBar: {
        borderBottom: '1px solid rgba(25, 25, 25, 0.06);'
    },
    select: {
        display: 'flex',
    },
    title: {
        paddingTop: '4px',
        paddingBottom: '4px',
        fontFamily: 'Assistant',
        fontWeight: '600',
        fontSize: '16px',
        color: 'rgba(53, 55, 61, 1)',
        textAlign: 'left',
        alignSelf: 'center',
        flexGrow: '0'
    },
    formControl: {
        width: '100%',        
    },
    icon: {
        color: 'rgba(18, 116, 228, 1)',
        width: '24px',
        height: '24px'
    },
    row: {
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    multipleHint: {
        height: '24px',
        width: '178px',
        fontFamily: 'Assistant',
        fontSize: '13px',
        color: 'rgba(166, 105, 199, 1)',
        textAlign: 'left',
        marginLeft: '4px'
    },
    editButton: {
        width: '14px',
        height: '14px',
        fill: 'black'
    },
    formLabel: { 
        fontFamily: 'Assistant',
        fontWeight: 400,
        letterSpacing: '0.4px',
        fontSize: '13px',
        color: 'rgba(53, 55, 61, 1)',
        textAlign: 'left',
        marginRight: '6px'
    },
    listIcon: {
        width: '36px',
        height: '36px',
    },
    button: {
        minWidth: '28px',
        padding: '0px',
        margin: '0px',
        alignSelf: 'center'
    },
    spacedRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    textField: {
        width: '100%',
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(189, 189, 189, 1)',
        textAlign: 'left',
        lineHeight: '21px'
    },
    toggleButton: {
        width: '22px',
        height: '22px'
    },
    innerIcon: {
        width: '18px',
        height: '18px'
    },
    stylingDiv: {
        marginBottom: '2px',
        alignSelf: 'center'
    },
    temp: {
        fontFamily:'Assistant', 
        fontSize: '14px', 
        fontWeight: 500, 
        marginTop: '24px'
    },
    mandatorySwitch: {
        alignSelf: 'flex-end',
        marginBottom: '4px'
    },
    sectionContainer: {
        padding: '14px 22px',
        '&:hover': {
            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.11)',
            cursor: 'pointer'
        }
    },
    radioLabel: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(77, 84, 92, 1)'
    },
    italic: {
        fontStyle: 'italic'
    },
    bold: {
        fontWeight: 600
    },
    underline: {
        textDecoration: 'underline'
    },
    textBoxDiv: {
        padding: '6px'
    },
    calculated: {
        textAlign: 'left',
        fontFamily: 'Assistant',
        fontSize: '12px'
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontFamily: 'Assistant',
        fontSize: '12px'
    },
    selectRoot: {
        padding: '0px'
    },
    typeContainer: {
        maxWidth: '45%'
    },
    dottedDivider: {
        height: '1px',
        borderBottom: '1px dashed rgba(187, 187, 187, 1);',
        margin: '8px'

    },
    addOtherLabel: {
        fontFamily: 'Assistant',
        fontsize: '16px',
        color: 'rgba(53, 55, 61, 1)',
        textAlign: 'left'
    },
    addOtherHelper: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        color: 'rgba(143, 143, 143, 1)',
        textAlign: 'left'
    },
    addOtherSwitch: {
        alignSelf: 'center',
        marginRight: '12px'
    },
    bottomDweller: {
        height: '240px'
    },
    choice: {
        display: 'flex',
        flexDirection: 'row',
        height: '34px',
        backgroundColor: 'rgba(245, 245, 245, 1);',        
        alignItems: 'center',
        margin: '8px 0px',
        '&.Mui-selected': {
            backgroundColor: 'rgba(240, 246, 253, 1)',
            border: '1px solid rgba(182, 210, 242, 1);'
        }
    },
    choiceIndex: {
        fontFamily: 'Assistant',
        fontWeight: '500',
        fontSize: '16px',
        color: 'rgba(18, 116, 228, 1)',
        textAlign: 'center',
        margin: '0px 12px'
    },
    choiceText: {        
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(98, 98, 98, 1)',
        textAlign: 'left',
        margin: '0px 12px',
        flexGrow: 1
    },
    newRowValueField: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        textAlign: 'left',
        color: 'rgba(98, 98, 98, 1);',
        margin: '0px 8px',
    },
    addValueButton: {
        marginTop: '12px'
    },
    justifyNone: {
        justifyContent: 'flex-start'
    },
    iconButton: {
        margin: '0px, 8px',
        minWidth: '22px',
        padding: '0px',
        alignSelf: 'center'
    },
    erazeIcon: {
        width: '16px',
        height: '16px',
        margin: '0px 8px'
    },
    conditionTitle: {
        fontFamily: 'Assistant',
        fontWeight: 500,
        fontSize: '14px',
        color: 'rgba(87, 96, 107, 1)',
        textAlign: 'left'
    },
    hintTitle: {
        fontFamily: 'Assistant',
        fontWeight: '600',
        fontSize: '14px',
        color: 'rgba(87, 96, 107, 1)',
        textAlign: 'left',
        lineHeight: '20px'
    },
    greyedOut: {
        color: 'rgba(208, 208, 208, 1)',
        fontSize: '12px',
        marginBottom :'12px'
    },
    helpTextBox: {
        width: '100%',
        backgroundColor: 'rgba(238, 238, 238, 1)'
    }
}));

const questionKinds = ['Single question', 'Multi questions form'];
const questionKindsValues = ['single_question', 'multi_questions_form'];
const questionTypesValues = ['text_box', 'number', 'email', 'address', 'date', 'attachment', 'drop_down', 'checkboxes', 'radio_buttons']; 
const repeatKindsValues = ['do_no_repeat', `let_user_add`, 'repeat_for_every_answer', 'repeat_for_every_value'];

const NewQuestionView = ({location, index, match}) => {
    const classes = useStyles();
    const theme = useTheme();

    const [dirty, setDirty] = useState(undefined);
    const [currentTab, setCurrentTab] = useState(0); // left most tab as default.
    const [redirectBack, setRedirectBack] = useState(false);

    const context = useContext(loggedInUserContext);
    const backURL = `${context.lastURL.pathname}${context.lastURL.search}`;

    const dispatch = useDispatch();
    const currentQuestion = useSelector(state => state.questions.currentQuestion);
    const errors = useSelector(state => state.questions.errors);
    const loadingPhase = useSelector(state => state.questions.loadingPhase);
    const selectedDocId = useSelector(state => state.document.documentId, shallowEqual);
    const passedQuestionId = match.params.question_id;    
    const questionKind = questionKindsValues[currentQuestion.is_multi ? 1 : 0];
    
    const onSaveClick = async () => {
        const vMan = await validateMandatories();
        const v = await validate();
        console.log(v, vMan);
        if (!(v && vMan))  {
            return;
        }
        if (passedQuestionId) {
            await dispatch(Actions.updateQuestion(selectedDocId, passedQuestionId, currentQuestion));
        } else {
            await dispatch(Actions.ensureQuestion(selectedDocId, currentQuestion));
        }
        await setDirty(undefined);
        await setRedirectBack(true);
    };
    
    const validateMandatorySingleQuestion = async (q) => {
        let questionUpdate = {};
        let valid = true;
        ['title'].forEach(m => {
            valid = valid && !(q[m] === undefined || q[m] === '');
            questionUpdate[q.id] = Object.assign(questionUpdate[q.id] || {}, {[`${m}`]: (valid) ? undefined :`${m} is a mandatory field`});
        });
        const finalUpdate = Object.assign(errors[q.id] || {}, questionUpdate);
        await dispatch(Actions.setErrors(Object.assign(errors, finalUpdate)));
        return valid;
    };
    const validateMandatories = async () => {
        let valid = await validateMandatorySingleQuestion(currentQuestion);
        console.log('valid outer', valid);
        if (currentQuestion.is_multi && currentQuestion.children && currentQuestion.children.length > 0) {
            console.log('children;', currentQuestion.children);
            for (let i = 0; i < currentQuestion.children.length; i++) {
                valid = valid && await validateMandatorySingleQuestion(currentQuestion.children[i]);
                console.log('Manda innervalidL:', valid);
            }
        }
        if (!valid) await setCurrentTab(0);        
        return valid;
    }

    const validateSingleQuestion = async (q) => {
        let errorsUpdate = {};
        let valid = true;

        //numbers
        const {min_value, max_value} = q;
        valid = !(min_value !== undefined && max_value != undefined && (min_value > max_value || max_value < min_value));
        errorsUpdate[q.id] = Object.assign(errors[q.id] || {} , {minMax: valid ? undefined: 'please make sure min. <=  max.'});
        await dispatch(Actions.setErrors(Object.assign(errors, errorsUpdate)));
        return valid;
    };

    const validate = async () => {
        let valid = await validateSingleQuestion(currentQuestion);
        if (currentQuestion.is_multi && currentQuestion.children) currentQuestion.children.forEach(async (q) => valid = valid && await validateSingleQuestion(q));
        return valid;
    }

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };    

    const handleIsMultiChange = async e =>  {
        // await setQuestionKind(e.target.value);
        const updatedQ = Object.assign({}, currentQuestion, {is_multi: e.target.value === questionKindsValues[1]});
        await dispatch(Actions.setCurrentQuestion(updatedQ));
    };
    const handleHelpTextChanged = async e => {
        const updatedQ = Object.assign({}, currentQuestion, {help_text: e.target.value});
        await dispatch(Actions.setCurrentQuestion(updatedQ));
    };

    return redirectBack ? <Redirect to={backURL} /> : (        
        <div className={classes.root}>            
            <NewObjectHeader title='Add Question' boxShadow={0} onSaveClick={onSaveClick} isSaving={loadingPhase !== ''} dirty={dirty} />
            <AppBar position="sticky" color="default" elevation={0} className={classes.appBar}>
                <Tabs
                    className={classes.tab} 
                    classes={{ indicator: classes.indicator }} 
                    value={currentTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    >
                    <Tab className={classes.tab} label={<span className={classes.label} style={{width:'112px'}}>{`Question(${1})`}</span>} />
                    <Tab className={classes.tab} label={<span className={classes.label} style={{width:'112px'}}>PRE-CONDITIONS</span>} />
                    <Tab className={classes.tab} label={<span className={classes.label}>HINTS</span>} />
                </Tabs>
            </AppBar>
            <TabPanel value={currentTab} index={0} dir={theme.direction} className={classes.tabPanel}>                
                <div className={classes.sectionContainer}>
                    <FormControl className={classes.formControl}>
                        <StyledSelect name='kind'
                            className={classes.select}
                            value={questionKind || questionKindsValues[0]}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                }
                            }}
                            onChange={handleIsMultiChange}>
                            {questionKinds.map((name, idx) => (
                                <StyledMenuItem                                    
                                    dense 
                                    value={questionKindsValues[idx]} 
                                    key={`${name}${idx}`}>{name}</StyledMenuItem>
                            ))}
                        </StyledSelect>
                    </FormControl>
                </div>
                {questionKind === questionKindsValues[0] && <SingleQuestionView setDirty={setDirty} />}
                {questionKind === questionKindsValues[1] && <MultiQuestionsForm setDirty={setDirty} />}
            </TabPanel>
            <TabPanel value={currentTab} index={1} dir={theme.direction}>
                <Typography className={classes.conditionTitle}>{`The Question (${currentQuestion.title}) will be presented if:`}</Typography>
                <ConditionView setDirty={setDirty} />
            </TabPanel>
            <TabPanel value={currentTab} index={2} dir={theme.direction}>
                <Typography className={classes.hintTitle}>Enter text explaining the question:</Typography>
                <br />
                <Typography className={classNames(classes.hintTitle, classes.greyedOut)}>(Will show when clicking the help button)</Typography>
                <br />
                <StyledTextField placeholder='Enter Hint text' rows='4'
                    classes={{root: classes.helpTextBox}} 
                    value={currentQuestion.help_text} 
                    onChange={handleHelpTextChanged}
                    multiline variant="outlined" />
            </TabPanel>
        </div>
    );
}
export default withRouter(NewQuestionView);