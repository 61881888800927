import { enableES5 } from 'immer';
import { combineReducers } from 'redux';
import documentReducer from './reducers/document';
import questionsReducer from './reducers/questions';
import organizationReducer from './reducers/organization';

enableES5();//before any logic

export default combineReducers({
    document: documentReducer,
    questions: questionsReducer,
    organization: organizationReducer,
});
