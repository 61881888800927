import React, {useState, useContext} from 'react';
import {Link, Redirect} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, LinearProgress } from '@material-ui/core';
import StyledButton from './StyledButton';
import Header from './Header';
import StyledTextField from './StyledTextField';
import Api from '../../api';
import LoggedInUserContext from '../../loggedInUserContext';

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: '12px',
        backgroundColor: theme.palette.background.paper,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    textField: {
        paddingBottom: '22px'
    },
    title: {
        fontFamily: 'Assistant',
        color: '#4789d2',
        margin: '12px 0px'
    },
    text: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        margin: '12px 12px'
    },
    link: {
        fontFamily: 'Assistant',
        fontSize: '10px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#4789d2',
        marginTop: '8px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        fontFamily: 'Assistant',
        fontSize: '10px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        alignItems: 'center'
    },
    progress: {
        width: '74%',
        margin: 'auto'
    }
}));

export default function ForgotPasswordView(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState(undefined);
    const [token, setToken] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [phase, setPhase] = useState(undefined);
    const [redirect, setRedirect] = useState(undefined);
    const handleClick = async () => {
        await Api.users.forgotPassword(email);
        setPhase('reset');
    }
    const resetClick = async () => {
        await Api.users.resetPassword(email, token, password);
        setPhase('done');
    }
    const handleLoginClick = async () => {
        setRedirect('/login');
    };

    return redirect ? <Redirect to={redirect} /> : (<div>
            <Header showButton={false} />
            {phase === undefined && <div className={classes.root}>
                <Typography className={classes.title}>Forgot Password</Typography>
                <StyledTextField type='email' placeholder='email' name='email' className={classes.textField}
                    onChange={(e)=> {setEmail(e.target.value);}}></StyledTextField>
                <Typography className={classes.text}>Clicking the button will send you a pin to your email address</Typography>
                <StyledButton disabled={email === undefined} onClick={handleClick}>Send Pin</StyledButton>
            </div>}
            {phase === 'reset' && <div className={classes.root}>
                <Typography className={classes.title}>Reset Password</Typography>
                <StyledTextField type='number' placeholder='token' name='token' className={classes.textField}
                    onChange={(e)=> {setToken(e.target.value);}}></StyledTextField>
                <StyledTextField type='password' placeholder='new password' name='password' className={classes.textField}
                    onChange={(e)=> {setPassword(e.target.value);}}></StyledTextField>
                <Typography className={classes.text}>Please copy and paste the pin we've sent you onto the token box and choose a new password to reset.</Typography>
                <StyledButton onClick={resetClick}>Reset</StyledButton>
            </div>}
            {phase === "done" && <div className={classes.root}>
                <Typography className={classes.text}>Your password has been reset</Typography>
                <StyledButton disabled={email === undefined} onClick={handleLoginClick}>Login</StyledButton>
            </div>}
            {isLoading && <LinearProgress className={classes.progress} className={classes.progress} />}
        </div>
    );
}
