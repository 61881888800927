import React from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Redirect} from 'react-router-dom';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {LinearProgress, IconButton, List, ListItem, Typography} from '@material-ui/core';
import Header from './Header';
import api  from '../../api';
import StyledButton from './StyledButton';
import * as Actions from '../../redux/actions';
import AlertDialog from './AlertDialog';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SERVER_URL } from '../../api';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    main: {
        marginTop: '18px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    button: {
        width: '164px',
        margin: '4px'
    },
    list: {
        width: '100%'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '32px',
        '&:hover' : {
            backgroundColor: 'rgba(36, 232, 255, 0.04)',
            cursor: 'pointer'
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(18, 116, 228, 0.05)'
        },
        '& > p': {
            flexGrow: 1,
            fontSize: '14px',
            fontFamily: 'Assistant'
        }
    },
    title: {
        color: '#4789d2',
    },
    description: {
        padding: '12px',
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0.2px',
        color: '#595959',
        marginBottom: '10px'
    },
    progress: {
        marginTop :'12px',
        width: '74%'
    },
    deleteButton: {
        width: '11px',
        height: '11px',
    },
    deleteIcon: {
        width: '11px',
        height: '11px',
    },
    titleBack: {
        color: '#9411AC',
        fontFamily: 'Nunito',
        fontSize: '9px',
        fontWeight: '700',
        fontStyle: 'normal',
        textDecoration: 'none',
        marginLeft: '10px',
        cursor:"pointer",
    },
}));

export default function DocumentList({}) {
    const classes = useStyles();
    const [documents, setDocuments] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [newWizard, setNewWizard] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(undefined);
    const [targetOnCopiedDocument, setTargetOnCopiedDocument] = useState(false);

    const dispatch = useDispatch();
    const selectedDocId = useSelector(state => state.document.documentId, shallowEqual)
    const loadingPhase = useSelector(state => state.document.loadingPhase, shallowEqual);
    const progress = useSelector(state => state.document.loadProgress, shallowEqual);
    const [error, setError] = useState(false);

    const [redirectPage, setRedirectPage] = useState(undefined);

    const user =  JSON.parse(localStorage.getItem('current_user'));
    const role = user && user.id ? user.role : '';
    const document = useSelector(state => state.document);

    useEffect( () => {
        const init = async () => {
            await setLoading(true);
            try {
                const docs = await api.documents.getDocuments();
                await setDocuments(docs);
                await setLoading(false);
            } catch (error) {
                console.error(error);
                await setError(true);
            }
        }
        init();
        Actions.initGlobalWS(dispatch)
    }, [] );

    useEffect( () => {
        const init = async () => {
            if(document && document.copiedDocument !== null && targetOnCopiedDocument){
                dispatch(Actions.loadDocx(document.copiedDocument.id, document.copiedDocument.name));
                setTargetOnCopiedDocument(false)
                await setLoading(true);
                try {
                    const docs = await api.documents.getDocuments();
                    await setDocuments(docs);
                    await setLoading(false);
                } catch (error) {
                    console.error(error);
                    await setError(true);
                }
            }
        }
        init();

    }, [document] );

    const handleOnClick = (docId, name) => {
        dispatch(Actions.loadDocx(docId, name));
    }
    const handleNewWizard = async () => {
        await setNewWizard(true);        
        await dispatch(Actions.importDocument());
    }

    if (error) {
        localStorage.removeItem('current_user');
        localStorage.removeItem('access_token');
        return <Redirect to='/login' />;
    }

    const handleCopyDocument = async (docId) => {
        setLoading(true);
        await dispatch(Actions.copyDocument(docId));

        setTargetOnCopiedDocument(true)
    }

    const handleBack = async () => {
        setRedirectPage(`/`)
    };

    const docs = documents && documents.documents;
    const selectedTo = selectedDocId ? `/main?selected_document_id=${selectedDocId}` : '/main'; 
    const availableText = `${docs && docs.length ? '' : 'No '}Available Wizards`;    
    const showProgress = ['fetching', 'loading'].indexOf(loadingPhase.trim().toLowerCase()) >= 0;

    return  redirectPage ? <Redirect to={redirectPage} /> : (newWizard || selectedDocId) ? <Redirect to={selectedTo} /> : (
        <div className={classes.root}>
            <Header showButton={false} />
            <AlertDialog 
                title='Are you sure?' 
                text={`You are about to delete document ${docs && deleteCandidate && docs.find(d=>d.id === deleteCandidate).name}`} 
                open={showDeleteDialog} 
                onOk={() => {
                    //Actual delete
                    dispatch(Actions.deleteDocument(deleteCandidate));
                    const idx = documents.documents.find(d => d.id === deleteCandidate);
                    documents.documents.splice(idx, 1);
                    setShowDeleteDialog(false);
                    setDeleteCandidate(undefined);
                }}
                onCancel={()=>{
                    setShowDeleteDialog(false);
                    setDeleteCandidate(undefined);
                }} />
            <div className={classes.main}>

                <div style={{display: 'flex', marginBottom: '20px', justifyContent: 'space-between', width: '100%', alignItems: 'center',}}>
                    <span className={classes.titleBack} onClick={handleBack}>
                        <svg className={classes.svg} width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5C9.27614 5 9.5 4.77614 9.5 4.5C9.5 4.22386 9.27614 4 9 4V5ZM0.646446 4.14645C0.451184 4.34171 0.451184 4.65829 0.646446 4.85355L3.82843 8.03553C4.02369 8.2308 4.34027 8.2308 4.53553 8.03553C4.7308 7.84027 4.7308 7.52369 4.53553 7.32843L1.70711 4.5L4.53553 1.67157C4.7308 1.47631 4.7308 1.15973 4.53553 0.964466C4.34027 0.769204 4.02369 0.769204 3.82843 0.964466L0.646446 4.14645ZM9 4L1 4V5L9 5V4Z" fill="#9411AC"/>
                        </svg>
                        <span className={classes.titleBack}>Back</span>
                    </span>
                    <Typography className={classes.title}>Get Started</Typography>
                    <div> </div>
                </div>

                <Typography className={classes.description}>To start a new LegalUp wizard, open the Word document you want to use as a template, click “Create a New Wizard”, and then define the questions and alternative clauses. Once the template is done, you can access and run your templates through our admin site (https://admin.legalup.me).
                </Typography>
                <StyledButton disabled={isLoading} className={classes.button} onClick={handleNewWizard}>Create a New Wizard</StyledButton>
                {(isLoading  || showProgress) && <LinearProgress color="primary" className={classes.progress}/>}
                {!(isLoading || showProgress) && <Typography className={classes.title}>{availableText}</Typography>}
                {!(isLoading || showProgress) && docs && <List className={classes.list}>
                    {docs.map((d, i) => {
                        return <ListItem 
                            onClick={()=> {
                                handleOnClick(d.id, d.name);
                            }} 
                            key={i} 
                            className={classes.listItem}>
                            <Typography>{d.name}</Typography>
                            {role.indexOf('admin') >= 0 && <IconButton disabled={!!loadingPhase} aria-label="delete" color="primary" className={classes.deleteIcon} onClick={e=>{
                                e.preventDefault();
                                e.stopPropagation();
                                setDeleteCandidate(d.id);
                                setShowDeleteDialog(true);
                            }}>
                                <img src={`${SERVER_URL}/assets/icons_delete.svg`} className={classes.deleteButton} />
                            </IconButton>}

                            {role.indexOf('admin') >= 0 && <FileCopyIcon
                                disabled={!!loadingPhase}
                                 aria-label="copy"
                                 color="primary"
                                 className={classes.deleteIcon}
                                 onClick={e=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    handleCopyDocument(d.id)
                                }}>

                            </FileCopyIcon>}
                        </ListItem>
                    })
                }
                </List>}                
            </div>
        </div>
    );
}
