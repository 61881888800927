import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {Typography, Tabs, Tab} from '@material-ui/core';
import WizardQuestionView from './WizardQuestionView';
import {isRTL} from '../../utils';
import {makeRTL} from './utils';

const useStyles = makeStyles({
    mainContainer:{
        margin: '52px 32px 28px 32px',        
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch', 
        alignContent: 'stretch'
    },
    title: {
        fontFamily: 'Assistant',
        fontSize: '28px'
    },
    text: {
        fontFamily: 'Assistant',
        fontSize: '18px'
    },
    explanation: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        color: 'rgba(99, 99 ,99, 0.6)'
    },
    label: {
        opacity: '0.77',
        fontFamily: 'Assistant',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.22px',
        textAlign: 'center',
        color: '#97a1b0',        
    },
    indicator: {
        backgroundColor: "#1274e4"
    },
    tab: {
        backgroundColor: 'white',
        boxShadow: 'none',
        '&.MuiTab-root': {
            width: '50%'
        },
        '&.Mui-selected > span > span': {
            fontFamily: 'Assistant',
            fontSize: '11px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.12px',
            textAlign: 'center',
            color: '#162250'            
        }        
    },
});

export default function WizardMultipleQuestionView({question, onAnswerChange, answers}) {
    const classes = useStyles();
    const [partyTab, setPartyTab] = useState(0);
    const children = question.children;
    const partyQuestion = question.children.filter(q=>q.read_only)[0];
    const RTL = isRTL(question.text);
    const [childrenAnswers, setchildrenAnswers] = useState(answers[question.id] || []);
    const onChildChange = async (e, id) => {
        let next = childrenAnswers.splice(0);
        const found = next.findIndex(a=>a.question_id === id);
        if (found >= 0) next[found] = {question_id: id,  value: e.target.value};
        else next.push({question_id: id,  value: e.target.value});
        await setchildrenAnswers(next);
        onAnswerChange(next);
    };
    let header = <div>
        <Typography className={classes.title}>{question.title}</Typography>
        <Typography className={classes.text}>{question.text}</Typography>
        <Typography className={classes.explanation}>{question.explanation_text}</Typography>
        <br />
    </div>;
    if (RTL) header = makeRTL(header);
    return <div className={classes.mainContainer}>
        {header}
        {partyQuestion && <Tabs
            className={classes.tab} 
            classes={{ indicator: classes.indicator }} 
            value={partyTab}
            onChange={(e, newVal) => setPartyTab(newVal)}
            indicatorColor="primary"
            textColor="primary">
            <Tab className={classes.tab} label={<span className={classes.label}>INDIVIDUAL</span>} />
            <Tab className={classes.tab} label={<span className={classes.label}>CORPORATION</span>} />
        </Tabs>
        }
        {partyQuestion && children.map(q => !q.read_only && q.conditions && 
            q.conditions[0].question_id === partyQuestion.id && q.conditions[0].value === partyTab.toString() && 
            <WizardQuestionView 
                key={q.id} question={q} 
                onAnswerChange={e => onChildChange(e, q.id)} 
                val={childrenAnswers.filter(a=>a.question_id === q.id).length > 0 && childrenAnswers.filter(a=>a.question_id === q.id)[0].value || ''} />
        )}
        {!partyQuestion && children.map (q => <WizardQuestionView 
            key={q.id} question={q} 
            onAnswerChange={e => onChildChange(e, q.id)} 
            val={childrenAnswers.filter(a=>a.question_id === q.id).length > 0 && childrenAnswers.filter(a=>a.question_id === q.id)[0].value || ''} /> 
        )}
    </div>
}