import {TextField} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';

export default withStyles({
    root: {
        // width: '180px',
        '& input, textarea': {            
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
            fontFamily: 'Assistant',
            fontSize: '12px',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#505050'
        },
        '& input::placeholder': {
            fontFamily: 'Assistant',
            fontSize: '12px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#565656'
        },
        '& label.Mui-focused': {
            color: '#4789d2',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#4789d2',
        },
        '& .MuiInput-underline:before': {
            borderBottomColor: 'rgba(208, 208, 208, 1)',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(238, 238, 238, 1)',
                borderRadius: '4px',
            },
            '&:hover fieldset': {
                borderColor: '#808080',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#4789d2',
            },
        },
    },
})(TextField);