import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import {withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';
import DocumentExplorer from './DocumentExplorer';
import QuestionsView from  './QuestionsView';
import Header from './Header';
import * as Actions from '../../redux/actions';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}        
            {...other}
        >
            <Box p={3} style={{padding: '0px'}}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    label: {
        opacity: '0.77',
        fontFamily: 'Assistant',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.22px',
        textAlign: 'center',
        color: '#97a1b0',        
    },
    indicator: {
        backgroundColor: "#1274e4"
    },
    tab: {
        backgroundColor: 'white',
        boxShadow: 'none',
        '&.Mui-selected > span > span': {
            fontFamily: 'Assistant',
            fontSize: '11px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.12px',
            textAlign: 'center',
            color: '#162250'
        }        
    },
    settingsIcon: {
        height: '16px',
        width: '16px'
    },
    appBar: {
        borderBottom: '1px solid rgba(25, 25, 25, 0.06);',
        top: '48px'
    }
}));

const MainView = ({location}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const selectedTab = useSelector(state => state.document.selectedTab);
    const questions = useSelector(state => state.questions.questions);

    function handleChange(event, newValue) {
        dispatch(Actions.setSelectedTab(newValue));
    }
    const params = new URLSearchParams(location.search);
    return (        
        <div className={classes.root}>
            <Header boxShadow={0} />
            <AppBar position="sticky" color="default" elevation={0} className={classes.appBar}>
                <Tabs
                    className={classes.tab} 
                    classes={{ indicator: classes.indicator }} 
                    value={selectedTab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    >
                    <Tab className={classes.tab} label={<span className={classes.label} style={{width:'156px'}}>{`Questions(${questions.filter(q => { return q.parent_id === undefined; }).length || 0})`}</span>} />
                    <Tab className={classes.tab} label={<span className={classes.label} style={{width:'156px'}}>Clauses</span>} />
                </Tabs>
            </AppBar>
            <TabPanel value={selectedTab} index={0} dir={theme.direction}>                
                <QuestionsView />
            </TabPanel>
            <TabPanel value={selectedTab} index={1} dir={theme.direction}>
                <DocumentExplorer/>
            </TabPanel>
            {/* <TabPanel value={selectedTab} index={2} dir={theme.direction}>
            Settings
            </TabPanel> */}
        </div>
    );
}
export default withRouter(MainView);