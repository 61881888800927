import * as Actions from '../actions';
import { createReducer } from '@reduxjs/toolkit';

const initialState = {
   descendants_ids: [],
   id: null,
   name: "",
   parent_id: null,
   monday_key: null,
   //on_finish_target_origin: '',
   created_at: null,
   updated_at: null,
   saveCategory:{
    id: null,
    name: null,
    new: false,
},
   categories: []
};

const loadOrganization = (state, action) => {

   if (typeof action.success === 'boolean') {
      if (action.success) {
        state.descendants_ids = action.organization.descendants_ids;
        state.id = action.organization.id;
        state.name = action.organization.name;
        state.parent_id = action.organization.parent_id;
        state.monday_key = action.organization.monday_key;
        //state.on_finish_target_origin = action.organization.on_finish_target_origin;
        state.created_at = action.organization.created_at;
        state.updated_at = action.organization.updated_at;
      } else if (action.success === false) {
         state.error = action.error;
      }

      state.loadingPhase = '';
   } else {
      state.loadingPhase = action.type === Actions.SAVE_DOCUMENT ? 'saving' : 'fetching';
   }

};


const getCategories = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.categories = action.categories.documents || [];
    }
}

const saveCategoryInMemory = (state, action) => {
    state.saveCategory.id = action.id;
    state.saveCategory.name = action.name;
    state.saveCategory.new = action.isNew;
}

const createCategory = (state, action) => {
    if (typeof action.success === 'boolean') {
        state.categories[state.categories.length] = action.category;
        state.saveContact = {
            id: action.category.id,
            name: action.category.name,
        }
    }
}

const handlers = {};
handlers[Actions.LOAD_ORGANIZATION] = loadOrganization;
handlers[Actions.GET_CATEGORIES] = getCategories;
handlers[Actions.SAVE_CATEGORY_IN_MEMORY] = saveCategoryInMemory;
handlers[Actions.CREATE_CATEGORY] = createCategory;

export default createReducer(initialState, handlers);