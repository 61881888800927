import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Actions from '../../redux/actions';


function LoadingPage(props) {    
    const [documentId, setDocumentId] = useState(undefined);
    const dispatch = useDispatch();
    console.log('loading page called');
    useEffect(() => {
        Word.run((context) => {
            const document = context.document;
            document.load('properties');
            console.log('word is called');
            context.sync().then(() => {
                try {
                    const keywords = document.properties ? document.properties.keywords : '';
                    const company = document.properties ? document.properties.company : '';
                    const category = document.properties ? document.properties.category : '';
                    if (company === 'LegalUP') {
                        if (category === 'document') {
                            dispatch(Actions.loadDocument(keywords));
                            setDocumentId(keywords);
                        } else if (category === 'clause'){
                            const [documentId, clauseId] = keywords.split(',');
                            setDocumentId(documentId);
                            dispatch(Actions.loadDocument(documentId, clauseId));
                        } else {
                            setDocumentId('');
                        }
                    } else {
                        setDocumentId('');
                    }        
                } catch (error) {
                    console.error(error);
                    setDocumentId('');
                }
            });
        });        
    }, []);

    if (documentId === '') {
        return <Redirect to='/mainPage' />
    } else if (documentId) {
        return <Redirect to={'/main'} />
    } else {
        return <div>invalid route in loading page</div>
    }
}
export default LoadingPage;