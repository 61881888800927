import React, { useState } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {Chip, BaseInput , Avatar, Typography} from '@material-ui/core';
import ColorButton from './ColorButton';
import AlertDialog from  './AlertDialog';
import ConditionLine from './ConditionLine';
import * as Actions from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: '24px'
    },
    dottedDivider: {
        height: '1px',
        borderBottom: '1px dashed rgba(187, 187, 187, 1);',
        margin: '18px 0px'
    },
    selectIcon: {
        color: 'rgba(18, 116, 228, 1)',
        width: '24px',
        height: '24px'
    },
    andOrSelector: {
        width: '60px',
        padding: '6px 14px',
    },
    textLogic: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: 300
    },
    orChip: {
        margin: '18px 0px 18px 30px'

    },
    andChip: {
        margin: '12px 0px 0px 80px'
    },
    addButton: {
        margin: '0px 0px 0px 0px'
    }
}));

function ConditionView(props) {
    const classes = useStyles();
    const [showAlert, setShowAlert] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState(undefined);
    const {setDirty} = props;
    const currentQuestion = useSelector(state => state.questions.currentQuestion);
    const allQuestions = useSelector(state => state.questions.questions);
    let conditions = currentQuestion.conditions || [];
    const dispatch = useDispatch();

    const handleAddCondition = async (oper, where) => {
        console.log('handling add with ', oper,where);
        const c = [...currentQuestion.conditions || []];
        if (oper !== undefined) {
            const newOne = {operator: oper};
            if (where !== undefined) {
                c.splice(where + 1, 0, newOne)
            } else {
                c.push(newOne);
            }
        }
        else {
            c.push({});
        }
        const q = Object.assign({}, currentQuestion, {conditions: c});
        await dispatch(Actions.setCurrentQuestion(q));
    };

    const handleQuestionChanged = async (e, idx) => {
        conditions[idx][e.target.name] = e.target.value;
        await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {conditions})));
    };

    const handleDelete = async (idx) => {
        await setDeleteCandidate(idx);
        await setShowAlert(true);
    };

    const onAlertOkClick = async () => {
        const newConditions = [...conditions];
        newConditions.splice(deleteCandidate, 1);
        conditions = newConditions;
        await setDeleteCandidate(undefined);
        await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {conditions})));
        await setShowAlert(false);
    };

    const minIgnoreList = [currentQuestion.id];
    const currIdx = allQuestions.findIndex(q => q.id === currentQuestion.id);
    // console.log('total questions', allQuestions.length);
    // console.log('found index =', currIdx);

    for (let i = currIdx + 1; i < allQuestions.length; i++) {
        minIgnoreList.push(allQuestions[i].id);
    }
    // console.log({minIgnoreList});

    const conditionItems = [];
    for (let i = 0; i < conditions.length; i++) {
        const isAnd = conditions[i].operator !== undefined && conditions[i].operator.toLowerCase() === 'and';
        const isPartOfAndClause = i !== 0 && isAnd || // either this is and AND line, or next line is and AND line
            conditions[i+1] && conditions[i+1].operator && conditions[i+1].operator.toLowerCase() === 'and';
        const nextOneIsOr = i+1 === conditions.length || conditions[i+1] && (conditions[i+1].operator === undefined || conditions[i+1].operator.toLowerCase() === 'or');
        conditionItems.push(<ConditionLine
            //hiddenQuestionIds={currentQuestion.is_multi ? [currentQuestion.id, ...currentQuestion.children_ids] :[currentQuestion.id]} //TODO: hide everything after me
            hiddenQuestionIds={minIgnoreList}
            key={i}
            index={i}
            onDelete={handleDelete}
            onQuestionChanged={handleQuestionChanged}
            condition={conditions[i]}
            isPartOfAndClause={isPartOfAndClause}
            />);
        if (nextOneIsOr) {
            conditionItems.push(<div style={{'marginLeft': '60px'}} key={`div${i}`}>
                <ColorButton className={classes.addButton} onClick={() => {handleAddCondition('and', i);}}>+ Add AND Condition</ColorButton>
            </div>);
        }
        if (i + 1 < conditions.length) {
            conditionItems.push(<div >
                <Chip label={nextOneIsOr ? 'OR' : 'AND'} color={!nextOneIsOr? "action" : ""}
                    avatar={<Avatar>{nextOneIsOr ? '+' : '*'}</Avatar>}
                    size="small"
                    className={!nextOneIsOr ? classes.andChip : classes.orChip}
                />
            </div>);
        }
    }

    let calculatedTextLogic = `This question will be shown if and only if`;
    for (let ii = 0; ii < conditions.length; ii++) {
        const c = conditions[ii];
        const theQuestion = allQuestions.find(q => q.id === c.question_id);
        if (theQuestion) {
            calculatedTextLogic = `${calculatedTextLogic} ${ii !== 0 && c.operator && c.operator.toUpperCase() || ''} ${c.question_id ? theQuestion.text : ''} ${c.type || ''} ${c.value || ''}`;
        } else {
            calculatedTextLogic = 'missing question from condition';
        }
    }

    return (
        <div className={classes.root}>
             <AlertDialog
                title='Are you sure?'
                text={`3 You are about to delete a condition, are you sure you want to do it?`}
                open={showAlert}
                onOk={onAlertOkClick}
                onCancel={()=>{setShowAlert(false);}} />

            {conditionItems}
            <div className={classes.dottedDivider} />
            <ColorButton onClick={() => {handleAddCondition(conditions.length === 0 ? undefined : "or");}}>{`+ Add ${conditions.length > 0 ? ' OR': 'a '} Condition`}</ColorButton>
            <div className={classes.dottedDivider} />
            <Typography className={classes.textLogic}>{calculatedTextLogic}</Typography>
        </div>
    );
}

export default withRouter(ConditionView);
