import React, { useState, useEffect } from 'react';
import {useSelector} from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {List, ListItem, ListItemText, Dialog, DialogTitle, DialogContent, Typography}  from '@material-ui/core';
import SearchBox from './SearchBox';
import {isRTL} from '../../utils';

const useStyles = makeStyles(theme => ({
    root: {
        
    },
    paper: {
        margin: '0px',
        minHeight: '264px',
        maxHeight: '480px',        
        overflowY: 'scroll'
    },
    title: {
        fontFamily: 'Assistant',        
        color: 'white',
        backgroundColor: theme.palette.primary[700],
        height: '28px',
        '& > .MuiTypography-root': {
            fontSize: '15px',
            fontWeight: 500,
        }
    },
    listItemText: {
        display: 'flex',
        flexDirection: 'row',
    },
    text: {
        fontFamily: 'Assistant',
        fontWeight: '400',
        fontSize: '12px',
        color: 'rgb(88, 88, 88, 0.88)',
        textAlign: 'right',
        width: '72px',
        marginRight: '12px',
        flexGrow: 1
    },
    centeredText:{
        fontFamily: 'Assistant',
        fontWeight: '400',
        fontSize: '12px',
        color: 'rgb(88, 88, 88, 0.88)',
        textAlign: 'center',
        width: '72px',
        marginRight: '12px',
        flexGrow: 1
    },
    searchContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        borderBottom: '1px solid rgba(208, 208, 208, 1)',
        height: '44px',
        alignItems: 'center'
    },
    questionNumber: {
        fontFamily: 'Assistant',
        fontWeight: '700',
        letterSpacing: '0.2px',
        fontSize: '18px',
        color: 'rgba(37, 48, 91, 1)',
        textAlign: 'left',
        marginRight: '24px',
        alignSelf: 'center'
    },
    leftAlign: {
        textAlign: 'left'
    },
    rightAlign: {
        textAlign: 'right'
    }
}));

export default function ChooseQuestionDialog(props) {
    const classes = useStyles();
    const [criteria, setCriteria] = useState(undefined);
    const { onClose, onQuestionSelected, open} = props;
    const defilter = () => true;
    const filter = props.filter || defilter;
    const questions = useSelector(state => state.questions.questions).filter(filter);    
    // console.log({props});
    const foundQuestions = criteria ? questions.filter(q => !q.is_multi && q.text.toLowerCase().indexOf(criteria.toLowerCase()) >= 0) : questions;
    // const multiQuestions = questions.filter(q => q.is_multi);
    // const singleNonChildrenQuestions = foundQuestions.filter(q => !q.is_multi && !q.parent_id) ;
    // const [shownList, setShownList] = useState([]);
    
    // arrange all shown questions in the same order as doc.questions
    
    // useEffect(() => {   
    //     if (!questions || questions.length === 0) return;
    //     const finalList = [];
    //     questions.filter(q => q.parent_id === null).map(quest => {
    //         if (quest.is_multi) {
    //             quest.map(inner => {
    //                 finalList.push(multiQuestions.find(q=>q.id === inner.id));
    //             })
    //         } else {
    //             finalList.push(singleNonChildrenQuestions.find(q=>q.id ===quest.id));
    //         }
    //     });
    //     setShownList(finalList);
    //     console.log('arranged list:', finalList);
    // },[questions])
    
    // console.log('FOUND', foundQuestions.filter((q, idx) => q.parent_id === undefined));

    const handleClose = () => {
        onClose();
    };
    const handleSearchChange = async e => {
        await setCriteria(e.target.value);
    }; 

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} className={classes.root} scroll='paper'
            classes={{paper: classes.paper}}>
            <DialogTitle id="simple-dialog-title" classes={{root: classes.title}} >Choose Question</DialogTitle>
            <div className={classes.searchContainer}>
                <SearchBox autoFocus placeholder='Search Question' className={classes.searchBox} onChange={handleSearchChange} />
                <Typography className={classes.questionNumber}>{criteria ? foundQuestions.length : questions.filter(q => !q.is_multi).length}</Typography>
            </div>
            <DialogContent>
                <List>
                    {foundQuestions && foundQuestions.filter((q) => q.parent_id === undefined).map((question, idx) => {
                        if (question.is_multi) {
                            const partyHiddenQuestion = question.children.find(c => c.read_only);
                            const children = [];
                            const multiText = `---- Q${idx + 1} ${question.title} (Multi) ----`;
                            children.push(<ListItem disabled>
                                <ListItemText style={{textAlign: 'center'}} className={classes.listItemText} classes={{primary: classes.centeredText}}>{multiText}</ListItemText>
                            </ListItem>);
                            {question.children.map((child, childIndex) => {
                                const label = child.conditions.filter(c => c.question_id === partyHiddenQuestion.id && c.value === "0").length === 1 ? 'Ind' : 'Corp';
                                children.push(<ListItem button onClick={() => onQuestionSelected(child, question)} key={childIndex}>
                                    <ListItemText classes={{primary: classes.text}}>{`Q0${idx + 1}.0${childIndex + 1}`}</ListItemText>
                                    <ListItemText className={classes.listItemText} classes={{primary: classes.text}}>{question.party_form ? 
                                    `${question.title}${child.read_only ? '.' : '.[' + label +'].'}${child.text}` :
                                        // `${question.text}->${child.conditions && child.conditions.length > 0 && child.conditions[0].value === 0 ? 'Individual' : 'Corporation'}->${questions.filter(q => q.id===child.id).length > 0 && questions.filter(q=>q.id===child.id)[0].text}`:  
                                        questions.filter(q => q.id===child.id).length > 0 && questions.filter(q=>q.id===child.id)[0].text}</ListItemText>
                                </ListItem>);
                            })}
                            return children;
                        } else {
                            return (<ListItem button onClick={() => onQuestionSelected(question)} key={idx}> 
                                <ListItemText classes={{primary: classes.text}}>{`Q0${idx + 1}`}</ListItemText>
                                <ListItemText className={classes.listItemText} classes={{primary: classes.text}}>{question.text || question.name}</ListItemText>
                            </ListItem>)
                        }
                    })}
                </List>
            </DialogContent>
        </Dialog>
  );
}

