import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Button} from '@material-ui/core';


const ColorButton = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant !important',
        height: '32px',
        fontSize: '14px !important',
        fontWeight: '700 !important',
        color: 'rgba(18, 116, 228, 1)',
        textAlign: 'left'
    },
}))(Button);

export default ColorButton;