import { isRTL } from './utils';

const INDICATION_COLORS = ['aff7d7', 'f7afcf', 'f7d7af', 'b3aff7', 'f3f7af'];

const getContentControlXml = (alias, tag, appearance, cannotEdit, cannotDelete, placeholderId, content) => {
    const lock = cannotDelete ? 'sdt' + (cannotEdit ? 'Content' : '') + 'Locked' : cannotEdit ? 'contentLocked' : 'unlocked';
    return `<w:sdt><w:sdtPr><w:alias w:val="${alias}"/><w:tag w:val="${tag}"/><w:lock w:val="${lock}"/><w:placeholder><w:docPart w:val="${placeholderId}"/></w:placeholder><w15:appearance w15:val="${appearance}"/></w:sdtPr><w:sdtContent>${content}</w:sdtContent></w:sdt>`;
};

const darkColor = (hex) => {
    const r = Math.round(parseInt(hex.slice(0,2), 16) * 0.7);
    const g = Math.round(parseInt(hex.slice(2,4), 16) * 0.7);
    const b = Math.round(parseInt(hex.slice(4,6), 16) * 0.7);
    return r.toString(16) + g.toString(16) + b.toString(16);
};

export function cleanClause(clauseOoxml, empty) {
    // this method extracts the content of the outer most content control
    // then cleans it from garbage and injects it back as the body of the ooxml
    // this will clean all content control common properties identifying this clause
    // we store all this information parsed in the DB so no need to have it in the raw data
    const start = clauseOoxml.indexOf('<w:sdtContent>') + '<w:sdtContent>'.length;
    const end = clauseOoxml.lastIndexOf('</w:sdtContent>');
    const content = clauseOoxml.replace(/(<w:body>)(.*?)(<\/w:body>)/, `$1${empty ? '' : clauseOoxml.slice(start, end)}$3`);
    return content.replace(/<w:sectPr\s*w:rsidR=\"00000000\"[^>]*?>[\s\S]*?<\/w:sectPr>/, '') //clear section properties
        .replace(/<w:p[^a-zA-Z][^>]*?\/>/g, '') // remove empty paragraphs (should we ?)
        .replace(/w:rsidR=\".*?\"\s*/g, '') // word generated id attributes
        .replace(/w:rsidP=\".*?\"\s*/g, '') // word generated id attributes
        .replace(/w:rsidRPr=\".*?\"\s*/g, '') // word generated id attributes
        .replace(/w:rsidRDefault=\".*?\"\s*/g, '') // word generated id attributes
        .replace(/<w:bookmarkStart.*?\/>/g, '') //remove bookmark starts
        .replace(/<w:bookmarkEnd.*?\/>/g, '') //remove bookmark ends
}

export function clearTextRuns(ooxml) {
    return ooxml.replace(/<w:r\s*?>.*?<\/w:r>/g, '')
}

export function getTextRuns(ooxml) {
    const runs = ooxml.match(/<w:r\s*?>.*?<\/w:r>/g);
    return (runs && runs.length > 0) ? runs.join('') : '';
}

export function extractBody(ooxml) {
    const match = ooxml.match(/<w:body>(.+?)<\/w:body>/);
    return match ? match[1] : '';
}

export function replaceBody(ooxml, body) {
    return ooxml.replace(/(<w:body>)(.*?)(<\/w:body>)/, `$1${body}$3`);
}

export function getEmptyVariableXml() {
    const backColor = 'B5D2FF';
    const borderColor = '68A4FF';
    return `<w:p>
    <w:r>
        <w:rPr>
            <w:shd w:val="clear" w:color="white" w:fill="${backColor}"/>
            <w:bdr w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
        </w:rPr>
        <w:t>Select Question</w:t>
    </w:r>
</w:p>`;
}

export function getEmptySignatureXml() {
    const backColor = '68A4FF';
    const borderColor = 'B5D2FF';
    return `<w:p>
    <w:r>
        <w:rPr>
            <w:shd w:val="clear" w:color="white" w:fill="${backColor}"/>
            <w:bdr w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
        </w:rPr>
        <w:t>Select Source</w:t>
    </w:r>
</w:p>`;
}

export function getPhraseHeaderXml(title) {
    const backColor = 'E3E7F1';
    const borderColor = '8186D5';

    return `<w:p>
    <w:r>
        <w:rPr>
            <w:shd w:val="clear" w:color="white" w:fill="${backColor}"/>
            <w:bdr w:val="single" w:sz="4" w:space="0" w:color="${borderColor}"/>
        </w:rPr>
        <w:t xml:space="preserve">${title}</w:t>
    </w:r>
</w:p>`;  
}

export function getPhraseXml(phrase, placeholderId) {
    const backColor = 'f3ebfe';
    const rtl = isRTL(phrase.alternatives[0].text);
    let colorIndex = 0;
    const handleAlt = (alt) => {
        const indicationColor = darkColor(INDICATION_COLORS[colorIndex % INDICATION_COLORS.length]);

        colorIndex++;
        return `<w:sdt>
    <w:sdtPr>
        <w:alias w:val="${alt.id}"/>
        <w:tag w:val="p_header"/>
        <w:lock w:val="sdtContentLocked"/>
        <w15:appearance w15:val="hidden"/>
        <w:placeholder><w:docPart w:val="${placeholderId}"/></w:placeholder>
    </w:sdtPr>
    <w:sdtContent>
        <w:r>
            <w:rPr>
                <w:color w:val="${indicationColor}"/>
                ${rtl ? '<w:rtl/>' : ''}
                <w:vertAlign w:val="superscript"/>
            </w:rPr>
            <w:t>${alt.name}</w:t>
        </w:r>
        <w:r>
            <w:rPr>
                <w:color w:val="${indicationColor}"/>
                ${rtl ? '<w:rtl/>' : ''}
            </w:rPr>
            <w:t xml:space="preserve"> [</w:t>
        </w:r>
    </w:sdtContent>
</w:sdt>
<w:sdt>
    <w:sdtPr>
        <w:alias w:val="${alt.id}"/>
        <w:tag w:val="p_alternative"/>
        <w:lock w:val="sdtLocked"/>
        <w15:appearance w15:val="hidden"/>
    </w:sdtPr>
    <w:sdtContent>
        ${alt.raw_data}
    </w:sdtContent>
</w:sdt>
<w:sdt>
    <w:sdtPr>
        <w:alias w:val="${alt.id}"/>
        <w:tag w:val="p_footer"/>
        <w:lock w:val="sdtContentLocked"/>
        <w15:appearance w15:val="hidden"/>
    </w:sdtPr>
    <w:sdtContent>
        <w:r>
            <w:rPr>
                <w:color w:val="${indicationColor}"/>
                ${rtl ? '<w:rtl/>' : ''}
            </w:rPr>
            <w:t xml:space="preserve">] </w:t>
        </w:r>
    </w:sdtContent>
</w:sdt>`;
    };
    
    return phrase.alternatives.map(handleAlt).join('');
}
