import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Typography, Checkbox, FormGroup, Tab, Tabs, FormControl, FormHelperText, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import StyledTextField from './StyledTextField';
import StyledSelect from './StyledSelect';
import StyledMenuItem from './StyledMenuItem';
import {isRTL} from '../../utils';
import {makeRTL} from './utils';

const useStyles = makeStyles({
    mainContainer:{
        margin: '52px 32px 28px 32px',        
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch', 
        alignContent: 'stretch'
    },
    title: {
        fontFamily: 'Assistant',
        fontSize: '28px',
    },
    text: {
        fontFamily: 'Assistant',
        fontSize: '18px',
    },
    explanation: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        color: 'rgba(99, 99 ,99, 0.6)'
    },
    alignRight: {
        textAlign: 'right'
    },
});

export default function WizardQuestionView({question, onAnswerChange, val}) {
    const classes = useStyles();
    const RTL = isRTL(question.text);
    const q = <div className={classes.mainContainer}>
        <Typography className={classNames(classes.title)}>{question.title}</Typography>
        <Typography className={classNames(classes.text)}>{`${question.text}`}</Typography>
        <Typography className={classNames(classes.explanation)}>{question.explanation_text}</Typography>
        <br />
        {['text_box', 'number', 'email','address', 'date', 'attachment'].indexOf(question.type) >= 0 && <StyledTextField
            placeholder={question.placeholder} defaultValue ={question.default_value} value={val} onChange={onAnswerChange}
            multiline rowsMax={question.num_lines}
        />}
        {'drop_down' === question.type && <StyledSelect
            placeholder={question.placeholder} defaultValue ={question.default_value} value={val} onChange={onAnswerChange}
        >
            {question.possible_answers && question.possible_answers.map((val, idx) => (
                <StyledMenuItem                                    
                    dense 
                    value={val} 
                    key={`${val}${idx}`}>{val}</StyledMenuItem>
            ))}
        </StyledSelect>
        }
        {'radio_buttons' === question.type && <FormControl component="fieldset" className={classes.formControl}>
            <RadioGroup value={val} onChange={onAnswerChange}>
            {question.possible_answers && question.possible_answers.map((val, idx) => (
                <FormControlLabel value={val} control={<Radio />} label={val} />
            ))}
            </RadioGroup>
        </FormControl>
        }
        {'checkboxes' === question.type && <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>                           
                {question.possible_answers && question.possible_answers.map((v, idx) => (
                     <FormControlLabel control={<Checkbox checked={val===v} onChange={onAnswerChange} value={v} />} 
                     label={v} />
                ))}
            </FormGroup>
            {/* <FormHelperText>Be careful</FormHelperText> */}
        </FormControl>
        }        
        {question.hint_text && <FormHelperText className={isRTL ? 'alignRight' : ''}>{question.hint_text}</FormHelperText>}
    </div>;
    return RTL ? makeRTL(q) : q;
}