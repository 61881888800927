import React from 'react';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {LinearProgress, IconButton, List, ListItem, Typography} from '@material-ui/core';
import Header from './Header';
import api  from '../../api';
import * as Actions from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    main: {
        marginTop: '18px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column'
    },
    button: {
        width: '164px',
        margin: '4px'
    },
    list: {
        width: '100%'
    },
    listItem: {
        display: 'flex',
        flexDirection: 'row',
        height: '32px',
        '&:hover' : {
            backgroundColor: 'rgba(36, 232, 255, 0.04)',
            cursor: 'pointer'
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(18, 116, 228, 0.05)'
        },
        '& > p': {
            flexGrow: 1,
            fontSize: '14px',
            fontFamily: 'Assistant'
        }
    },
    title: {
        color: '#4789d2',
    },
    description: {
        padding: '12px',
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0.2px',
        color: '#595959',
        marginBottom: '10px'
    },
    progress: {
        marginTop :'12px',
        width: '74%'
    },
    deleteButton: {
        width: '11px',
        height: '11px',
    },
    deleteIcon: {
        width: '11px',
        height: '11px',
    },
    link: {
        color: '#333',
        textDecoration: 'none',
        display: 'block',
    },
    titleBack: {
        color: '#9411AC',
        fontFamily: 'Nunito',
        fontSize: '9px',
        fontWeight: '700',
        fontStyle: 'normal',
        textDecoration: 'none',
        marginLeft: '10px',
        cursor: "pointer",
    },
}));

export default function DocumentWithCheckLists({}) {
    const classes = useStyles();
    const [documents, setDocuments] = useState({});
    const [isLoading, setLoading] = useState(true);

    const dispatch = useDispatch();
    const selectedDocId = useSelector(state => state.document.documentId, shallowEqual)
    const loadingPhase = useSelector(state => state.document.loadingPhase, shallowEqual);
    const [error, setError] = useState(false);
    const [redirectPage, setRedirectPage] = useState(undefined);

    useEffect( () => {
        const init = async () => {
            await setLoading(true);
            try {
                const docs = await api.documents.getDocuments('-updated_at', 100, 0, true);

                await setDocuments(docs);
                await setLoading(false);
            } catch (error) {
                console.error(error);
                await setError(true);
            }
        }
        init();
        Actions.initGlobalWS(dispatch)
    }, [] );

    if (error) {
        localStorage.removeItem('current_user');
        localStorage.removeItem('access_token');
        return <Redirect to='/login' />;
    }

    const handleBack = async () => {
        setRedirectPage(`/`)
    };

    const docs = documents && documents.documents;
    const selectedTo = selectedDocId ? `/main?selected_document_id=${selectedDocId}` : '/main'; 
    const availableText = `${docs && docs.length ? '' : 'No '}Available Wizards CheckLists`;
    const showProgress = ['fetching', 'loading'].indexOf(loadingPhase.trim().toLowerCase()) >= 0;

    return  redirectPage ? <Redirect to={redirectPage} /> : (
        <div className={classes.root}>
            <Header showButton={false} />

            <div className={classes.main}>




                {(isLoading  || showProgress) && <LinearProgress color="primary" className={classes.progress}/>}
                {!(isLoading || showProgress) &&
                    <div style={{display: 'flex', marginBottom: '20px', justifyContent: 'space-between', width: '100%', alignItems: 'center',}}>
                        <span className={classes.titleBack} onClick={handleBack}>
                            <svg className={classes.svg} width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 5C9.27614 5 9.5 4.77614 9.5 4.5C9.5 4.22386 9.27614 4 9 4V5ZM0.646446 4.14645C0.451184 4.34171 0.451184 4.65829 0.646446 4.85355L3.82843 8.03553C4.02369 8.2308 4.34027 8.2308 4.53553 8.03553C4.7308 7.84027 4.7308 7.52369 4.53553 7.32843L1.70711 4.5L4.53553 1.67157C4.7308 1.47631 4.7308 1.15973 4.53553 0.964466C4.34027 0.769204 4.02369 0.769204 3.82843 0.964466L0.646446 4.14645ZM9 4L1 4V5L9 5V4Z" fill="#9411AC"/>
                            </svg>
                            <span className={classes.titleBack}>Back</span>
                        </span>
                        <Typography className={classes.title}>{availableText}</Typography>
                        <div> </div>
                    </div>
                }
                {!(isLoading || showProgress) && docs && <List className={classes.list}>
                    {docs.map((d, i) => {
                        return <ListItem
                            key={i} 
                            className={classes.listItem}>
                            <Typography>
                                {<Link className={classes.link} to={ `/document/${d.id}/iframe/checkLists`}>{d.name}</Link>}
                            </Typography>

                        </ListItem>
                    })
                }
                </List>}                
            </div>
        </div>
    );
}
