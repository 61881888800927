import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
// import {AppBar, Toolbar, IconButton,  } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    mainHeader: {
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid rgba(25, 25, 25, 0.06);'
    },
    row:{
        display:'flex',
        flexDirection: 'row'
    },
    oneDiv: {
        margin: '1px',
        width: '14px',
        height: '14px',
        borderRadius: '0.9px',
        backgroundColor: '#1274e4',
        '&.corner': {
            backgroundColor: '#a6f9fe',
        }
    }, 
           
}));

function LegalUpProgress(props, context) {
    const classes = useStyles();    
    return (
        <div>
            <div className={classes.row}>
                <div className={classNames(classes.oneDiv, 'corner')} />
                <div className={classes.oneDiv} />
            </div>
            <div className={classes.row}>
                <div className={classes.oneDiv} />
                <div className={classes.oneDiv} />
            </div>
        </div>
    );
}
export default LegalUpProgress;