import React, { useState } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import classnames from 'classnames';
import {Redirect} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, IconButton, Chip } from '@material-ui/core';
import AlertDialog from './AlertDialog';
import * as Actions from '../../redux/actions';
import { SERVER_URL } from '../../api';

const useStyles = makeStyles(theme => ({
    card: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '80px',        
        width: '100%',
        margin: '3px px;',
        borderBottom: '1px solid rgba(148, 149, 150, 0.5);'
        // paddingLeft: '24px',        
    },
    titleRow: {
        display: 'flex',
        flexDirection: 'row',
        padding: '8px 24px;',
        alignItems: 'flex-start'
    },
    title: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        color: 'rgba(22, 34, 80, 1)',
        paddingLeft: '12px',
        alignSelf: 'center'
    },
    index: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#1274e4 !important',
        marginTop: '4px',
    },
    iconsDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        flexGrow: 1,
        alignSelf: 'center'
    },
    icon: {
        width: '13px',
        height: '13px',
        alignSelf: 'center'
    },
    circle: {
        display: 'table-cell',
        height: '27px',
        minWidth: '27px',
        border: '1px solid rgba(210, 210, 210, 1)',
        borderRadius: '50%',
        textAlign: 'center',
        lineHeight: '27px',
        verticalAlign: 'middle',
        alignSelf: 'center',
    },
    button: {
        height: '15px',
        width: '15px',
        margin: '0px 4px'
    },
    referralsContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    referralsRow: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: '66px',
        marginTop: '12px',
        marginBottom: '12px',
    },
    clauseName: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: '500',
        color: 'rgba(69, 69, 69, 1)',
        textAlign: 'left',
        letterSpacing: '0.02em',
        lineHeight: '38px',
        marginRight: '24px'
    },
    clauseText: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        color: 'rgba(89, 89, 89, 1)',
        textAlign: 'left',
        letterSpacing: '0.02em',
        lineHeight: '38px',
        flexGrow: 1,
        marginRight: '24px'
    },
    padded: {
        marginLeft: '54px'
    },
    arrow: {
        filter: 'invert(46%) sepia(93%) saturate(2434%) hue-rotate(186deg) brightness(101%) contrast(91%)'
    }
}));

export default function QuestionCardView(props) {
    const {question, onDeleteClick} = props;
    const [redirect, setRedirect] = useState(undefined);
    const [alertOpen, setAlertOpen] = useState(false);
    const clauses = useSelector(state => state.document.clauses);
    const dispatch = useDispatch();
    let referringClauses = [];    
    for (let i = 0; i < clauses.length; i++) {
        const clause = clauses[i];
        for (let a = 0; a < clause.alternatives.length; a++) {
            const alt = clause.alternatives[a];
            let found = [undefined, undefined, undefined];
            
            // Phrases
            for (let p = 0; p < alt.phrases; p++) {
                if (alts.phrases[p].question_id === question.id) {
                    if (!found[0]) found[0] = [];
                    found[0].push(alts.phrases[p]);
                }
            }
            
            // Variables
            if (alt.variables) {
                for (let v = 0; v < alt.variables; v++) {
                    if (alts.variables[v].question_id === question.id) {
                        found[1] = alts.variables[v];
                        break;
                    }
                }
            }

            // Condition that points to the question
            if (alt.condition) {
                for (let c = 0; c < alt.conditions.length; c++) {
                    const condition = alt.conditions[c];
                    if (condition.question_id === question.id) {
                        found[2] = true;
                        break;
                    }
                }
            }
            if (found[0] || found[1] || found[2]) {
                referringClauses.push(Object.assign({}, clause, {index: i, variable: found[1], phrases: found[0]}));
            }
        }
    }
    const handleEditClick = async () => {
        await dispatch(Actions.initQuestion(question.id));        
        await setRedirect(`/question/${question.id}`);
    };
    
    const handleDeleteClick = () => {
        setAlertOpen(true);
    };
    const onAlertOkClick = () => {
        onDeleteClick(question.id);
        setAlertOpen(false);
    };
    const classes = useStyles();
    const TEXT = question.is_multi ? question.children.map(c => {return c.title;}).join().replace(',', ', ') : question.text;
    
    return redirect ? <Redirect to={`${redirect}`} /> : 
        <div className={classes.card}>
            <div className={classes.titleRow}>
                <div className={classes.circle}>
                    <Typography className={classes.index}>{question.index}</Typography>
                </div>
                <Typography className={classes.title}>{question.title}</Typography>
                <div className={classes.iconsDiv}>
                    <IconButton className={classes.button} aria-label="edit" onClick={handleEditClick}>
                        <img src={`${SERVER_URL}/assets/icons_edit.svg`} className={classes.icon} />
                    </IconButton>
                    <IconButton className={classes.button} aria-label="delete" onClick={handleDeleteClick}>
                        <img src={`${SERVER_URL}/assets/icons_delete.svg`} className={classes.icon} />
                    </IconButton>                    
                </div>
            </div>
            <Typography className={classnames(classes.title, classes.padded)}>{TEXT}</Typography>
            {referringClauses.length > 0 && <div className={classes.referralsContainer}>
                {referringClauses.map((c, idx) => <div>
                    {c.variable && <div className={classes.referralsRow}><Chip color="primary" label="Variable" /></div>}
                    {c.phrases && c.phrases.map(phrase => <div className={classes.referralsRow}><Chip color="primary" label={phrase.name} /></div>)}
                    <div className={classes.referralsRow}>
                        <Typography className={classes.clauseName}>{`P00${c.index + 1}`}</Typography>
                        <Typography className={classes.clauseText}>{c.alternatives[0].text}</Typography>
                    </div></div>)}
            </div>}
            <AlertDialog 
                title='Are you sure?' 
                text={`You are about to delete question #${question.index}`} 
                open={alertOpen} 
                onOk={onAlertOkClick} 
                onCancel={()=>{setAlertOpen(false);}} />
        </div>;
}
