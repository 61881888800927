import React, { useState, useContext } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import AlertDialog from  './AlertDialog';
import ChooseRepeatingQuestionDialog from './ChooseRepeatingQuestionDialog';
import { makeStyles } from '@material-ui/core/styles';
import loggedInUserContext from '../../loggedInUserContext';
import {Chip, Avatar, IconButton, Typography, TextField, LinearProgress} from '@material-ui/core';
import RepeatIcon from '@material-ui/icons/Repeat';
import NewObjectHeader from './NewObjectHeader';
import ConditionLine from './ConditionLine';
import ColorButton from './ColorButton';
import api, { SERVER_URL } from '../../api';
import NotesIcon from '@material-ui/icons/NotesRounded';
import HighlightOffSharpIcon from '@material-ui/icons/HighlightOffSharp';

import * as Actions from '../../redux/actions';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginTop :'18px',
        marginLeft: '32px'
    },
    col: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 8px'
    },
    octopusIcon: {
        height: '24px',
        width: '24px', 
        margin: '0px 4px'       
    },
    fullWith: {
      width: '100%',
      padding: '0 10px',
      boxSizing: 'border-box'
    },
    splitText: {
        '& > span': {
            fontSize: '14px',
        },
    },
    clauseTitle: {
        margin: '0px 4px',     
        fontFamily: 'Assistant',
        fontWeight: 800,
        fontSize: '16px',
        color: 'rgba(46, 93, 147, 1)',
        textlaign: 'left'
    },
    dottedDivider: {
        height: '1px',
        borderBottom: '1px dashed rgba(187, 187, 187, 1);',
        margin: '18px 0px'
    },
    longerTitle: {
        fontfamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(119, 119, 119, 1)',
        textAlign: 'left',
        marginLeft: '8px',
        fontWeight: 300
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    button: {
        margin: theme.spacing(0),
        fontSize: '11px',
        lineHeight: '11px',
        color : 'rgba(18, 116, 228, 1)'
    },
    // repeatButton: {
    //     height: '18px',
    //     width: '18px'
    // },
    titleDiv: {
        display: 'flex',
        flexDirection: 'row',
        margin: '18px 8px',
        backgroundColor: 'rgba(234, 240, 248, 0.34)',
        height: '35px',
        width: '100%',
        bottomBorder: 'border: 1px solid rgba(18, 116, 228, 0.15)',
        alignItems: 'center',
        alignContent: 'center'
    },
    titleDivTitle: {
        fontFamily: 'Assistant',
        fontWeight: 400,
        fontSize: '14px',
        color: 'rgba(136, 165, 194, 1)',
        textAlign: 'left',
        margin: '0px 12px',
        alignSelf: 'center'      
    },
    alternativeContainer: {

    },
    altRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop :'18px',
        marginLeft: '12px'
    },
    boldIndex: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'rgba(77, 84, 92, 1)',
        textAlign: 'center',
        lineHeight: '24px',
        marginLeft: '12px'
    },
    slashIndex: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: 300,
        color: 'rgba(207, 207, 207, 1)',
        textAlign: 'center',
        lineHeight: '24px',
        margin: '0px 4px'
    },
    lengthIndex: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(136, 136, 136, 1)',
        textAlign: 'center',
        lineHeight: '24px'
    },
    altText: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: 700,
        color: 'rgba(87, 96, 107, 1)',
        textAlign: 'left',
        marginLeft: '36px'
    },
    textLogic: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: 300
    },
    orChip: {
        margin: '18px 0px 18px 30px'

    },
    andChip: {
        margin: '12px 0px 0px 80px'
    },
    fab: {
        padding: 0,
        boxShadow: 'none',
        backgroundColor: 'white',
        border: '1.5px solid #1274e4',
        color: '#1274e4',
        '&:hover': {
            color: '1px solid #1480F0',
            backgroundColor: 'rgba(18, 116, 228, 0.6)'
        },
        '&.empty': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            right: 0
        },
    },
    conditionLine: {
        margin: '8px 24px',
        marginLeft: '80px'
    },
    internalNotesContainer: {
        borderTop: '1px dashed #A0A0A0',
        marginTop: '12px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '90%',
        marginLeft: '32px'
    },
    internalNotes: {
        flexGrow: 1
    },
    internalNotesIcon: {
        color: '#A669C7',
        marginRight: '12px',
        width: '14px',
        height: '14px'
    },
    internalNotesInput: {
        fontStyle: 'Assistant',
        fontSize: '12px',
        color: '#A0A0A0',
        '&::placeholder': {
            fontStyle: 'Assistant',
            fontSize: '11px'
        },
    }
}));

const ClauseConditionView = () => {
    const classes = useStyles();
    const [dirty, setDirty] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState({});
    const [redirectBack, setRedirectBack] = useState(false);
    const [showQuestionDialog, setShowQuestionDialog] = useState(false);
    const context = useContext(loggedInUserContext);
    const backURL = `${context.lastURL.pathname}${context.lastURL.search}`;

    const dispatch = useDispatch();
    const allQuestions = useSelector(state => state.questions.questions);
    const loadingPhase = useSelector(state => state.questions.loadingPhase);
    const selectedClause = useSelector(state => state.document.selectedClause);
    const docId = useSelector(state => state.document.documentId);
    const repeatQ = selectedClause && selectedClause.repeat_question_id ? allQuestions.find(q => q.id === selectedClause.repeat_question_id) : undefined;
    const repeatedQuestionText = repeatQ ? repeatQ.text : 'not repeating';
    const [isLoading, setLoading] = useState(false);
    console.log({selectedClause, allQuestions, repeatQ, repeatedQuestionText});
    // const addClick = async (altIndex) => {
    //     let alts = selectedClause.alternatives.slice(0); //clone
    //     let updatedAlt = alts[altIndex];
    //     let conditions = updatedAlt.conditions.slice(0) || [];
    //     try {
    //         conditions.push({});
    //         updatedAlt = Object.assign({}, updatedAlt, { conditions });
    //         alts[altIndex] = updatedAlt;
    //         const clause = Object.assign({}, selectedClause, {alternatives: alts});
    //         await dispatch({ type: Actions.UPDATE_CLAUSE, success: true, clause });
    //     } catch(e) {
    //         console.error(e);
    //     }
    // };


    const addCondition = async (altIndex, operator, childIndex) => {
        let alts = selectedClause.alternatives.slice(0); //clone
        let updatedAlt = alts[altIndex];
        let conditions = updatedAlt.conditions.slice(0) || [];
        try {
            if (childIndex)
                conditions.splice(childIndex, 0, {operator});
            else 
                conditions.push({operator});
            alts[altIndex] = Object.assign({}, updatedAlt, { conditions });
            const newClause = Object.assign({}, selectedClause, { clause_id: selectedClause.id, alternatives: alts }); 
            await dispatch({ type: Actions.UPDATE_CLAUSE, success: true, clause: newClause }); 
        } catch(e) {
            console.error(e);
        }
    };

    const handleDelete = async (altIndex, condIndex) => {
        console.log(altIndex, condIndex);
        await setDeleteCandidate({altIndex, condIndex });
        await setShowAlert(true);
    };
    
    const onAlertOkClick = async () => {
        try {        
            let alts = selectedClause.alternatives.slice(0); //clone            
            let updatedAlt = alts[deleteCandidate.altIndex];
            let conditions = updatedAlt.conditions.slice(0);
            conditions.splice(deleteCandidate.condIndex, 1);
            // console.log('will update with', deleteCandidate, conditions, origCondish, updatedAlt);
            updatedAlt = Object.assign({}, updatedAlt, {conditions});        
            alts[deleteCandidate.altIndex] = updatedAlt;
            const clause = Object.assign({}, selectedClause, {alternatives: alts});
            await dispatch({ type: Actions.UPDATE_CLAUSE, success: true, clause });
            await setDeleteCandidate({});
            await setShowAlert(false);
            await setDirty(true);
        } catch(e) {
            console.error(e);
        }
    };
        
    const handleInternalNotesChanges = async (newVal, altIndex) => {
        let alts = [...selectedClause.alternatives]; 
        let updatedAlt = alts[altIndex];        
        updatedAlt = Object.assign({}, updatedAlt, {"internal_notes": newVal});
        alts[altIndex] = updatedAlt;
        const clause = Object.assign({}, selectedClause, {alternatives: alts});
        await dispatch({ type: Actions.UPDATE_CLAUSE, success: true, clause });
        await setDirty(true);
    };

    const handleQuestionChanged = async (e, altIndex, condIndex) => {
        let alts = [...selectedClause.alternatives];
        let updatedAlt = alts[altIndex];
        let conditions = updatedAlt.conditions.slice(0);
        let updatedCondition = Object.assign({}, conditions[condIndex], {[e.target.name]: e.target.value});
        conditions[condIndex] = updatedCondition;
        updatedAlt = Object.assign({}, updatedAlt, {conditions});        
        alts[altIndex] = updatedAlt;
        const clause = Object.assign({}, selectedClause, {alternatives: alts});
        await dispatch({ type: Actions.UPDATE_CLAUSE, success: true, clause });
        await setDirty(true);
    };

    const onSaveClick = async () => {
        console.log('saving clause conditions!');
        await dispatch(Actions.updateClause(selectedClause, docId));
        setDirty(false);
        setRedirectBack(true);
    };

    const repeatIconClick = async () => {
        setShowQuestionDialog(true);
    };
    const handleChooseQuestionClose = async () => {
        setShowQuestionDialog(false);
    };

    const handleIsSplitPointOnChange = async (event) => {
       setLoading(true);

        try {
            const newClausePoint = {...selectedClause};
            newClausePoint.is_split_point = event.target.checked;
            await dispatch(Actions.updateClause(newClausePoint, docId));
            setLoading(false);
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
    }
    
    const handleSelectedQuestion = async (selectedQuestion, parentQuestion) => {
        setDirty(true);
        const clauseToUpdate = Object.assign({}, selectedClause, {repeat_question_id: selectedQuestion !== undefined ? selectedQuestion.id : ''});
        console.log({clauseToUpdate, selectedQuestion});
        await dispatch(Actions.updateClause(clauseToUpdate, docId));
        setShowQuestionDialog(false);
    };
    
    if (!selectedClause) {
        return <div>loading...</div>
    }
    
    const calculatedTexts = [];
    const altsWithConditions = [];
    for (let a = 0; a < selectedClause.alternatives.length; a++) {
        const alt = selectedClause.alternatives[a];
        const conditions = alt.conditions;
        const conditionItems = [];
        for (let i = 0; i < conditions.length; i++) {
            const isAnd = conditions[i].operator !== undefined && conditions[i].operator.toLowerCase() === 'and';
            const isPartOfAndClause = i !== 0 && isAnd || // either this is and AND line, or next line is and AND line
                conditions[i+1] && conditions[i+1].operator && conditions[i+1].operator.toLowerCase() === 'and';
            const nextOneIsOr = i+1 === conditions.length || conditions[i+1] && (conditions[i+1].operator === undefined || conditions[i+1].operator.toLowerCase() === 'or');
            // console.log({conditions, i, nextOneIsOr});
            conditionItems.push(<ConditionLine 
                //hiddenQuestionIds={currentQuestion.is_multi ? [currentQuestion.id, ...currentQuestion.children_ids] :[currentQuestion.id]} //TODO: hide everything after me
                hiddenQuestionIds={[]}
                key={`${a}${i}`} 
                index={i} 
                onDelete={() => {handleDelete(a, i);}} 
                onQuestionChanged={(e, i) => {
                    handleQuestionChanged(e, a, i);
                }} 
                condition={conditions[i]} 
                isPartOfAndClause={isPartOfAndClause}
                />);
            if (nextOneIsOr) {
                conditionItems.push(<div style={{'marginLeft': '60px'}} key={`div${i}`}>
                    <ColorButton className={classes.addButton} onClick={() => {addCondition(a, 'and', i + 1);}}>+ Add AND Condition</ColorButton>
                </div>);
            }
            if (i + 1 < conditions.length) {
                conditionItems.push(<div >                
                    <Chip label={nextOneIsOr ? 'OR' : 'AND'} color={!nextOneIsOr? "action" : ""} 
                        avatar={<Avatar>{nextOneIsOr ? '+' : '*'}</Avatar>}
                        size="small"
                        className={!nextOneIsOr ? classes.andChip : classes.orChip}
                    />
                </div>);
            }
        }
        altsWithConditions.push(conditionItems);
        let calculatedTextLogic = `This alternative will be shown if and only if`;
        for (let ii = 0; ii < conditions.length; ii++) {
            const c = conditions[ii];
            const theQuestion = allQuestions.find(q => q.id === c.question_id);
            if (theQuestion) {
                calculatedTextLogic = `${calculatedTextLogic} ${ii !== 0 && c.operator && c.operator.toUpperCase() || ''} ${c.question_id ? theQuestion.text : ''} ${c.type || ''} ${c.value || ''}`;
            } else {
                calculatedTextLogic = 'missing question from condition';
            }
        }
        calculatedTexts.push(calculatedTextLogic);
    };
    return redirectBack ? <Redirect to={backURL} /> : (
        <div className={classes.root}>
            <NewObjectHeader 
                title='Clause Condition' 
                boxShadow={0} 
                onSaveClick={onSaveClick} 
                isSaving={loadingPhase !== ''} 
                dirty={dirty} />
            <AlertDialog 
                title='Are you sure?' 
                text={`You are about to delete a condition, are you sure you want to do it?`} 
                open={showAlert} 
                onOk={onAlertOkClick} 
                onCancel={async ()=>{
                    await setShowAlert(false);
                    await setDeleteCandidate({});
                }} />
            <ChooseRepeatingQuestionDialog 
                filter={(q) => {return q.is_multi && q.repeat_type && q.repeat_type !== 'do_no_repeat' && q.repeat_type !== '';}}
                open ={showQuestionDialog}
                onClose={handleChooseQuestionClose}
                onQuestionSelected={handleSelectedQuestion}
            /> 
            <div className={classes.row}>                            
                <img src={`${SERVER_URL}/assets/icons_octopus.svg`} className={classes.octopusIcon} /> 
                <div className={classes.col}>
                    <Typography className={classes.clauseTitle}>{selectedClause.name}</Typography>
                    {repeatQ && <Typography className={classes.longerTitle}>{repeatedQuestionText}
                        <IconButton aria-label="delete" style={{height: '24px', width: '24px'}}>
                            <HighlightOffSharpIcon fontSize="small" color="error" onClick={() => {handleSelectedQuestion(undefined);}}/>
                        </IconButton>
                    </Typography>}
                </div>                
                <div className={classes.buttonsContainer}>
                    <IconButton size="small" className={classes.repeatButton} onClick={repeatIconClick}><RepeatIcon fontSize='small' /></IconButton>
                    <IconButton className={classes.button}>&lt;</IconButton>
                    <IconButton className={classes.button}>&gt;</IconButton>
                </div>
            </div>
            <div className={classes.titleDiv}>
                <Typography className={classes.titleDivTitle}>Clause</Typography>
                <Typography className={classes.titleDivTitle}>Condition</Typography>
            </div>
            {selectedClause.alternatives.map((alt, idx) => {return <div className={classes.alternativeContainer}
                style={{backgroundColor: idx % 2  === 0 ? '#fff' : 'rgba(232, 232, 232, .5)'}}
            >
                <div className={classes.altRow}>
                    <Typography className={classes.boldIndex}>{idx + 1}</Typography>
                    <Typography className={classes.slashIndex}>{'/'}</Typography>
                    <Typography className={classes.lengthIndex}>{selectedClause.alternatives.length}</Typography>
                    <Typography className={classes.altText}>{alt.name}</Typography>
                </div>
                {altsWithConditions && altsWithConditions[idx]}
                <div className={classes.dottedDivider} />

                <div className={classes.fullWith}>
                    {isLoading && <LinearProgress color="primary" style={{width: '100%'}} />}
                    <FormControlLabel className={classes.splitText}
                        control={<Checkbox
                        disabled={!!isLoading}
                        checked={selectedClause.is_split_point}
                        onChange={handleIsSplitPointOnChange}
                        name="is_split_point" />}
                        label="Split document"
                    />
                </div>

                <ColorButton onClick={() => {addCondition(idx, alt.conditions.length === 0 ? undefined : "or");}}>{`+ Add ${alt.conditions.length > 0 ? ' OR': 'a '} Condition`}</ColorButton>
                {altsWithConditions && altsWithConditions[idx] && <div className={classes.dottedDivider} />}
                {altsWithConditions && altsWithConditions[idx] && <Typography style={{margin:'0px 22px'}} className={classes.textLogic}>{calculatedTexts[idx]}</Typography>}
                <div className={classes.internalNotesContainer}>
                    <NotesIcon size="small" color="secondary" classes={{root: classes.internalNotesIcon}} />
                    <TextField 
                        name="internal_notes" 
                        className={classes.internalNotes} 
                        placeholder="Notes for internal use"
                        multiline
                        InputProps={{
                            classes: { input: classes.internalNotesInput}
                        }}
                        value={alt.internal_notes}
                        onChange={e => {handleInternalNotesChanges(e.target.value, idx);}}
                    />
                </div>
            </div>})}
        </div>
    );
}
export default withRouter(ClauseConditionView);