import React from 'react';
import LoggedInUserContext from  '../../loggedInUserContext';
import VariableSetup from './VariableSetup'
import SignatureSetup from './SignatureSetup'
import { withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from '../../redux/store';

window.store = store;

class App extends React.Component {
    constructor(props) {
        super(props);
        
        if (OfficeExtension && OfficeExtension.config) {
            OfficeExtension.config.extendedErrorLogging = true;
        } else {
            console.error('cannot find office extension config');
        }
        
        this.state = {
            user_id: localStorage.getItem('current_user') ? JSON.parse(localStorage.getItem('current_user')).id : undefined,
            update: async (user_id) => {
                await this.setState(state => Object.assign({}, state, { user_id }));
            },
            doc_id: undefined,
            updateDocId: async docId=>{ await this.setState({doc_id: docId}); },
            lastURL: undefined,
            updateLastURL: async lastURL=>{ await this.setState({lastURL}); },
            questions: undefined,
            updateQuestions: async(questions) => {await this.setState({questions}); }
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.location && this.props.location !== prevProps.location) {
            this.state.updateLastURL(prevProps.location);
        }
    }
    async componentDidMount() {
        try {
            let currentUser = JSON.parse(localStorage.getItem('current_user'));
            if (currentUser) {
                await this.state.update(currentUser.id);
                document.cookie=`access_token=${localStorage.getItem('access_token')}`;
            }
        } catch (err) {
            console.error(err);
        }
    }

    render() {
        return <Provider store={store}>
            <LoggedInUserContext.Provider value={this.state}>
                {this.props.children}
                <VariableSetup/>
                <SignatureSetup/>
            </LoggedInUserContext.Provider>
        </Provider>
    }
}

export default withRouter(App);
