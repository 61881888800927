import React from 'react';


const LoggedInUserContext = React.createContext({
    user: {},
    update: async(userId) => {},
    doc_id: undefined,
    updateDocId: async(docId) => {},
    lastURL: undefined,
    updateLastURL: async lastURL=> {},
    questions: undefined,
    updateQuestions: async questions => {}
});

export default LoggedInUserContext;
