import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import * as ReactDOM from 'react-dom';
import LegalupRouter from './Router';
import {trackDocumentChanges} from '../redux/store';

ReactDOM.render(<div>Progress...</div>, document.getElementById('container'));
/* Render application after Office initializes */
Office.initialize = () => {
    console.log('office is initializing');
    ReactDOM.render(<LegalupRouter />, document.getElementById('container'));
    trackDocumentChanges();
};
/* Initial render showing a progress bar */
if (module.hot) {
    module.hot.accept('./Router', () => {
        ReactDOM.render(<LegalupRouter />, document.getElementById('container'));
    });
}
