import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import {useSelector, useDispatch } from 'react-redux';
import {withRouter} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Tabs, Tab, Button, Typography, FormControl, Accordion, AccordionSummary, AccordionDetails, FormControlLabel } from '@material-ui/core';
import StyledSelect from './StyledSelect';
import StyledTextField from './StyledTextField';
import { SERVER_URL } from '../../api';
import StyledMenuItem from './StyledMenuItem';
import ColorButton from './ColorButton';
import {ExpandMore, ExpandLess}  from '@material-ui/icons';
import PropTypes from 'prop-types';
import AlertDialog from  './AlertDialog';
import ChooseQuestionDialog from './ChooseQuestionDialog';
import SingleQuestionView from './SingleQuestionView';
import * as Actions from '../../redux/actions';
import {ObjectId} from '../../utils';

const useStyles = makeStyles(theme => ({
    select: {
        display: 'flex',
    },
    title: {
        paddingTop: '4px',
        paddingBottom: '4px',
        fontFamily: 'Assistant',
        fontWeight: '600',
        fontSize: '16px',
        color: 'rgba(53, 55, 61, 1)',
        textAlign: 'left',
        alignSelf: 'center',
        flexGrow: '0'
    },
    formControl: {
        width: '100%',        
    },
    icon: {
        color: 'rgba(18, 116, 228, 1)',
        width: '24px',
        height: '24px'
    },
    listIcon: {
        width: '18px',
        height: '18px',
    },
    button: {
        minWidth: '28px',
        padding: '0px',
        margin: '0px',
        alignSelf: 'center'
    },
    spacedRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    textField: {
        width: '100%',
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(189, 189, 189, 1)',
        textAlign: 'left',
        lineHeight: '21px'
    },
    sectionContainer: {
        padding: '14px 22px',
        '&:hover': {
            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.11)',
            cursor: 'pointer'
        }
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontFamily: 'Assistant',
        fontSize: '12px'
    },
    addFieldButton: {
        marginTop: '12px'
    },
    child: {
        // height: '38px',
        width: '100%',
        borderRadius: '5px',
        border: '1px solid rgba(235, 235, 235, 1);',
        boxShadow: 'none',
        margin: '8px 0px',
        '&::before': {
            display: 'none'
        }
    },
    childExpanded: {
        backgroundColor: 'white'
    },
    expansionSummaryRoot: {
        minHeight: '38px',
        '& >.MuiExpansionPanelSummary-content': {
            margin: '2px 0px'
        }
    },
    expansionSummaryRootExpanded: {
        height: '100%'
    },
    exansionDetails: {
        padding: '0px'
    },
    expansionTitle: {
        fontFamily: 'Assistant',
        fontWeight: '400',
        fontSize: '14px',
        color: 'rgba(46, 93, 147, 1)',
        textAlign: 'left',
        flexGrow: 1
    },
    selectedInnerQuestion: {
        backgroundColor: 'rgba(249, 249, 249, 1)'
    },
    erazeIcon: {
        width: '16px',
        height: '16px',
        margin: '0px 8px'
    },
    iconButton: {
        padding: 2,
        minWidth: '18px'
    },
    repeatForHelper: {
        width: '146px'
    },
    partyFormLabel: {
        fontFamily: 'Assistant',
        fontSize: '10px'
    },
    partyFormLabelRoot: {
        alignSelf: 'center'
    },
    dottedDivider: {
        height: '1px',
        borderBottom: '1px dashed rgba(187, 187, 187, 1);',
        margin: '18px 0px'
    },
    label: {
        opacity: '0.77',
        fontFamily: 'Assistant',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.22px',
        textAlign: 'center',
        color: '#97a1b0',        
    },
    indicator: {
        backgroundColor: "#1274e4"
    },
    tab: {
        backgroundColor: 'white',
        boxShadow: 'none',
        '&.Mui-selected > span > span': {
            fontFamily: 'Assistant',
            fontSize: '11px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.12px',
            textAlign: 'center',
            color: '#162250'
        }        
    },
    remark: {
        fontFamily: 'Assistant',
        fontWeight: '200',
        fontStyle: 'italic',
        fontSize: '10px'
    }
}));

function SingleQuestionContainer(props) {
    const classes = useStyles();
    const {children, selected, title, onQuestionSelected, onDeleteClicked, key, errors} = props;
    const [expanded, setExpanded] = useState(false);

    useEffect( () => {  // recalc expanded of a single question each time error changes
        const init = async () => {
            const thereAreErrors = errors && Object.values(errors).filter(e => e !== undefined).length > 0;
            if (expanded === false && thereAreErrors) {
                await setExpanded(true);
            }
        }
        init();
    }, [errors, expanded] );


    const handleSelection = e => {
        e.stopPropagation();
        onQuestionSelected();
    };
    
    const handleDelete = e => {
        e.stopPropagation();
        onDeleteClicked();
    };
    
    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }

    return (
        <Accordion
            classes={{root: classNames(classes.child, selected ? classes.selectedInnerQuestion : ''), expanded: classes.childExpanded}}
            onChange={handleChange(key)}
            expanded={expanded === key || expanded === true}
        >
            <AccordionSummary aria-controls="panel1a-content" id="panel1a-header"
                classes={{root: classes.expansionSummaryRoot}}
            >
                {expanded===key ? <ExpandLess />: <ExpandMore />}
                <Typography onClick={handleSelection} className={classes.expansionTitle}>{title}</Typography>
                {selected && <Button className={classes.iconButton} onClick={handleDelete}>
                <img src={`${SERVER_URL}/assets/icons_delete.svg`} className={classes.erazeIcon} /> 
            </Button>}
            </AccordionSummary>
            <AccordionDetails classes={{root: classes.expansionDetails}}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
}

SingleQuestionContainer.propTypes = {
    children: PropTypes.node
};

const repeatKinds = ['Do not repeat', `Let user 'Add Another'`, 'Repeat for every answer of', 'Repeat for every value of'];
const repeatKindsValues = ['do_no_repeat', `let_user_add`, 'repeat_for_every_answer', 'repeat_for_every_value'];

const MultiQuestionsForm = ({ setDirty }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const currentQuestion = useSelector(state => state.questions.currentQuestion);
    const selectedDocId = useSelector(state => state.document.documentId);
    const questions = useSelector(state => state.questions.questions);

    const partyQ = currentQuestion.children && currentQuestion.children.find(c => c.read_only === true);
    const [showAlert, setShowAlert] = useState(false);
    const [selectedInnerQuestion, setSelectedInnerQuestion] = useState(undefined);
    const [partyTab, setPartyTab] = useState(0);
    const [showQuestionDialog, setShowQuestionDialog] = useState(false);
    const [connectedTextField, setConnectedTextField] = useState(undefined);    
    
    const allErrors = useSelector(state => state.questions.errors);
    const errors = allErrors[currentQuestion.id] || {};

    const showTitlesList = (fieldName) => {
        setConnectedTextField(fieldName);
        setShowQuestionDialog(true);
    };
    const handleChooseQuestionClose = async () => {
        setShowQuestionDialog(false);
        setConnectedTextField(undefined);
    };
    
    const handleSelectedQuestion = async (selectedQuestion, parentQuestion) => {
        setDirty(`${selectedQuestion.text} was used`);
        currentQuestion[connectedTextField] = `${currentQuestion[connectedTextField]}{${selectedQuestion.id}}`;
        await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, currentQuestion)));
        await setConnectedTextField(undefined);
        setShowQuestionDialog(false);
    };

    const onChange = async (e) => {
        e.persist();
        setDirty(`${e.target.name} was changed`);
        const checked = e.target.name === 'party_form';
        const update = {[e.target.name]: checked ? e.target.checked : e.target.value};
        await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, update)));
    };

    const handleAddFieldClick = async () => {
        let children = currentQuestion && currentQuestion.children ? [...currentQuestion.children] : [];
        const newChild = {
            id: ObjectId(),
            title: '', 
            text: '',
            children: [],
            children_ids: [],
            is_multi: false,
            type: 'text_box',
            num_lines: 1,
            parent_id: currentQuestion.id,
            document_id: selectedDocId,
            conditions: partyQ ? [{question_id: partyQ.id, type: 'is', value: partyTab.toString() }]: [] //set the right conditions
        };
        children.push(newChild);
        const next = Object.assign({}, currentQuestion, { children });
        await dispatch(Actions.setCurrentQuestion(next));
    };

    const handleDeleteClick = async () => {
        await setShowAlert(true);
    }
    const onAlertOkClick = async () => {
        let children = [...currentQuestion.children];
        const idxToDelete = children.findIndex(c=>c.id === selectedInnerQuestion);
        children.splice(idxToDelete, 1);
        await setShowAlert(false);
        await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children })));
    }

    const handlePartyFormClick = async e => {
        if (e.target.checked) {
            const hiddenQuestion = {
                id: ObjectId(),
                document_id: selectedDocId,
                read_only: true,
                is_multi: false,
                title: 'Party main question',
                type:'radio_buttons',
                possible_answers: ['0', '1'],
                text: 'Are you an individual or a corporation'
            };
            let children = currentQuestion.children || [];
            children.push(hiddenQuestion);
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children, party_form: true })));
        } else {
            let children = currentQuestion.children;
            const partyIndex = children.findIndex(q => q.read_only === true);
            if (partyIndex > -1) {
                children.splice(partyIndex, 1);
                await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children, party_form: false })));
            }
        }   
        setDirty(`party form change happened`);
    }

    return (
        <div>
            <AlertDialog 
                title='Are you sure?' 
                text={`You are about to delete question`} 
                open={showAlert} 
                onOk={onAlertOkClick} 
                onCancel={()=>{setShowAlert(false);}} />
            <ChooseQuestionDialog 
                open ={showQuestionDialog}
                onClose={handleChooseQuestionClose}
                onQuestionSelected={handleSelectedQuestion}            
            />
            <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <Typography className={classes.title}>Title for wizard mapping</Typography>
                </div>
                <StyledTextField multiline name='question_map_title' className={classes.textField}
                    value={currentQuestion.question_map_title} onChange={onChange}>
                </StyledTextField>
            </div>
            {currentQuestion && <div className={classes.sectionContainer}>
                <FormControlLabel label="Party Form" labelPlacement="end" classes={{root: classes.partyFormLabelRoot, label: classes.partyFormLabel}}
                    control={<Checkbox
                    checked={currentQuestion.party_form === true} 
                    disabled={currentQuestion && currentQuestion.children && currentQuestion.children.filter(q=>!q.read_only).length > 0}
                    onChange={handlePartyFormClick}
                    name='party_form' 
                    value={currentQuestion.party_form} />}/>
                {currentQuestion.children && currentQuestion.children.filter(q => !q.read_only).length > 0 && <Typography className={classes.remark}>* This field cannot be changed if there are children</Typography>}
            </div>}
            <div className={classes.sectionContainer}>
                <FormControl className={classes.formControl}>
                    <StyledSelect
                        name='repeat_type'
                        className={classes.select}
                        value={currentQuestion.repeat_type || repeatKindsValues[0]}
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                            },
                        }}
                        onChange={onChange}>
                        {repeatKinds.map((repeatKind, idx) => (
                            <StyledMenuItem dense value={repeatKindsValues[idx]} key={`${idx}`}>{repeatKind}</StyledMenuItem>
                        ))}
                    </StyledSelect>
                </FormControl> 
            </div>
            {currentQuestion.repeat_type && currentQuestion.repeat_type.indexOf('repeat_for') >= 0 && <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <StyledSelect name='repeat_for_helper' 
                        value={currentQuestion.repeat_for_helper || ''} 
                        inputProps={{
                            classes: {
                                icon: classes.icon,
                                root: classes.repeatForHelper
                            },
                        }}
                        onChange={onChange}>
                        {questions.filter(o => o.parent_id !== null).map((q, idx)=><StyledMenuItem dense value={q.id} key={`${idx}`}>{`Q0${idx+1}-${q.text}`}</StyledMenuItem>)}
                    </StyledSelect>                    
                </div>
            </div>}
            <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <Typography className={classes.title}>Page Title</Typography>
                    <Button className={classes.button} aria-label="list" onClick={() => showTitlesList('title')}>
                        <img src={`${SERVER_URL}/assets/icons_x.svg`} className={classes.listIcon} />
                    </Button>
                </div>
                <StyledTextField multiline name='title' className={classes.textField} 
                    value={currentQuestion.title} onChange={onChange}></StyledTextField>
                {errors.title && <Typography className={classes.errorText}>{errors.title}</Typography>}
            </div>
            <div className={classes.sectionContainer}>
                <Typography className={classes.title}>Form Intro Text</Typography>
                <StyledTextField multiline name='text' className={classes.textField} 
                    value={currentQuestion.text} onChange={onChange} placehholder='Enter explanation text'></StyledTextField>
                {errors.text && <Typography className={classes.errorText}>{errors.text}</Typography>}
            </div>
            <div className={classes.dottedDivider} />
                {currentQuestion.party_form && <Tabs
                    className={classes.tab} 
                    classes={{ indicator: classes.indicator }} 
                    value={partyTab}
                    onChange={(e, newVal) => setPartyTab(newVal)}
                    indicatorColor="primary"
                    textColor="primary"
                    >
                    <Tab className={classes.tab} label={<span className={classes.label}>INDIVIDUAL</span>} />
                    <Tab className={classes.tab} label={<span className={classes.label}>CORPORATION</span>} />
                </Tabs>
                }
            {currentQuestion.party_form && currentQuestion.children && currentQuestion.children.length > 0 && currentQuestion.children.filter(c =>
             c && !c.read_only && c.conditions && c.conditions.length > 0 && c.conditions[0].question_id === partyQ.id && 
                c.conditions[0].value === partyTab.toString() && c.conditions[0].type === 'is').map((childQ, idx) => (
                <SingleQuestionContainer title={`${currentQuestion.title || 'untitled'} (${childQ.text || 'untitled'})`} key={`container${idx}`}
                    selected={childQ.id===selectedInnerQuestion}
                    onQuestionSelected={e => setSelectedInnerQuestion(childQ.id)} 
                    onDeleteClicked={handleDeleteClick}
                    errors={allErrors[childQ.id]}
                >
                    <SingleQuestionView setDirty={setDirty} questionIdx={idx} question={childQ} />
                </SingleQuestionContainer>
            ))}
            {!currentQuestion.party_form && currentQuestion.children && currentQuestion.children.map((childQ, idx) => (
                <SingleQuestionContainer title={`${currentQuestion.title || 'untitled'} (${childQ.text || 'untitled'})`} key={`container${idx}`}
                    selected={childQ.id===selectedInnerQuestion}
                    onQuestionSelected={e => setSelectedInnerQuestion(childQ.id)} 
                    onDeleteClicked={handleDeleteClick}
                    errors={allErrors[childQ.id]}
                >
                    <SingleQuestionView setDirty={setDirty} questionIdx={idx} question={childQ} />
                </SingleQuestionContainer>
            ))}
            <ColorButton color="primary" className={classes.addFieldButton} onClick={handleAddFieldClick}>+ Add Field</ColorButton>
        </div>
    );
}
export default withRouter(MultiQuestionsForm);