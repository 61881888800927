import {Select} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';

export default withStyles({
    root: {
        width: '100%',
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#505050',
        borderBottom: '1px solid rgba(208, 208, 208, 1)',
        backgroundColor: 'transparent',
        '& > .MuiSelect-icon' : {
            width : '6px',
            height: '6px',
            color: 'magenta'
        },
        '& input': {            
            borderBottom: '1px solid rgba(208, 208, 208, 1)',            
            fontFamily: 'Assistant',
            fontSize: '12px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#505050',            
        },
        '& input::placeholder': {
            fontFamily: 'Assistant',
            fontSize: '12px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: '#565656'
        },
        '& .MuiInputBase-inputSelect:before': {
            borderBottom: '1px solid rgba(208, 208, 208, 1)',                        
        },
        '& > .MuiOutlinedInput-input': {
            padding: '6px 14px !important'
        },
        // }
        // '& label.Mui-focused': {
        //     color: '#4789d2',
        // },
        // '& .MuiInput-underline:after': {
        //     borderBottomColor: 'rgba(220, 220, 220, 1)',
        // },
        // '& .MuiOutlinedInput-root': {
        //     '& fieldset': {
        //     borderColor: 'red',
        // },
        // '&:hover fieldset': {
        //     borderColor: 'yellow',
        // },
        // '&.Mui-focused fieldset': {
        //     borderColor: '#4789d2',
        //     },
        // },
    },
})(Select);