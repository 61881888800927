import { makeStyles } from '@material-ui/core/styles';
import { Add, CloudUploadSharp, ExpandLess, ExpandMore, CallMergeSharp as MergeIcon } from '@material-ui/icons';
import {Tooltip, Chip, Badge, List, InputBase, ListItem, ListItemText, IconButton, Typography, Collapse, LinearProgress, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll'
import LegalUpProgress from './LegalUpProgress';
import SearchBox from './SearchBox';
import { SERVER_URL } from '../../api';
import * as Actions from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 2,
    },
    divider: {
        height: 28,
        margin: 4,
    },
    row: {
        width: '95%',
        display: 'flex',
        flexDirection: 'row',
        height: '44px',
        alignItems: 'center'
    },
    altsIcon: {
        width: '24px',
        padding: '2px',
        marginRight: '8px',
        flexGrow: 0
    },
    pName: {
        fontSize: '18px',
        fontFamily: 'Roboto',
        flexGrow: 0,
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: '600',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        color: '#454545',
        margin: '0px 4px',
        marginTop: '5px'
    },
    pValue: {
        fontFamily: 'Assistant',
        fontSize: '12px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: '0.2px',
        color: '#595959',
        flexGrow: 1,
        marginTop: '5px'
    },
    list: {
        width: '100%',
        topBorder: '1px solid rgba(241, 243, 246, 1);'
    },
    listItem: {
        padding: '0px',
        height: '44px',
        '&:hover' : {
            backgroundColor: 'rgba(112, 112, 255, 0.04)'
        },
        '&.Mui-selected': {
            backgroundColor: 'rgba(18, 116, 228, 0.05)',
            '&:hover': {
                backgroundColor: 'rgba(18, 116, 228, 0.1)',
            }
        }
    },
    listItemRipple: {
        color: 'rgba(18, 116, 228, 0.5)'
    },
    plusButton: {
        padding: '2px',
        color: '#1274e4',
        flexGlow: 0,
        width: '24px',
        margin: '0px 4px'
    },

    expandButton: {
        margin: '0px 12px;',
        padding: '2px',
        color: '#8f8f8f',
        flexGlow: 0,
        width: '24px'
    },
    recycleIcon: {
        margin: '0px 12px;',
        padding: '2px',
        color: '#8f8f8f',
        flexGlow: 0,
        width: '24px'
    },
    recycleImage: {
        width: '20px',
        height: '20px',
        filter: 'invert(46%) sepia(93%) saturate(2434%) hue-rotate(186deg) brightness(101%) contrast(91%)'
    },
    loadButton: {
        margin: '12px'
    },
    deleteIcon: {
        marginRight: '18px',
        padding: '0px 0px'
    },
    preparation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    preparationText: {
        marginTop: '24px',
        width: '212.5px',
        height: '80px',
        fontSize: '17px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#353535'
    },
    prepDividerDiv: {
        width: '36px',
        height: '1px',
        border: 'solid 1.5px #e1e1e1;',
        backgroundColor: '#e1e1e1'
    },
    prepFoundText: {
        width: '146px',
        height: '22.5px',
        fontFamily: 'Assistant',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '2',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#7f7f7f',
        marginTop: '24px',
    },
    dividerDiv: {
        height: '0px', 
        width: '100%',
        border: '0.5px solid rgba(241, 243, 246, 1)',
    },
    progress: {
        width: '100%',
        marginBottom: '18px'
    },
    deleteProgress: {
        width: '38px',
        height: '38px'
    },
    delButton: {
        height: '17px',
        width: '17px'
    },
    octopusIcon: {
        height: '19px',
        width: '19px',
        paddingTop: '10px',
        paddingLeft: '8px',
    },
    paragraphsCount: {
        fontFamily: 'Assistant',
        fontWeight: '700',
        letterSpacing: '0.2px',
        fontSize: '18px',
        color: 'rgba(37, 48, 91, 1)',
        textAlign: 'left',
        marginRight: '24px'
    }, 
    phraseChip: {
        marginLeft: '54px'
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    phrasesIcon: {
        fontSize: '10px',
        fontFamily: 'Assistant',
        position: 'absolute',
        fontWeight: 900,
        left: '20px',
        top: '11px',
        color: 'rgba(37, 48, 191, 1)',
        borderRadius: '8px',
        minWidth: '14px',
        textAlign: 'center',
        backgroundColor: 'rgba(90, 90, 225, .6)'
    }
}));

const DocumentExplorer = () => {
    const classes = useStyles();
    const [lastClauseId, setLastClauseId] = useState(null); 
    const [redirectTo, setRedirectTo] = useState(undefined);
    const [criteria, setCriteria] = useState(undefined);
    const [isOpen, setIsOpen] = useState({});
    const { questions } = useSelector(state => state.questions);
    const { 
        documentId,
        selectedClause,
        loadProgress,
        loadingPhase,
        clauses,
        updatingAlternative,
        updatingClause,
        singleClauseId
    } = useSelector(state => state.document);
    const dispatch = useDispatch();    
    const searchedClauses = criteria ? clauses.filter(c => //search for name of clause, text/name of alternatives within         
        c.name.toLowerCase().indexOf(criteria.toLowerCase()) >= 0 ||  
        c.alternatives.filter(alt => alt.text.toLowerCase().indexOf(criteria.toLowerCase())>=0).length > 0 || 
        c.alternatives.filter(alt => alt.name.toLowerCase().indexOf(criteria.toLowerCase())>=0).length > 0) || 
        [] : clauses;
    // console.log(criteria, searchedClauses);
    
    const handleSearchChange = async e => {
        await setCriteria(e.target.value);
        console.log(e.target.value, criteria);
    };

    const onOpenAlternatives = (documentId, clauseId) => {        
        dispatch(Actions.openClause(documentId, clauseId));
    };

    const onToggleClause = (id) => {
        setIsOpen(Object.assign({}, isOpen, { [id]: !isOpen[id] }));
        console.log(isOpen);
    };

    const onSelectClause = (id) => {
        setLastClauseId(id); // ignore the clause change (and scroller) when triggered by the listview
        dispatch(Actions.selectClause(id));
    };

    const onDeleteAlternative = (alternativeId, clauseId, documentId) => {
        dispatch(Actions.deleteAlternative(alternativeId, clauseId, documentId))
    };

    const handleVariableChipClick = () => {
        console.log('ariel check out thhe TODO here and go TODO it.');
    };

    const handleVariableChipDelete = () => {
        
    };

    const handlePhraseChipDelete = (clauseId, phraseId) => {
        dispatch(Actions.deletePhrase(clauseId, phraseId));
    };
    
    const handlePhraseChipClick = (clauseId, phraseId) => {
        setRedirectTo(`phrase/${clauseId}/${phraseId}`);
    };
    if (selectedClause && lastClauseId !== selectedClause.id) {
        scroller.scrollTo('clause_id_' + selectedClause.id, {
            duration: 0,
            delay: 0,
            smooth: false,
            offset: -200, 
        });
        setLastClauseId(selectedClause.id);
    }

    const clauseId = selectedClause ? selectedClause.id : null;
    const items = [];

    searchedClauses.forEach((c, index) => {
        const hasAlts = c.alternatives.length > 1;
        let numPhrases = 0;
        c.alternatives.forEach(a => { numPhrases += a.phrases ? a.phrases.length : 0; });
        const label = `C${c.index}`;
        const isSelected = clauseId ? clauseId === c.id : false;
        items.push(<ListItem className={classes.listItem}
            TouchRippleProps={{classes: {root: classes.listItemRipple}}}
            key={c.id} 
            id={'clause_id_' + c.id}
            button
            selected={isSelected}
            onClick={ event => { onSelectClause(c.id) } } >
            <ListItemText>
                <div className={classes.row}>
                    <div className={classes.altsIcon}>
                        {numPhrases > 0 && <p className={classes.phrasesIcon}>{`${numPhrases}`}</p>}
                        {hasAlts && <img src={`${SERVER_URL}` + '/assets/icons_octopus.svg'} className={classes.octopusIcon} />}
                    </div>
                    <Typography className={classes.pName}>{label}.</Typography>
                    <Typography                        
                        className={classes.pValue} 
                        //defaultValue={c.name}
                        // onBlur={ async e => {
                        //     dispatch(Actions.updateClauseName(clauseId, documentId, e.target.value));
                        // } }
                    >{c.name}</Typography>
                    {isSelected && <div className={classes.buttonsContainer}>
                        <Tooltip title="Add" aria-label="add">
                            <IconButton aria-label="add" className={classes.plusButton} onClick={() => {onOpenAlternatives(documentId, c.id)}}>
                                <Add fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {false && index !== 0 && <Tooltip title="Merge Up" aria-label="mergeup">
                            <IconButton aria-label="merge" className={classes.plusButton} onClick={() => {console.log('not implemented yet');}}>
                                <MergeIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>}
                    <Tooltip title="Settings" aria-label="settings">
                        <IconButton aria-label="settings" className={classes.plusButton} 
                            onClick={e => {
                                e.stopPropagation(); setRedirectTo(`clause/${lastClauseId}`);
                            }}>
                            <img src={`${SERVER_URL}/assets/configurations.svg`}/>
                        </IconButton>
                    </Tooltip>
                    <IconButton aria-label="expand" 
                        className={classes.expandButton} 
                        onClick={() => { onToggleClause(c.id) }}>
                        {isOpen[c.id] ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                    {/* <Tooltip title="Recycle Clause" aria-label="recycle">
                        <IconButton aria-label="settings" className={classes.recycleIcon}>
                            <CloudUploadSharp fontSize="small" color="primary" />
                        </IconButton>
                    </Tooltip> */}
                    </div>}
                </div>
            </ListItemText>   
        </ListItem>);

        if (isOpen[c.id]) {
            items.push(<Collapse in={isOpen[c.id]} timeout='auto' unmountOnExit key={`alts_${c.id}`} >
                <List component='div' disablePadding>
                {c.alternatives.map((a, index) => {
                    return (<div key={a.id}><ListItem key={`alt_${index}`}>
                        <ListItemText>
                            <div className={classes.row} style={{ marginLeft: '28px' }}>
                                <Typography className={classes.pName}>{label + '.' + String(index+1)}</Typography>
                                <Typography className={classes.pValue}>{a.name}</Typography>
                                {updatingAlternative === a.id && <CircularProgress color="secondary" className={classes.deleteProgress} />}
                                <IconButton disabled={!!loadingPhase} aria-label="delete" color="primary" className={classes.deleteIcon} onClick={()=>{
                                    onDeleteAlternative(a.id, clauseId, documentId);
                                }}>
                                    <img src={`${SERVER_URL}/assets/icons_delete.svg`} className={classes.delButton} />
                                </IconButton>
                            </div>
                        </ListItemText>
                    </ListItem>
                    {a.phrases && a.phrases.map((p, pIdx) => <ListItem key={pIdx} >
                        <Badge badgeContent={p.alternatives.length} color="primary">
                            <Chip className={classes.phraseChip} variant="outlined"
                                onClick={() => handlePhraseChipClick(c.id, p.id)}
                                onDelete={() => handlePhraseChipDelete(c.id, p.id)}
                                color="primary" 
                                size='small' 
                                label = {p.name || `P${pIdx+1}`} />
                        </Badge>
                    </ListItem>)}
                    {a.variables && a.variables.map((v, vIdx) => {
                        const q = questions && questions.find(q => q.id === v.question_id);
                        return <ListItem key={vIdx} >
                            <Chip className={classes.phraseChip} variant="outlined"
                                // onClick={() => handleVariableChipClick(v.id)}
                                // onDelete={handleVariableChipDelete}
                                color="secondary" 
                                size='small' 
                                label = {`V${vIdx+1}: ${q ? q.text : vIdx+1}`}/>
                        </ListItem>
                    })}
                    </div>);
                })}
                </List>
            </Collapse>);
        }
    });
    let prepareMessage = 'Preparing master document...'
    let isLoading = false;
    switch (loadingPhase) {
        case 'fetching': 
            prepareMessage = 'Contacting Legalup servers...';
            isLoading = true;
            break;
        case 'saving':
            prepareMessage = 'saving document...';
            isLoading = true;
            break;
        default:
            if (singleClauseId && updatingClause) {
                prepareMessage = 'saving document...';
                isLoading = true;
            }
            break;    
    }
    
    return redirectTo ? <Redirect to={redirectTo} /> : (
        <div className={classes.root}>
            <div className={classes.row}>
                <SearchBox placeholder='Search Clauses' onChange={handleSearchChange} />
                <Typography className={classes.paragraphsCount}>{clauses.length}</Typography>
            </div>
            {!!isLoading &&
                <div className={classes.preparation}>
                    {loadingPhase==='loading' && <LinearProgress variant="determinate" value={loadProgress} className={classes.progress} />}
                    <LinearProgress className={classes.progress} />
                    <LegalUpProgress />
                    <Typography className={classes.preparationText}>{prepareMessage}</Typography>
                    {loadingPhase==='loading' && <div className={classes.prepDividerDiv} />}
                    {loadingPhase==='loading' && <Typography className={classes.prepFoundText}>{`${clauses.length} Clauses found...`}</Typography>}
                </div>
            }
            <div className={classes.dividerDiv} />
            <List component="nav" className={classes.list} overflow="auto">
                {items}
            </List>
        </div>
    );
};

export default withRouter(DocumentExplorer);