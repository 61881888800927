import React from 'react';
import {useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Tooltip, CircularProgress} from '@material-ui/core';
import StyledSelect from './StyledSelect';
import StyledMenuItem from './StyledMenuItem';
import { SERVER_URL } from '../../api';

const useStyles = makeStyles(theme => ({   
    conditionRoot: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        margin: '0px'
    },
    conditionIndex: {
        fontFamily: 'Assistant',
        fontWeight: 700,
        fontSize: '14px',
        color: 'rgba(77, 84, 92, 1)',
        marginTop: '10px',
        lineHeight: '24px;',
        margin: '0px 8px'
    },
    selectIcon: {
        color: 'rgba(18, 116, 228, 1)',
        width: '24px',
        height: '24px'
    },
    logicContainer: {
        flexGrow: 1
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    },
    questionSelect: {
        margin: '0px 8px',
        flexGrow: 1,
    },
    valueSelect: {
        width: '60px',
        margin: '0px 8px',
        flexGrow: 1,
    },
    erazeIcon: {
        width: '16px',
        height: '16px',
        margin: '0px 8px'
    },
    iconButton: {
        padding: 2,
        minWidth: '18px',
        marginTop: '6px',
        marginLeft: '4px'
    },
}));

function ConditionLine(props) {
    const classes = useStyles();
    const {condition, index, onQuestionChanged, onDelete, hideIndex, hideDelete, hiddenQuestionIds, isPartOfAndClause} = props;
    const origQuestions = useSelector(state => state.questions.questions);    
    const questions = hiddenQuestionIds ? origQuestions.filter(q => hiddenQuestionIds.indexOf(q.id) < 0) : origQuestions; //dont show passed question ids as condition options - for instance multi cant condition its children
    const multiQuestions = questions.filter(q => q.is_multi);
    const childIdToMultiIndex = {};
    const childIdToParentIndex = {};
    const idToIndex = {};
    for (let i = 0; i < multiQuestions.length; i++) {
        const multi = multiQuestions[i];
        for (let k = 0; k < multi.children_ids.length; k++) {
            const inner = multi.children[k];
            childIdToMultiIndex[inner.id] = k; //e.g. the fourth child
        }
    }
    let idx = 0;
    let childrenIndex = 0;
    let lastMultiIndex = 0;
    for (let i = 0; i < questions.length; i++) {
        const q = questions[i];
        if (q.is_multi) {
            lastMultiIndex = idx;
            idToIndex[q.id] = idx++;
        } else {
            if (childIdToMultiIndex[q.id] === undefined) {
                idToIndex[q.id] = idx++;
            } else {
                idToIndex[q.id] = childrenIndex++;
                childIdToParentIndex[q.id] = lastMultiIndex;
            }
        }
    }
    const selectedQuestion = questions.find(a => {return a.id === condition.question_id;}) || {};
    return (
        <div className={classes.conditionRoot} style={{marginLeft: isPartOfAndClause ?'12px' : undefined}}>
            {!hideIndex && <Typography className={classes.conditionIndex}>{index + 1}</Typography>}
            <div className={classes.logicContainer}>
                <div className={classes.row}>
                    <StyledSelect
                        name='question_id'
                        className={classes.questionSelect}
                        value={condition.question_id}
                        placeholder='choose'
                        inputProps={{
                            classes: {
                                icon: classes.selectIcon,
                            },
                        }}
                        onChange={e => onQuestionChanged(e, index)}>
                        {questions.map((q, idx) => {
                            const multiContainer = !q.is_multi && q.parent_id !== undefined ? questions.find(a=>a.id === q.parent_id) : undefined;
                            const partyHiddenQuestion = multiContainer !== undefined && multiContainer.party_form && multiContainer.children[0] || undefined;
                            const partyChildlabel = multiContainer!== undefined && partyHiddenQuestion !== undefined && !q.read_only ?
                                q.conditions.filter(c => c.question_id === partyHiddenQuestion.id && c.value === "0").length === 1 ? '[Ind]' : '[Corp]' :
                                 '';
                            const isRepeat = q.repeat_type && q.repeat_type !== 'do_no_repeat';
                            const isParty = q.party_form;
                            return (
                                <StyledMenuItem 
                                    disabled={q.is_multi} 
                                    dense 
                                    value={q.id} 
                                    key={q.id}>{q.is_multi ? `---- Q${idToIndex[q.id] + 1} ${q.title} (Multi${isRepeat ? '/Repeating':''}${isParty?'/Party' : ''}) ----` :
                                    childIdToMultiIndex[q.id] !== undefined ? `Q${childIdToParentIndex[q.id] + 1}.${childIdToMultiIndex[q.id] + 1} ${partyChildlabel} ${q.text}` : 
                                        `Q${idToIndex[q.id] + 1} ${partyChildlabel}. ${q.text}`}</StyledMenuItem>
                            );
                        })}
                    </StyledSelect>
                </div>
                <div className={classes.row}>
                    <StyledSelect name="type" 
                        value={condition.type}
                        inputProps={{
                            classes: {
                                icon: classes.selectIcon,
                            },
                        }}
                        onChange={e => onQuestionChanged(e, index)}>
                        <StyledMenuItem dense value={'is'} key={'is'}>is</StyledMenuItem>
                        <StyledMenuItem dense value={'is_not'} key={'is_not'}>is not</StyledMenuItem>
                    </StyledSelect>
                    <StyledSelect name="value" 
                        value={condition.value}
                        className={classes.valueSelect}
                        placeholder='choose'
                        inputProps={{
                            classes: {
                                icon: classes.selectIcon,
                            },
                        }}
                        onChange={e => onQuestionChanged(e, index)}>
                            {selectedQuestion && selectedQuestion.possible_answers && selectedQuestion.possible_answers.map(a => (
                                <StyledMenuItem dense value={a} key={a}>{selectedQuestion.read_only ?
                                     `${a} - ${a === '0' ? '(Individual)' :'(Corporation)'}` : a}</StyledMenuItem>
                            ))}
                            {['drop_down', 'checkboxes', 'radio_buttons'].indexOf(selectedQuestion.type) < 0 && <StyledMenuItem dense value={'answered'} key={'answered'}>Answered</StyledMenuItem>}
                    </StyledSelect>
                    {!hideDelete && <Button className={classes.iconButton} onClick={e=>onDelete(index)}>
                        <img src={`${SERVER_URL}/assets/icons_delete.svg`} className={classes.erazeIcon} /> 
                    </Button>}
                </div>
            </div>
        </div>
    );
}
export default ConditionLine;
