import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import reducer from './reducer';
import * as Actions from './actions';

const logger = createLogger()

const store = createStore(
  reducer,
  applyMiddleware(thunk, logger)
);

const selectParentClause = async (control) => {
    const parent = control.parentContentControlOrNullObject;
    context.load(parent);
    await context.sync();
    if (parent && !parent.isNullObject && parent.tag === 'clause') {
        const clauseId = parent.title;
        if (clauseId && (!state.document.selectedClause || state.document.selectedClause.id !== clauseId)) {
            store.dispatch(Actions.selectClause(clauseId));
        }
    }
    return parent;
};

let saveTimer = null;
export const trackDocumentChanges = () => {
    const onSelectionChange = () => {
        const state = store.getState();
        Word.run(async context => {
            const selection = context.document.getSelection();
            context.load(selection);
            const control = selection.parentContentControlOrNullObject;
            context.load(control);
            const p = selection.paragraphs.getFirst();
            context.load(p, 'text');
            await context.sync();
            if (control) {
                console.log('found control with tag ' + control.tag);
            }
            if (!p.text.trim()) {
                console.log('empty clause change ignored.');
                return;
            } else if (control.isNullObject) {
                // const clauseId = ObjectId();
                // createClauseContainer(p, clauseId);
                // await context.sync();
                // console.log('new clause change detected, re-scheduling update in 30 seconds.');
                // scheduleDocumentUpdate(context);
            } else if (control.tag === 'variable') {
                if (!control.title) {
                    store.dispatch(Actions.setupVariable(control.id));
                } else {
                    console.log('do not support editing question.');
                }
                // variable might be in phrases in such a case go to grandparent
                const parent = selectParentClause(control);
                if (parent.tag === 'phrase') {
                    selectParentClause(parent);
                }
            } else if (control.tag === 'phrase') {
                selectParentClause(control);
            } else if (control.tag === 'clause') {
                console.log('checking if clause changed?');
                // scheduleDocumentUpdate(context);
                // console.log('existing clause change detected, re-scheduling update in 30 seconds.');
                if (!state.document.selectedClause || state.document.selectedClause.id !== control.title) {
                    console.log('yes it changed to ' + control ? control.title : 'unkown' );
                    store.dispatch(Actions.selectClause(control.title));
                }
            }
        }).catch(async (error) => {
            console.error(error);
            console.log('Error: ' + JSON.stringify(error));
            if (error instanceof OfficeExtension.Error) {
                console.log('Debug info: ' + JSON.stringify(error.debugInfo));
            }
        });
    };

    Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, onSelectionChange);          
}

export default store;
