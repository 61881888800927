import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

const RTLtheme = createMuiTheme({
    direction: 'rtl',
});

export const makeRTL = object => {
    return <ThemeProvider theme={RTLtheme}>
        <div dir={"rtl"}>
            {object}
      </div>
    </ThemeProvider>
}