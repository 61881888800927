import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ChooseQuestionDialog from './ChooseQuestionDialog';
import * as Actions from '../../redux/actions';

export default function SignatureSetup(props) {
    const containerId = useSelector(state => state.document.setupSignatureContainerId);
    if (!containerId) return '';

    const dispatch = useDispatch();

    const onQuestionSelected = (selectedQuestion, parentQuestion) => {
        dispatch(Actions.saveSignature(containerId, selectedQuestion, parentQuestion));
    };

    const onClose = () => {
        dispatch(Actions.setupSignature(null));
    };

    return <ChooseQuestionDialog 
        open ={true}
        onClose={onClose}
        onQuestionSelected={onQuestionSelected}
        filter={q => q.type === 'email'}
    />;
}
