import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import * as Actions from "../../redux/actions";

const useStyles = makeStyles(theme => ({
    icon: {
        color: 'rgba(18, 116, 228, 1)',
        width: '24px',
        height: '24px'
    },
}));

function DropdownAutocomplete({ currentQuestion, isChildQuestion, questionIndex }) {
    const dispatch = useDispatch();
    const filter = createFilterOptions();
    let contact_field = currentQuestion.contact_field || '';
    let childrenQuestion;
    if (isChildQuestion) {
        childrenQuestion = [...currentQuestion.children];
        contact_field = childrenQuestion[questionIndex].contact_field || '';
    }

    const customProps = [
        'client_type',
        'first_name',
        'last_name',
        'company_name',
        'foreign_company_name',
        'email',
        'mobile_phone',
        'work_phone',
        'home_phone',
        'fax_phone',
        'address',
        'city',
        'country',
        'state',
        'zip_code',
        'full_address',
        'title',
        'id_number',
        'responsible_partner_name',
        'leading_partner_name',
        'purpose',
        'type_of_service',
        'date_of_birth_incorporation',
        'full_name',
    ];

    const renderOption = (option) => {
        const value = option
            .replace(/ +/g, ' ')
            .toLowerCase().replace(/[^\_a-z\s]+/g, "").trim();
        const isExisting = customProps.some((o) => value  === o);
        if(!isExisting){
            return `<div class="wrapperAutocompleteOption">
                        <span class="left-new">${value}</span>
                        <span class="right-new">New Field</span>
                    </div>`;
        } else {
            return value;
        }
    }

    const checkAndSaveContactField = (value) => {
        if (isChildQuestion) {
            childrenQuestion[questionIndex] = Object.assign({}, childrenQuestion[questionIndex], { contact_field: value });
            dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children: childrenQuestion })));
        } else {
            dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { contact_field: value })));
        }
    }

    const validationContactField = (value) => {
        if (customProps.indexOf(value) !== -1) {

            checkAndSaveContactField(value);

            return value;
        } else if ( value !== null ){
            value = value
                .replace(/ +/g, ' ')
                .toLowerCase().replace(/[^\_a-z\s]+/g, "").trim();
            let validValue = value
                .replace('custom_', '')
                .replace(/ +/g, '_');
            validValue = `custom_${validValue}`;

            checkAndSaveContactField(validValue);

            return validValue;
        } else if ( value === null) {

            checkAndSaveContactField('');

            return value;
        }
        return value;
    }

    const classes = useStyles();

    return (
        <Autocomplete
            className={classes.select}
            classes={{
                clearIndicatorDirty: classes.icon,
                popupIndicator: classes.icon,
            }}
            value={contact_field}
            onChange={(event, newValue) => {
                validationContactField(newValue);
            }}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                    filtered.push(
                        inputValue,
                    );
                }

                return filtered;
            }}
            id="custom_field"
            options={customProps}
            getOptionLabel={(option) => {
                if (typeof option === 'string') {

                    return option;
                }
                if (option.inputValue) {

                    return option.inputValue.trim();
                }

                return option;
            }}
            renderOption={(option) =>
                <div dangerouslySetInnerHTML={{ __html: renderOption(option) }} />
            }
            renderInput={(params) => (
                <TextField
                    {...params }
                    label="Select from list, or used new field"
                    variant="standard"
                />
            )}
        />
    );
}
export default DropdownAutocomplete;