import React, { useRef, useState } from 'react';
import {useSelector, useDispatch } from 'react-redux';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import { Button, InputBase, RadioGroup, Radio, FormControlLabel, Typography, Tooltip, Box, FormControl } from '@material-ui/core';
import {ToggleButton, ToggleButtonGroup}  from '@material-ui/lab';

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';

import ChooseQuestionDialog from './ChooseQuestionDialog';
import StyledSelect from './StyledSelect';
import StyledTextField from './StyledTextField';
import StyledSwitch from './StyledSwitch';
import StyledMenuItem from './StyledMenuItem';
import AlertDialog from './AlertDialog';
import ColorButton from './ColorButton';
import DropdownAutocomplete from './DropdownAutocomplete';
import { SERVER_URL } from '../../api';
import * as Actions from '../../redux/actions';


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width:'100%'
    },
    label: {
        opacity: '0.77',
        fontFamily: 'Assistant',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.22px',
        textAlign: 'center',
        color: '#97a1b0',        
    },
    indicator: {
        backgroundColor: "#1274e4"
    },
    tab: {
        backgroundColor: 'white',
        boxShadow: 'none',
        '&.Mui-selected > span > span': {
            fontFamily: 'Assistant',
            fontSize: '11px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.12px',
            textAlign: 'center',
            color: '#162250'
        }        
    },
    settingsIcon: {
        height: '16px',
        width: '16px'
    },
    appBar: {
        borderBottom: '1px solid rgba(25, 25, 25, 0.06);'
    },
    select: {
        display: 'flex',
    },
    title: {
        paddingTop: '4px',
        paddingBottom: '4px',
        fontFamily: 'Assistant',
        fontWeight: '600',
        fontSize: '16px',
        color: 'rgba(53, 55, 61, 1)',
        textAlign: 'left',
        alignSelf: 'center',
        flexGrow: '0'
    },
    formControl: {
        width: '100%',        
    },
    icon: {
        color: 'rgba(18, 116, 228, 1)',
        width: '24px',
        height: '24px'
    },
    row: {
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    multipleHint: {
        height: '24px',
        width: '178px',
        fontFamily: 'Assistant',
        fontSize: '13px',
        color: 'rgba(166, 105, 199, 1)',
        textAlign: 'left',
        marginLeft: '4px'
    },
    editButton: {
        width: '14px',
        height: '14px',
        fill: 'black'
    },
    formLabel: { 
        fontFamily: 'Assistant',
        fontWeight: 400,
        letterSpacing: '0.4px',
        fontSize: '13px',
        color: 'rgba(53, 55, 61, 1)',
        textAlign: 'left',
        marginRight: '6px'
    },
    listIcon: {
        width: '18px',
        height: '18px',
    },
    button: {
        minWidth: '28px',
        padding: '0px',
        margin: '0px',
        alignSelf: 'center'
    },
    spacedRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    textField: {
        width: '100%',
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(189, 189, 189, 1)',
        textAlign: 'left',
        lineHeight: '21px'
    },
    toggleButton: {
        width: '22px',
        height: '22px'
    },
    innerIcon: {
        width: '18px',
        height: '18px'
    },
    stylingDiv: {
        marginBottom: '2px',
        alignSelf: 'center'
    },
    temp: {
        fontFamily:'Assistant', 
        fontSize: '14px', 
        fontWeight: 500, 
        marginTop: '24px'
    },
    mandatorySwitch: {
        alignSelf: 'flex-end',
        marginBottom: '4px'
    },
    sectionContainer: {
        padding: '14px 22px',
        '&:hover': {
            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.11)',
            cursor: 'pointer'
        }
    },
    radioLabel: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(77, 84, 92, 1)'
    },
    italic: {
        fontStyle: 'italic'
    },
    bold: {
        fontWeight: 600
    },
    underline: {
        textDecoration: 'underline'
    },
    textBoxDiv: {
        padding: '6px'
    },
    calculated: {
        textAlign: 'left',
        fontFamily: 'Assistant',
        fontSize: '12px'
    },
    errorText: {
        color: 'red',
        textAlign: 'left',
        fontFamily: 'Assistant',
        fontSize: '12px'
    },
    selectRoot: {
        padding: '0px'
    },
    typeContainer: {
        maxWidth: '45%'
    },
    dottedDivider: {
        height: '1px',
        borderBottom: '1px dashed rgba(187, 187, 187, 1);',
        margin: '8px'
    },
    addOtherLabel: {
        fontFamily: 'Assistant',
        fontsize: '16px',
        color: 'rgba(53, 55, 61, 1)',
        textAlign: 'left'
    },
    addOtherHelper: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        color: 'rgba(143, 143, 143, 1)',
        textAlign: 'left'
    },
    addOtherSwitch: {
        alignSelf: 'center',
        marginRight: '12px'
    },
    bottomDweller: {
        height: '240px'
    },
    choice: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '34px',
        backgroundColor: 'rgba(245, 245, 245, 1);',        
        alignItems: 'center',
        margin: '8px 0px',
        '&.Mui-selected': {
            backgroundColor: 'rgba(240, 246, 253, 1)',
            border: '1px solid rgba(182, 210, 242, 1);'
        }
    },
    choiceIndex: {
        fontFamily: 'Assistant',
        fontWeight: '500',
        fontSize: '16px',
        color: 'rgba(18, 116, 228, 1)',
        textAlign: 'center',
        margin: '0px 12px'
    },
    choiceText: {        
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(98, 98, 98, 1)',
        textAlign: 'left',
        margin: '0px 12px',
        flexGrow: 1,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
    },
    newRowValueField: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        textAlign: 'left',
        color: 'rgba(98, 98, 98, 1);',
        margin: '0px 8px',
    },
    addValueButton: {
        marginTop: '12px'
    },
    justifyNone: {
        justifyContent: 'flex-start'
    },
    iconButton: {
        margin: '0px, 8px',
        minWidth: '22px',
        padding: '0px',
        alignSelf: 'center'
    },
    erazeIcon: {
        width: '16px',
        height: '16px',
        margin: '0px 8px'
    }
}));

const questionTypes = ['Text box', 'Number', 'Email', 'Address', 'Date', 'Attachment', 'Drop Down (Multiple)', 'Checkboxes (Multiple)', 'Radio Buttons (Multiple)'];
const questionTypesValues = ['text_box', 'number', 'email', 'address', 'date', 'attachment', 'drop_down', 'checkboxes', 'radio_buttons']; 

const SingleQuestionView = ({ setDirty, questionIdx, question }) => {
    const classes = useStyles();
    const isChildQuestion = questionIdx !== undefined;

    const currentQuestion = useSelector(state => state.questions.currentQuestion);
    const localQuestion = question ? question : currentQuestion;

    const allErrors = useSelector(state => state.questions.errors);
    const errors = allErrors[localQuestion.id] || {};

    const [hintOrDefault, setHintOrDefault] = useState('hint_text'); //hint_text / default_value
    
    const [showQuestionDialog, setShowQuestionDialog] = useState(false);
    const [connectedTextField, setConnectedTextField] = useState(undefined);

    const [nextRowText, setNextRowText] = useState(undefined);
    const [selectedChoiceRow, setSelectedChoiceRow] = useState(undefined);
    const [showAlert, setShowAlert] = useState(false);

    const questionIndex = questionIdx === undefined ? undefined : 
        currentQuestion.party_form ? currentQuestion.children.findIndex(q => q.id === question.id) : questionIdx;

    const questionEndEL = useRef(null);

    const dispatch = useDispatch();
    const validate = async () => {
        let errorsUpdate = {};
        let valid = true;
                
        //numbers
        const {min_value, max_value} = localQuestion;
        if (min_value !== undefined && max_value != undefined && (min_value > max_value || max_value < min_value)) {
            errorsUpdate['minMax'] = 'please make sure min. <=  max.';
            valid = false;
        } else {
            errorsUpdate['minMax'] = undefined;
        }
        await dispatch(Actions.setErrors(Object.assign({}, errors, errorsUpdate)));
        return valid;
    }
    const onChange = async (e) => {
        // e.persist();
        setDirty(`${e.target.name} was changed`);
        const isChecked = ['mandatory', 'add_other'].indexOf(e.target.name) >= 0;
        let update = {[e.target.name]: isChecked ? e.target.checked : e.target.value};
        if (['hint_text','default_value'].indexOf(e.target.name) >= 0) {
            const key = (e.target.name === 'hint_text') ? 'default_value' : 'hint_text';
            update = Object.assign({}, update, {[key] : ''});
            console.log({update});
        }
        if (isChildQuestion) {
            let children = [...currentQuestion.children];
            children[questionIndex] = Object.assign({}, question, update);
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {children})));
        } else {
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, update)));
        }
        const qError = Object.assign(errors, { [e.target.name] : undefined});
        await dispatch(Actions.setErrors(Object.assign(allErrors, {[localQuestion.id]: qError})));

        if (!isChildQuestion && e.target.name === 'type') {
            questionEndEL && questionEndEL.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    const onBlur = async(e) => {
        await validate();
    }
    
    const showTitlesList = (fieldName) => {
        setConnectedTextField(fieldName);
        setShowQuestionDialog(true);
    };

    let toggles = [];
    if (localQuestion.title_bold) toggles.push('bold');
    if (localQuestion.title_italic) toggles.push('italic');
    if (localQuestion.title_underline) toggles.push('underlined');

    const questionTextHelper = (encodedText) => {
        if (!encodedText || encodedText.length === 0) return '';
        
        try {
            let index = 0;
            let retVal = '';
            let opener = encodedText.indexOf('{');
            let closer = encodedText.indexOf('}', opener + 1);
            
            while (opener >= 0 && closer >= 0) {            
                const qId = encodedText.substring(opener + 1, closer);
                const question = questions.filter(q => q.id === qId)[0];

                retVal = `${retVal}${encodedText.substring(index, opener-1)}{${question.text}}`;

                index = closer + 1;
                opener = encodedText.indexOf('{', closer + 1);
                closer = encodedText.indexOf('}', opener + 1);
            }
            return retVal !== '' ? retVal : encodedText;
        } catch(e) {
            console.error(e);
            return encodedText;
        }
    };

    const handleSelectedQuestion = async (selectedQuestion, parentQuestion) => {
        setDirty(`${selectedQuestion.id} was used`);
        if (isChildQuestion) {
            let children = currentQuestion.children;
            children[questionIndex][connectedTextField] = `${currentQuestion[connectedTextField]}{${selectedQuestion.id}}`;
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {children})));
        } else {
            currentQuestion[connectedTextField] = `${currentQuestion[connectedTextField]}{${selectedQuestion.id}}`;
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, currentQuestion)));
        }
        await setConnectedTextField(undefined);
        setShowQuestionDialog(false);
    };

    const handleChooseQuestionClose = async () => {
        setShowQuestionDialog(false);
        setConnectedTextField(undefined);
    };

    const handleAddMultipleChoiceValue = async () => {
        let choices = currentQuestion.possible_answers && [...currentQuestion.possible_answers] || [];
        if (isChildQuestion) {
            choices = currentQuestion.children[questionIndex].possible_answers && [...currentQuestion.children[questionIndex].possible_answers] || [];
            choices.push(nextRowText);
            let nextChildren = [...currentQuestion.children];
            nextChildren[questionIndex] = Object.assign({}, nextChildren[questionIndex], {possible_answers: choices});
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children: nextChildren })));
        } else {
            choices.push(nextRowText);
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {possible_answers: choices})));
        }        
        await setDirty(`${nextRowText} was added as a multiple value`);
        await setNextRowText('');
    };

    const handleSelectedValueChange = (val, idx) => {
        let choices = currentQuestion.possible_answers && [...currentQuestion.possible_answers] || [];
        if (isChildQuestion) {
            choices = currentQuestion.children[questionIndex].possible_answers && [...currentQuestion.children[questionIndex].possible_answers] || [];
            choices[idx] = val;
            let nextChildren = [...currentQuestion.children];
            nextChildren[questionIndex] = Object.assign({}, nextChildren[questionIndex], {possible_answers: choices});
            dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children: nextChildren })));
        } else {
            choices[idx] = val;
            dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {possible_answers: choices})));
        }        
        setDirty(`changed a multiple value entry to ${val} `);
    };

    const handleArrowUp = async (choiceIdx) => {
        if (choiceIdx <= 0) return;
        if (isChildQuestion) {
            let choices = currentQuestion.children[questionIndex].possible_answers && [...currentQuestion.children[questionIndex].possible_answers] || [];
            var tmp = choices[choiceIdx-1];
            choices[choiceIdx-1] = choices[choiceIdx];
            choices[choiceIdx] = tmp;
            currentQuestion.children[questionIndex].possible_answers = choices;
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children: currentQuestion.children })));
        } else {
            let choices = [...currentQuestion.possible_answers];
            var tmp = choices[choiceIdx-1];
            choices[choiceIdx-1] = choices[choiceIdx];
            choices[choiceIdx] = tmp;
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {possible_answers: choices})));
        }        
        await setSelectedChoiceRow(choiceIdx -1);
        await setDirty(`a value within a multiple value list was moved`);
    };

    const handleArrowDown = async (choiceIdx) => {
        if (choiceIdx === currentQuestion.possible_answers.length - 1) return;
        if (isChildQuestion) {
            let choices = [...currentQuestion.children[questionIndex].possible_answers];
            var tmp = choices[choiceIdx+1];
            choices[choiceIdx+1] = choices[choiceIdx];
            choices[choiceIdx] = tmp;
            currentQuestion.children[questionIndex].possible_answers = choices;
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children: currentQuestion.children })));
        } else {
            let choices = [...currentQuestion.possible_answers];
            var tmp = choices[choiceIdx+1];
            choices[choiceIdx+1] = choices[choiceIdx];
            choices[choiceIdx] = tmp;
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {possible_answers: choices})));
        }
        await setSelectedChoiceRow(choiceIdx + 1);
        await setDirty(`a value within a multiple value list was moved`);
    };

    const handleRemoveSelected = async () => {
        if (isChildQuestion) {
            let choices = [...currentQuestion.children[questionIndex].possible_answers];
            choices.splice(selectedChoiceRow, 1);
            const nextChild = Object.assign({}, 
                currentQuestion.children[questionIndex], {possible_answers: choices});
            const nextChildren = [...currentQuestion.children];
            nextChildren.splice(questionIndex, 1, nextChild);
            // currentQuestion.children[questionIndex].possible_answers = choices;
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children: nextChildren })));
        } else {
            let choices = [...currentQuestion.possible_answers];
            choices.splice(selectedChoiceRow, 1);
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, {possible_answers: choices})));
        }
        await setShowAlert(undefined);
        await setSelectedChoiceRow(undefined);
        await setDirty(`a value was deleted`);
    };

    const handleTitleFormat = async (event, newFormats) => {
        const update = {
            title_bold: newFormats.indexOf('bold') >= 0,
            title_italic: newFormats.indexOf('italic') >= 0,
            title_underline: newFormats.indexOf('underlined') >= 0 
        };
        
        if (isChildQuestion) {
            currentQuestion.children[questionIndex] = Object.assign({}, currentQuestion.children[questionIndex], update );
            await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, { children: currentQuestion.children })));
        }
        else await dispatch(Actions.setCurrentQuestion(Object.assign({}, currentQuestion, update)));
    };
    
    const usedQuestion = question || Object.assign({type: questionTypesValues[0]}, currentQuestion);
    return (
        <div className={classes.root}>
            <ChooseQuestionDialog 
                open ={showQuestionDialog}
                onClose={handleChooseQuestionClose}
                onQuestionSelected={handleSelectedQuestion}
            />
            <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <Typography className={classes.title}>Title for wizard mapping</Typography>
                </div>
                <StyledTextField multiline name='question_map_title' className={classes.textField}
                                 value={usedQuestion.question_map_title} onChange={onChange}></StyledTextField>
            </div>
            <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <Typography className={classes.title}>Question Title</Typography>
                    <ToggleButtonGroup value={toggles} onChange={handleTitleFormat} arial-label="text formatting" className={classes.stylingDiv}>
                        <ToggleButton value="bold" aria-label="bold" className={classes.toggleButton}>
                            <FormatBoldIcon className={classes.innerIcon} />
                        </ToggleButton>
                        <ToggleButton value="italic" aria-label="italic" className={classes.toggleButton}>
                            <FormatItalicIcon className={classes.innerIcon} />
                        </ToggleButton>
                        <ToggleButton value="underlined" aria-label="underlined" className={classes.toggleButton}>
                            <FormatUnderlinedIcon className={classes.innerIcon} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Button className={classes.button} aria-label="list" onClick={()=>showTitlesList('title')}>
                        <img src={`${SERVER_URL}/assets/icons_x.svg`} className={classes.listIcon} />
                    </Button>
                </div>
                {/* <Tooltip label={questionTextHelper(usedQuestion.title)} interactive arrow> */}
                    <StyledTextField name='title' className={classes.textField}
                        multiline
                        InputProps={{
                            classes: {
                                input: classNames(usedQuestion.title_italic && classes.italic, 
                                    usedQuestion.title_underline && classes.underline,
                                    usedQuestion.title_bold && classes.bold)
                        }}}
                        value={usedQuestion.title} onChange={onChange} />
                {/* </Tooltip> */}
                {errors.title && <Typography className={classes.errorText}>{errors.title}</Typography>}
            </div>
            <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <Typography className={classes.title}>Question</Typography>
                    <Button className={classes.button} aria-label="list" onClick={() => showTitlesList('text')}>
                        <img src={`${SERVER_URL}/assets/icons_x.svg`} className={classes.listIcon} />
                    </Button>
                </div>
                <StyledTextField multiline name='text' className={classes.textField} 
                    value={usedQuestion.text} onChange={onChange}></StyledTextField>
                {/* {usedQuestion.text && usedQuestion.text.indexOf('{') >= 0 && 
                <Typography className={classes.calculated}>{questionTextHelper(usedQuestion.text)}</Typography>} */}
                {errors.text && <Typography className={classes.errorText}>{errors.text}</Typography>}
            </div>
            <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <Typography className={classes.title}>Explanation Text</Typography>                        
                </div>
                <StyledTextField multiline className={classes.textField} name='explanation_text' value={usedQuestion.explanation_text} onChange={onChange}></StyledTextField>
            </div>
            <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <Typography className={classes.title}>Contact field</Typography>
                </div>
                <DropdownAutocomplete currentQuestion = { currentQuestion } isChildQuestion = {isChildQuestion} questionIndex = {questionIndex} />
            </div>
            <div className={classes.sectionContainer}>
                <div className={classes.spacedRow}>
                    <div className={classes.typeContainer}>
                        <Typography className={classes.title}>Question type</Typography>
                        <FormControl className={classes.formControl}>
                            <StyledSelect 
                                name='type'
                                className={classes.select}
                                value={usedQuestion && usedQuestion.type || questionTypesValues[0]}
                                inputProps={{
                                    classes: {
                                        icon: classes.icon,
                                    },
                                }}
                                onChange={onChange}>
                                {questionTypes.map((name, idx) => (
                                    <StyledMenuItem dense value={questionTypesValues[idx]} key={`${name}${idx}`}>{name}</StyledMenuItem>
                                ))}
                            </StyledSelect>
                        </FormControl>
                    </div>
                    <FormControlLabel className={classes.mandatorySwitch} labelPlacement="start" label="Mandatory" classes={{label: classes.formLabel}} control={
                        <StyledSwitch checked={usedQuestion.mandatory === true} name='mandatory' onChange={onChange} />
                    } />
                </div>    
            </div>
            {['text_box', 'email', 'address'].indexOf(usedQuestion.type) >= 0 && 
                <div>
                    {usedQuestion.type !== 'email' && <div className={classes.sectionContainer}>
                        <div className={classes.textBoxDiv}>
                            <Typography className={classes.title}>Text area size</Typography>
                            <FormControl className={classes.formControl}>
                                <StyledSelect 
                                    name='num_lines'
                                    className={classes.select}
                                    value={usedQuestion.num_lines || 3}
                                    inputProps={{
                                        classes: {
                                            icon: classes.icon,
                                        },
                                    }}
                                    onChange={onChange}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
                                        <StyledMenuItem dense value={i} key={`menu_${i}`}>{`${i} lines`}</StyledMenuItem>
                                    ))}
                                </StyledSelect>
                            </FormControl>
                        </div>
                    </div>}
                    <div className={classes.sectionContainer}>
                        <RadioGroup
                            value={hintOrDefault}
                            onChange={(e) => setHintOrDefault(e.target.value)}
                            row>
                            <FormControlLabel
                                classes={{label: classes.radioLabel}}
                                value="hint_text"
                                control={<Radio color="secondary" />}
                                label="Hint Text"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                classes={{label: classes.radioLabel}}
                                value="default_value"
                                control={<Radio color="secondary" />}
                                label="Default Value"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                        {hintOrDefault==='hint_text' && <div>
                            <StyledTextField multiline className={classes.textField} name='hint_text' value={usedQuestion.hint_text} onChange={onChange}></StyledTextField>
                        </div>}
                        {hintOrDefault === 'default_value' && <div>
                            <StyledTextField multiline className={classes.textField} name='default_value' value={usedQuestion.default_value} onChange={onChange}></StyledTextField>
                        </div>}
                    </div>
                </div>
            }
            {usedQuestion.type==='number' && 
                <div className={classes.sectionContainer}>
                    <div className={classes.spacedRow}>
                        <div className={classes.textBoxDiv}>
                            <Typography className={classes.title}>Min. Value</Typography>
                            <StyledTextField type='number' onBlur={onBlur} className={classes.textField} name='min_value' value={usedQuestion.min_value} onChange={onChange}></StyledTextField>
                        </div>
                        <div className={classes.textBoxDiv}>
                            <Typography className={classes.title}>Max. Value</Typography>
                            <StyledTextField type='number' onBlur={onBlur} className={classes.textField} name='max_value' value={usedQuestion.max_value} onChange={onChange}></StyledTextField>
                        </div>                            
                    </div>
                    {errors.minMax && <Typography className={classes.errorText}>{errors.minMax}</Typography>}
                </div>
            }
            {/* {usedQuestion.type==='date' && 
                <div className={classes.sectionContainer}>
                    <Typography className={classes.title}>Date Format</Typography>
                    <FormControl className={classes.formControl}>
                        <StyledSelect 
                            name='date_format'
                            className={classes.select}
                            value={usedQuestion.date_format}
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}
                            onChange={onChange}>
                            {['dd/mm/yy', 'dd/mm/yyyy', 'mm/dd/yy', 'mm/dd/yyyy'].map((format, idx) => (
                                <StyledMenuItem dense value={idx} key={`menu_${format}`}>{`${dateFormat(new Date(), format)} - ${format}`}</StyledMenuItem>
                            ))}
                        </StyledSelect>
                    </FormControl>
                </div>
            } */}
            {usedQuestion.type==='attachment' && 
                <div>
                    <div className={classes.sectionContainer}>
                        <Typography className={classes.title}>Attachment button label</Typography>
                        <StyledTextField className={classes.textField} name='attachment_label' value={usedQuestion.attachment_label} onChange={onChange}></StyledTextField>
                    </div>
                    <div className={classes.sectionContainer}>
                        <Typography className={classes.title}>Change document name to</Typography>
                        <StyledTextField className={classes.textField} name='attachment_rename' value={usedQuestion.attachment_rename} onChange={onChange}></StyledTextField>
                    </div>
                </div>
            }
            {['drop_down', 'checkboxes', 'radio_buttons'].indexOf(usedQuestion.type) >= 0  && 
                <div>
                    <AlertDialog 
                        title='Deleting' 
                        text={`Are you sure you want to Delete the value: ${selectedChoiceRow && usedQuestion.possible_answers[selectedChoiceRow] || ''}?`} 
                        open={showAlert} 
                        onOk={handleRemoveSelected}
                        onCancel={()=>{setShowAlert(false);}} />
                    <div className={classes.sectionContainer}>
                        <Typography className={classes.title}>Values</Typography>
                        {usedQuestion.possible_answers && usedQuestion.possible_answers.map((choice, idx) =>
                            <div className={classNames(classes.choice,  idx === selectedChoiceRow ? 'Mui-selected' : '')} onClick={()=>setSelectedChoiceRow(idx)}>
                                <Typography className={classes.choiceIndex}>{idx + 1}</Typography>
                                {idx !== selectedChoiceRow && <Typography className={classes.choiceText}>{choice}</Typography>}
                                {idx === selectedChoiceRow && <StyledTextField multiline value={choice} onChange={e=>{handleSelectedValueChange(e.target.value, idx);}}/>}
                                {idx === selectedChoiceRow && <Button className={classes.iconButton} onClick={() => handleArrowDown(idx)}
                                    disabled={idx===(usedQuestion.possible_answers.length - 1)}>
                                    <KeyboardArrowDown color="primary" />
                                </Button>}
                                {idx === selectedChoiceRow && <Button className={classes.iconButton} disabled={idx===0} onClick={() => handleArrowUp(idx)}>
                                    <KeyboardArrowUp color="primary" />
                                </Button>}
                                {idx === selectedChoiceRow && <Button className={classes.iconButton} onClick={() => setShowAlert(true)}>
                                    <img src={`${SERVER_URL}/assets/icons_delete.svg`} className={classes.erazeIcon} /> 
                                </Button>}
                            </div>
                        )}
                    </div>
                    <div className={classes.dottedDivider} />
                    <div className={classes.sectionContainer}>
                        <div className={classes.choice}>
                            <Typography className={classes.choiceIndex}>{usedQuestion.possible_answers && usedQuestion.possible_answers.length + 1 || 1}</Typography>
                            <InputBase 
                                placeholder={'Enter Value'} 
                                className={classes.newRowValueField} 
                                value={nextRowText} 
                                onChange={e => setNextRowText(e.target.value)}
                                onFocus={e=>{
                                    setSelectedChoiceRow(undefined);
                                }}
                                onBlur={async e => {
                                    await setNextRowText(e.target.value);
                                    if (nextRowText) handleAddMultipleChoiceValue();                                    
                                }}
                            />
                        </div>
                        <ColorButton color="primary" className={classes.addValueButton} onClick={handleAddMultipleChoiceValue}>+ Add Value</ColorButton>
                    </div>
                    <div className={classes.sectionContainer}>
                        <Typography className={classes.title}>Default value</Typography>
                        <StyledTextField multiline className={classes.textField} name='default_value' value={usedQuestion.default_value} onChange={onChange}></StyledTextField>
                    </div>


                    <div className={classes.dottedDivider} />
                    <div className={classes.sectionContainer}>
                        <div className={classNames(classes.spacedRow, classes.justifyNone)}>
                            <StyledSwitch checked={usedQuestion.add_other === true} name='add_other' onChange={onChange} className={classes.addOtherSwitch} />
                            <div>
                                <Typography className={classes.addOtherLabel}>Add 'Other' Option</Typography>
                                <Typography className={classes.addOtherHelper}>User will be able to enter free text</Typography>
                            </div>
                        </div>
                    </div>
                </div>
                
            }
            {!isChildQuestion && <div className={classes.bottomDweller} ref={questionEndEL} />}
        </div>
    );
}
export default withRouter(SingleQuestionView);
