import React, { useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Redirect} from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {Tooltip, Fab, Zoom, LinearProgress, Typography, IconButton} from '@material-ui/core';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import QuestionCardView from './QuestionCardView';
import * as Actions from '../../redux/actions';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        '&.empty': {
            alignItems: 'center',
            marginTop: '112px'
        }
    },
    label: {
        opacity: '0.77',
        fontFamily: 'Assistant',
        fontSize: '11px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: '0.22px',
        textAlign: 'center',
        color: '#97a1b0',        
    },
    indicator: {
        backgroundColor: "#1274e4"
    },
    tab: {
        backgroundColor: 'white',
        '&.Mui-selected > span > span': {
            fontFamily: 'Assistant',
            fontSize: '11px',
            fontWeight: '700',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.12px',
            textAlign: 'center',
            color: '#162250'
        }        
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(2),
        left: 'calc(50% - 26px)',
        boxShadow: 'none',
        '&.empty': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            right: 0
        },
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: 'white',
        border: '1.5px solid #1274e4',
        color: '#1274e4',
        '&:hover': {
            color: '1px solid #1480F0',
            backgroundColor: 'rgba(18, 116, 228, 0.6)'
        },
    },
    progress: {
        width: '74%',
        alignSelf: 'center'
    },
    noQuestions: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        lineHeight: '1.78',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#454545'
    },
    separator: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width :'100%',
        margin: 'auto',
        marginTop: '-14px',
        height: '25px',
        opacity: 0,
        zIndex: 100,
        '&:hover': {
            opacity: 0.75,
            cursor: 'pointer',
        }
    },
    switchButton: {
        width: '20px',
        height: '20px',
        color: 'blue',
        fontSize: '13px'
    }
}));

export default function QuestionsView() {
    const classes = useStyles();
    const [redirectNew, setRedirectNew] = useState(false);
    const theme = useTheme();
    const documentId = useSelector(state => state.document.documentId);
    const loadingPhase = useSelector(state => state.questions.loadingPhase); 
    const questions = useSelector(state => state.questions.questions); 
    const isLoading = loadingPhase !== '';
    const mainQuestions = questions.filter(q => { return q.parent_id === undefined; });
    const dispatch = useDispatch();

    const loadQuestions = async () => {
        if (!documentId) return;
        await dispatch(Actions.loadQuestions(documentId));
    }

    useEffect( () => {
        const init = async () => {
            await loadQuestions();
        }
        init();
    }, [documentId] );

    const handleDeleteClick = async questionId => {
        await dispatch(Actions.deleteQuestion(documentId, questionId));
    };

    const handleFabClick = async () => {
        await dispatch(Actions.initQuestion());
        await setRedirectNew(true);
    }; 

    const disabledArrows = [];
    for (let i = 0; i < mainQuestions.length; i++) {
        const q = mainQuestions[i];
        const nextQ = i < mainQuestions.length ? mainQuestions[i + 1] : undefined;
        if (nextQ === undefined || nextQ.conditions && nextQ.conditions.length > 0 && nextQ.conditions.filter(c => c.question_id === q.id).length > 0) {
            disabledArrows.push(i);
        }
    }
    console.log({disabledArrows});
    const switchQuestions = async (idx) => {
        const id1 = mainQuestions[idx].id;
        const id2 = mainQuestions[idx+1].id;

        await dispatch(Actions.switchQuestions(documentId, id1, id2));
    };
    const empty = questions && questions.length === 0;
    return redirectNew ? <Redirect to='/question'/> : (
        <div className={classNames(classes.root, empty && 'empty')}>
            {mainQuestions && mainQuestions.length > 0 && mainQuestions.map((q, idx) => (<div><QuestionCardView 
                    onDeleteClick={handleDeleteClick} 
                    question={Object.assign({}, q, {index: idx + 1})} key={idx}
                />
                {idx < mainQuestions.length - 1 && <div className={classes.separator}>
                    <Tooltip title={disabledArrows.indexOf(idx) < 0 ? 'Change questions order' : 'Disabled due to pre-conditions'} interactive arrow>
                        <div><IconButton 
                            disabled={disabledArrows.indexOf(idx) >= 0}
                            className={classes.switchButton} 
                            onClick={() => {
                                switchQuestions(idx);
                            }}>
                            ↨
                        </IconButton></div>
                    </Tooltip>
                </div>}
            </div>))}
            {empty && ! isLoading && <Typography className={classes.noQuestions}>Add your first question</Typography> }
            {isLoading && <LinearProgress className={classes.progress} />}
            {!isLoading && <Zoom in={true} timeout={{
                    enter: theme.transitions.duration.enteringScreen,
                    exit: theme.transitions.duration.leavingScreen,
                }}
                style={{
                    transitionDelay: `${theme.transitions.duration.leavingScreen}ms`,
                }}
                unmountOnExit>
                 <Fab onClick={handleFabClick} className={classNames(classes.fab, classes.fabGreen, empty && 'empty')}>
                    <AddIcon />
                </Fab>
            </Zoom>            
            }
        </div>
    );
}
