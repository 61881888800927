import React, { useState, useContext } from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import AlertDialog from  './AlertDialog';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import loggedInUserContext from '../../loggedInUserContext';
import { IconButton, Typography, Fab, Zoom, Chip, Avatar } from '@material-ui/core';
import NewObjectHeader from './NewObjectHeader';
import ConditionLine from './ConditionLine';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import classNames from 'classnames';
import ColorButton from './ColorButton';
import { SERVER_URL } from '../../api';
import * as Actions from '../../redux/actions';
import { ObjectId } from '../../utils';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        paddingBottom: '24px'
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        marginTop :'18px',
        marginLeft: '32px',
        width: '90%',
    },
    dottedDivider: {
        height: '1px',
        borderBottom: '1px dashed rgba(187, 187, 187, 1);',
        margin: '18px 0px'
    },
    col: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        margin: '0px 8px'
    },
    octopusIcon: {
        height: '24px',
        width: '24px', 
        margin: '0px 4px'       
    },
    phraseTitle: {
        margin: '0px 4px',     
        fontFamily: 'Assistant',
        fontWeight: 800,
        fontSize: '16px',
        color: 'rgba(46, 93, 147, 1)',
        textlaign: 'left'
    },
    longerTitle: {
        fontfamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(119, 119, 119, 1)',
        textAlign: 'left',
        marginLeft: '8px',
        fontWeight: 300
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row'
    },
    button: {
        margin: theme.spacing(0),
        fontSize: '11px',
        lineHeight: '11px',
        color : 'rgba(18, 116, 228, 1)'
    },
    titleDiv: {
        display: 'flex',
        flexDirection: 'row',
        margin: '18px 8px',
        backgroundColor: 'rgba(234, 240, 248, 0.34)',
        height: '35px',
        width: '100%',
        bottomBorder: 'border: 1px solid rgba(18, 116, 228, 0.15)',
        alignItems: 'center',
        alignContent: 'center'
    },
    titleDivTitle: {
        fontFamily: 'Assistant',
        fontWeight: 400,
        fontSize: '14px',
        color: 'rgba(136, 165, 194, 1)',
        textAlign: 'left',
        margin: '0px 12px',
        alignSelf: 'center'      
    },
    textLogic: {
        fontFamily: 'Assistant',
        fontSize: '13px',
        fontWeight: 300
    },
    alternativeContainer: {

    },
    altRow: {
        display: 'flex',
        flexDirection: 'row',
        marginTop :'18px',
        marginLeft: '12px'
    },
    boldIndex: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: 'bold',
        color: 'rgba(77, 84, 92, 1)',
        textAlign: 'center',
        lineHeight: '24px',
        marginLeft: '12px'
    },
    slashIndex: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: 300,
        color: 'rgba(207, 207, 207, 1)',
        textAlign: 'center',
        lineHeight: '24px',
        margin: '0px 4px'
    },
    lengthIndex: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        color: 'rgba(136, 136, 136, 1)',
        textAlign: 'center',
        lineHeight: '24px'
    },
    altText: {
        fontFamily: 'Assistant',
        fontSize: '14px',
        fontWeight: 700,
        color: 'rgba(87, 96, 107, 1)',
        textAlign: 'left',
        marginLeft: '36px'
    },
    fabBottom: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        boxShadow: 'none',
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: 'white',
        border: '1.5px solid #1274e4',
        color: '#1274e4',
        '&:hover': {
            color: '1px solid #1480F0',
            backgroundColor: 'rgba(18, 116, 228, 0.6)'
        },
    },

    fab: {
        padding: 0,
        boxShadow: 'none',
        color: theme.palette.common.white,
        backgroundColor: 'white',
        border: '1.5px solid #1274e4',
        color: '#1274e4',
        '&:hover': {
            color: '1px solid #1480F0',
            backgroundColor: 'rgba(18, 116, 228, 0.6)'
        },
        '&.empty': {
            position: 'relative',
            textAlign: 'center',
            bottom: 0,
            right: 0
        },
    },
    conditionLine: {
        margin: '8px 24px',
        marginLeft: '80px'
    },
    orChip: {
        margin: '18px 0px 18px 30px'

    },
    andChip: {
        margin: '12px 0px 0px 80px'
    },
    addButton: {
        margin: '0px 0px 0px 0px'
    }
}));

const PhraseView = (props) => {
    const { match: { params } } = props;
    const classes = useStyles();
    const [dirty, setDirty] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [deleteCandidate, setDeleteCandidate] = useState({});
    const [redirectBack, setRedirectBack] = useState(false);
    const context = useContext(loggedInUserContext);
    const backURL = `${context.lastURL.pathname}${context.lastURL.search}`;
    const theme = useTheme();
    const allQuestions = useSelector(state => state.questions.questions);
    const clauses = useSelector(state => state.document.clauses);
    const clauseId = params.clause_id;
    const phraseId = params.phrase_id;
    console.log('got clause id '+clauseId)
    const clause = clauses.find(c => c.id === clauseId);
    if (!clause) {
        throw new Error('invalid clause');
    }
    let phrase;
    for (let i = 0; i < clause.alternatives.length; ++i) {
        phrase = clause.alternatives[i].phrases.find(p => p.id === phraseId);
        if (phrase) {
            break;
        }
    }

    if (!phrase) {
        throw new Error('invalid phrase ' + phraseId);
    }
    const dispatch = useDispatch();
    const loadingPhase = useSelector(state => state.questions.loadingPhase);
    const docId = useSelector(state => state.document.id);
    
    const addCondition = async (altIndex, operator, childIndex) => {
        let alts = phrase.alternatives.slice(0); //clone
        let updatedAlt = alts[altIndex];
        let conditions = updatedAlt.conditions.slice(0) || [];
        try {
            if (childIndex)
                conditions.splice(childIndex, 0, {operator});
            else 
                conditions.push({operator});

            alts[altIndex] = Object.assign({}, updatedAlt, { conditions });
            const newPhrase = Object.assign({}, phrase, { clause_id: clause.id, alternatives: alts }); // this is a pointer to the actual phrase in the clause object.
            await dispatch({ type: Actions.UPDATE_PHRASE, success: true, phrase: newPhrase }); // clause phrase was updated
        } catch(e) {
            console.error(e);
        }
    };

    const handleDelete = async (altIndex, condIndex) => {
        console.log(altIndex, condIndex);
        await setDeleteCandidate({altIndex, condIndex });
        await setShowAlert(true);
    };

    const onAlertOkClick = async () => {
        try {        
            let alts = phrase.alternatives.slice(0);
            let updatedAlt = alts[deleteCandidate.altIndex];
            let conditions = updatedAlt.conditions.slice(0);
            conditions.splice(deleteCandidate.condIndex, 1);
            updatedAlt = Object.assign({}, updatedAlt, { conditions });        
            alts[deleteCandidate.altIndex] = updatedAlt;
            const newPhrase = Object.assign({}, phrase, { clause_id: clause.id, alternatives: alts }); // this is a pointer to the actual phrase in the clause object.
            await dispatch({ type: Actions.UPDATE_PHRASE, success: true, phrase: newPhrase }); // clause phrase was updated
            await setDeleteCandidate({});
            await setShowAlert(false);
            await setDirty(`Condition was deleted`);
        } catch(e) {
            console.error(e);
        }
    };

    const handleQuestionChanged = async (e, altIndex, condIndex) => {
        let alts = phrase.alternatives.slice(0); //clone
        let updatedAlt = alts[altIndex];
        let conditions = updatedAlt.conditions.slice(0);
        let updatedCondition = Object.assign({}, conditions[condIndex], {[e.target.name]: e.target.value});
        conditions[condIndex] = updatedCondition;
        updatedAlt = Object.assign({}, updatedAlt, {conditions});        
        alts[altIndex] = updatedAlt;
        const newPhrase = Object.assign({}, phrase, { clause_id: clause.id, alternatives: alts }); // this is a pointer to the actual phrase in the clause object.
        await dispatch({ type: Actions.UPDATE_PHRASE, success: true, phrase: newPhrase }); // clause phrase was updated
        await setDirty(`Condition was changed`);
    };

    const handleAddAlternative = async (e) => {
        let alts = phrase.alternatives.slice(0); // clone
        // generate new default alternative based on last one created
        let name = 'alt #1';
        for (let i = alts.length - 1; i >= 0; --i) {
            if (alts[i].name.startsWith('alt #')) {
                let n = alts[i].name.slice('alt #'.length);
                if (!isNaN(n)) {
                    name = 'alt #' + String(parseInt(n) + 1);
                    break;
                }
            }
        }
        alts.push({
            id: ObjectId(),
            text: '',
            name,
            raw_data: '<w:r><w:t></w:t></w:r>',
            conditions: []
        })
        const newPhrase = Object.assign({}, phrase, { clause_id: clause.id, alternatives: alts }); // this is a pointer to the actual phrase in the clause object.
        await dispatch({ type: Actions.UPDATE_PHRASE, success: true, phrase: newPhrase }); // clause phrase was updated
        await setDirty(true);
    }

    const onSaveClick = async () => {
        await dispatch(Actions.updatePhrase(clauseId, phrase));
        await setDirty(undefined);
        await setRedirectBack(true);
    };
    const calculatedTexts = [];
    const altsWithConditions = [];
    for (let a = 0; a < phrase.alternatives.length; a++) {
        const alt = phrase.alternatives[a];
        const conditions = alt.conditions;
        const conditionItems = [];
        for (let i = 0; i < conditions.length; i++) {
            const isAnd = conditions[i].operator !== undefined && conditions[i].operator.toLowerCase() === 'and';
            const isPartOfAndClause = i !== 0 && isAnd || // either this is and AND line, or next line is and AND line
                conditions[i+1] && conditions[i+1].operator && conditions[i+1].operator.toLowerCase() === 'and';
            const nextOneIsOr = i+1 === conditions.length || conditions[i+1] && (conditions[i+1].operator === undefined || conditions[i+1].operator.toLowerCase() === 'or');
            console.log({conditions, i, nextOneIsOr});
            conditionItems.push(<ConditionLine 
                //hiddenQuestionIds={currentQuestion.is_multi ? [currentQuestion.id, ...currentQuestion.children_ids] :[currentQuestion.id]} //TODO: hide everything after me
                hiddenQuestionIds={[]}
                key={`${a}${i}`} 
                index={i} 
                onDelete={() => {handleDelete(a, i);}} 
                onQuestionChanged={(e, i) => {
                    handleQuestionChanged(e, a, i);
                }} 
                condition={conditions[i]} 
                isPartOfAndClause={isPartOfAndClause}
                />);
            if (nextOneIsOr) {
                conditionItems.push(<div style={{'marginLeft': '60px'}} key={`div${i}`}>
                    <ColorButton className={classes.addButton} onClick={() => {addCondition(a, 'and', i + 1);}}>+ Add AND Condition</ColorButton>
                </div>);
            }
            if (i + 1 < conditions.length) {
                conditionItems.push(<div >                
                    <Chip label={nextOneIsOr ? 'OR' : 'AND'} color={!nextOneIsOr? "action" : ""} 
                        avatar={<Avatar>{nextOneIsOr ? '+' : '*'}</Avatar>}
                        size="small"
                        className={!nextOneIsOr ? classes.andChip : classes.orChip}
                    />
                </div>);
            }
        }
        altsWithConditions.push(conditionItems);
        let calculatedTextLogic = `This alternative will be shown if and only if`;
        for (let ii = 0; ii < conditions.length; ii++) {
            const c = conditions[ii];
            const theQuestion = allQuestions.find(q => q.id === c.question_id);
            if (theQuestion) {
                calculatedTextLogic = `${calculatedTextLogic} ${ii !== 0 && c.operator && c.operator.toUpperCase() || ''} ${c.question_id ? theQuestion.text : ''} ${c.type || ''} ${c.value || ''}`;
            } else {
                calculatedTextLogic = 'missing question from condition';
            }
        }
        calculatedTexts.push(calculatedTextLogic);
    };
    console.log({calculatedTexts});


    return redirectBack ? <Redirect to={backURL} /> : (
        <div className={classes.root}>
            <NewObjectHeader title='Phrase Condition' boxShadow={0} onSaveClick={onSaveClick} isSaving={loadingPhase !== ''} dirty={dirty} />
            <AlertDialog 
                title='Are you sure?' 
                text={`You are about to delete a condition, are you sure you want to do it?`} 
                open={showAlert} 
                onOk={onAlertOkClick} 
                onCancel={async ()=>{
                    await setShowAlert(false);
                    await setDeleteCandidate({});
                }} />
            <div className={classes.row}>                            
                <img src={`${SERVER_URL}` + '/assets/icons_octopus.svg'} className={classes.octopusIcon} /> 
                <div className={classes.col}>
                    <Typography className={classes.phraseTitle}>{phrase.name}</Typography>
                    <Typography className={classes.longerTitle}>{'longer title'}</Typography>
                </div>
                <div className={classes.buttonsContainer}>
                    <IconButton className={classes.button}>&lt;</IconButton>
                    <IconButton className={classes.button}>&gt;</IconButton>
                </div>
            </div>
            <div className={classes.titleDiv}>
                <Typography className={classes.titleDivTitle}>Clause</Typography>
                <Typography className={classes.titleDivTitle}>Condition</Typography>
            </div>
            {phrase.alternatives.map((alt, idx) => {return <div className={classes.alternativeContainer} key={alt.id}>
                <div className={classes.altRow}>
                    <Typography className={classes.boldIndex}>{idx + 1}</Typography>
                    <Typography className={classes.slashIndex}>{'/'}</Typography>
                    <Typography className={classes.lengthIndex}>{phrase.alternatives.length}</Typography>
                    <Typography className={classes.altText}>{alt.name}</Typography>
                </div>                
                {altsWithConditions && altsWithConditions[idx]}
                <div className={classes.dottedDivider} />
                <ColorButton onClick={() => {addCondition(idx, alt.conditions.length === 0 ? undefined : "or");}}>{`+ Add ${alt.conditions.length > 0 ? ' OR': 'a '} Condition`}</ColorButton>
                {altsWithConditions && altsWithConditions[idx] && <div className={classes.dottedDivider} />}
                {altsWithConditions && altsWithConditions[idx] && <Typography style={{margin:'0px 22px'}} className={classes.textLogic}>{calculatedTexts[idx]}</Typography>}
            </div>})}
            <Zoom in={true} timeout={{
                    enter: theme.transitions.duration.enteringScreen,
                    exit: theme.transitions.duration.leavingScreen,
                }}
                style={{
                    transitionDelay: `${theme.transitions.duration.leavingScreen}ms`,
                }}
                unmountOnExit>
                <Fab onClick={handleAddAlternative}  className={classNames(classes.fabBottom, classes.fabGreen)}>
                    <AddIcon />
                </Fab>
            </Zoom>
        </div>
    );
}
export default withRouter(PhraseView);