import React from 'react';
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide} from '@material-ui/core';
import { red } from '@material-ui/core/colors'
const redTheme = createMuiTheme({ palette: { primary: red } })

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
    title: {
        fontFamily: 'Assistant',        
        color: 'white',
        backgroundColor: theme.palette.error[700],
        height: '28px',
        '& > .MuiTypography-root': {
            fontSize: '15px',
            fontWeight: 500,
        }
    },    
    text: {
        fontFamily: 'Assistant',
        fontSize: '13px'
    }
}));

export default function AlertDialog(props) {
    const {open, onCancel, onOk, title, text, buttonTexts} = props;
    const classes  = useStyles();
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onCancel}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className={classes.title}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" className={classes.text}>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="secondary">
                    {buttonTexts && buttonTexts[0] || 'Cancel'}
                </Button>
                <MuiThemeProvider theme={redTheme}>
                    <Button onClick={onOk}color='primary'>
                    {buttonTexts && buttonTexts[1] || 'OK'}
                    </Button>
                </MuiThemeProvider>
            </DialogActions>
        </Dialog>
    );
}