import * as Actions from '../actions';

const initialState = {
    loadingPhase: '',
    questions: [],
    currentQuestion: {},
    errors: {}
};

function questionsReducer(state = initialState, action) {
    switch (action.type) {
        case Actions.FETCH_QUESTIONS:
            if (typeof action.success === 'boolean') {
                if (action.skipped) {
                    return Object.assign({}, state, { loadingPhase: '' });
                }
                return Object.assign({}, state, {
                    questions: action.success ? action.questions : [],
                    loadingPhase: '',
                });
            } else {
                return Object.assign({}, state, {
                    loadingPhase: 'fetching'
                });
            }
        case Actions.UPDATE_QUESTION:
            if (typeof action.success === 'boolean') {
                if (action.success) {
                    const origQuestions = state.questions;
                    origQuestions[origQuestions.findIndex(q=>q.id === action.question.id)] = action.question;
                    return Object.assign({}, state, {loadingPhase: '', questions: origQuestions});
                } else {
                    console.error(action.error);
                    return Object.assign({}, state, {loadingPhase: ''});
                }
            } else {
                return Object.assign({}, state, {
                    loadingPhase: 'Updating'
                });
            }
        case Actions.ENSURE_QUESTION:
            if (typeof action.success === 'boolean') {
                state.questions.push(state.currentQuestion);
                // also push the children themselves to questions array
                if (state.currentQuestion.is_multi) {
                    state.currentQuestion.children.map(c =>  {
                        state.questions.push(c);
                    });
                }
            }
            return Object.assign({}, state, {
                loadingPhase: (typeof action.success === 'boolean') ? '' : 'creating'
            });
        case Actions.DELETE_QUESTION:
            let filtered = state.questions.filter(q => q.id !== action.question_id);
            return Object.assign({}, state, { 
                loadingPhase: (typeof action.success === 'boolean') ? '' : 'deleting',
                questions : (typeof action.success === 'boolean') ?  filtered : state.questions
            });
        case Actions.SWITCH_QUESTIONS:
            if (action.success) {
                const questions = [...state.questions];
                let index1, index2;
                for (let i = 0; i < questions.length && (index1 === undefined || index2 === undefined); ++i) {
                    if (questions[i].id === action.question_id1) {
                        index1 = i;
                    } else if (questions[i].id === action.question_id2) {
                        index2 = i;
                    }
                }
                if (index1 !== undefined && index2 !== undefined) {
                    [questions[index1], questions[index2]] = [questions[index2], questions[index1]]
                    state.questions = questions;
                    return Object.assign({}, state, { questions });
                }    
            }
            return state;
        case Actions.SET_CURRENT_QUESTION:
            if (action.question) {
                return Object.assign({}, state, {currentQuestion: action.question});
            }
        case Actions.SET_ERRORS:
            return Object.assign({}, state, {
                errors: Object.assign({}, state.errors, action.errors)
            });
        default:
            return state;
    }    
}


export default questionsReducer;