import { ObjectId } from './utils';
import { getEmptySignatureXml, getEmptyVariableXml, getTextRuns, getPhraseXml } from './ooxmlUtils';

export function createClauseContainer(paragraph, id) {
    const range = paragraph.getRange('Whole');
    const cc = range.insertContentControl();
    cc.appearance = 'Hidden';
    cc.tag = 'clause';
    cc.title = id;
}

export function getClauseContainer(context, id) {
    const container = context.document.contentControls.getByTitle(id).getFirst();
    return container;
}

export async function releaseContentControls(context) {
    const contentControls = context.document.contentControls;
    contentControls.load('items');
    await context.sync();
    for(let i = 0; i < contentControls.items.length; ++i) {
        const cc = contentControls.items[i];
        cc.cannotDelete = false;
        // cc.delete(true);
    }
    // await context.sync();
}

export async function getFirstContentControlById(context, id, tag) {
    const controls = context.document.contentControls.getByTitle(id);
    context.load(controls, ['text', 'tag']);
    await context.sync();
    if (!controls.items.length) {
        throw new Error('cannot find content control with id=' + id);
    }
    if (tag) {
        for (let i = 0; i < controls.items.length; ++i) {
            if (controls.items[i].tag === tag) {
                return controls.items[i];
            }
        }        
    }
    return controls.items[0];

}
export async function deletePhraseContainer(context, phraseId, container) {
    console.log('deleting document phrase', phraseId);
    // if content control container not provided find it.
    if (!container) {
        container = await getFirstContentControlById(context, phraseId);
    }

    // remove any delete protection so we can override the ooxml.
    const subControls = container.contentControls;
    context.load(subControls, 'text');
    await context.sync();
    for (let i = 0; i < subControls.items.length; ++i) {
        subControls.items[i].cannotEdit = false;
        subControls.items[i].cannotDelete = false;
        subControls.items[i].delete(false)
    }

    container.cannotDelete = false;
    container.cannotEdit = false;
    container.delete(false);
    await context.sync();
}

export async function updatePhraseContainer(context, phrase, container) {
    console.log('updating document phrase', phrase);
    // if content control container not provided find it.
    if (!container) {
        container = await getFirstContentControlById(context, phrase.id);
    }

    // remove any delete protection so we can override the ooxml.
    const subControls = container.contentControls;
    context.load(subControls, 'text');
    const ooxml = container.getOoxml();
    await context.sync();
    for (let i = 0; i < subControls.items.length; ++i) {
        subControls.items[i].cannotEdit = false;
        subControls.items[i].cannotDelete = false;
    }

    //replace ooxml with new phrase ooxml
    const match = ooxml.value.match(/<w:docPart>.*?<w:docPartPr>.*?<w:name.*?w:val=\"(.*?)\".*?\/>.*?<w:category>.*?<w:gallery.*?w:val=\"placeholder\".*?\/>/);
    const newXml = ooxml.value.replace(/(<w:body>)(.*?)(<\/w:body>)/, `$1<w:p>${getPhraseXml(phrase, match ? match[1] : undefined)}</w:p>$3`);
    container.insertOoxml(newXml, 'replace');
    
    // HACK: select last empty alternative then re-select it to get keyboard focus
    const lastAltContainer = await getFirstContentControlById(context, phrase.alternatives[phrase.alternatives.length - 1].id, 'p_alternative');
    const firstAltContainer = await getFirstContentControlById(context, phrase.alternatives[0].id, 'p_alternative');    
    lastAltContainer.getRange('content').select();
    firstAltContainer.getRange('content').select('select');
    lastAltContainer.getRange('content').select();
    await context.sync();
}

export async function createPhraseContainer(context) {
    try {
        let selection = context.document.getSelection();
        const parent = selection.parentContentControlOrNullObject;
        const children = selection.contentControls;
        context.load(children);
        context.load(selection);
        context.load(parent);
        await context.sync();
        if (!parent.isNullObject && !selection.isEmpty) {
            if (parent.tag === 'clause' || parent.tag === 'alternative') {
                if (children.items.length === 0) {
                    const selectionOoxml = selection.getOoxml();
                    const container = selection.insertContentControl();
                    container.appearance = 'hidden';
                    container.tag = 'phrase';
                    container.title = ObjectId();
                    container.cannotDelete = true;
                    selection = container.getRange('content');
                    context.load(selection);
                    await context.sync();
                    // remove artifacts word inserts..
                    const cleanXml = selectionOoxml.value
                        .replaceAll('<w:r><w:continuationSeparator/></w:r>', '')
                        .replaceAll('<w:r><w:separator/></w:r>', '')
                    await updatePhraseContainer(context, {
                        id: ObjectId(),
                        name: selection.text,
                        alternatives: [{ 
                            id: ObjectId(),
                            name: 'alt #1',
                            text: selection.text,
                            raw_data: getTextRuns(cleanXml)
                        }, {
                            id: ObjectId(),
                            name: 'alt #2',
                            text: '',
                            raw_data: '<w:r><w:t></w:t></w:r>'
                        }]
                    }, container);
                } else {
                    console.error('cannot create phrase that contains other container');
                }     
            } else {
                console.error('cannot create phrase in a "' + parent.tag + '" container');
            }
        } else {
            console.error('cannot create a phrase outside a clause');
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function createVariableContainer(context) {
    try {
        const selection = context.document.getSelection();
        context.load(selection);
        const parent = selection.parentContentControlOrNullObject;
        context.load(parent);
        await context.sync();
        if (!parent.isNullObject) {
            if (parent.tag === 'clause' || parent.tag === 'alternative' || parent.tag === 'p_alternative') {
                const children = selection.contentControls;
                context.load(children);
                await context.sync();
                if (children.items.length === 0) {
                    const cc = selection.insertContentControl();
                    cc.appearance = 'hidden';
                    cc.tag = 'variable';
                    cc.title = '';
                    context.load(cc);
                    const ooxml = cc.getOoxml();
                    await context.sync();
                    const newXml = ooxml.value.replace(/(<w:body>)(.*?)(<\/w:body>)/, `$1${getEmptyVariableXml()}$3`);
                    cc.insertOoxml(newXml, 'replace');
                    cc.cannotEdit = true;
                    await context.sync();
                    return cc;
                } else {
                    console.error('cannot create variable that contains other container');
                }     
            } else {
                console.error('cannot create variable in a "' + parent.tag + '" container');
            }
        } else {
            console.error('cannot create a variable outside a clause');
        }
    } catch (error) {
        console.error(error.stack);
        throw error;
    }
}

export async function createSignatureContainer(context) {
    try {
        const selection = context.document.getSelection();
        context.load(selection);
        const parent = selection.parentContentControlOrNullObject;
        context.load(parent);
        await context.sync();
        if (!parent.isNullObject) {
            if (parent.tag === 'clause' || parent.tag === 'alternative' || parent.tag === 'p_alternative') {
                const children = selection.contentControls;
                context.load(children);
                await context.sync();
                if (children.items.length === 0) {
                    const cc = selection.insertContentControl();
                    cc.appearance = 'hidden';
                    cc.tag = 'signature';
                    cc.title = '';
                    context.load(cc);
                    const ooxml = cc.getOoxml();
                    await context.sync();
                    const newXml = ooxml.value.replace(/(<w:body>)(.*?)(<\/w:body>)/, `$1${getEmptySignatureXml()}$3`);
                    cc.insertOoxml(newXml, 'replace');
                    cc.cannotEdit = true;
                    await context.sync();
                    return cc;
                } else {
                    console.error('cannot create signature that contains other container');
                }     
            } else {
                console.error('cannot create signature in a "' + parent.tag + '" container');
            }
        } else {
            console.error('cannot create a signature outside a clause');
        }
    } catch (error) {
        console.error(error.stack);
        throw error;
    }
}

export async function importNewClauses(context) {
    
}